import { useMemo, useState } from "react"
import { useNavigate, useSearchParams } from "react-router-dom"
import { ActionTypesStories } from "../../StoriesPage.controller"
import { ActionTypes } from "../../../MainEditPage/types"
import {
	useDeleteStoryMutation,
	useGetStoriesQuery,
} from "../../../../services/stories"
import { message } from "antd"
import { AllRoutes } from "../../../../constans/appRoutes"

export const useStoriesCategories = () => {
	const navigate = useNavigate()
	const [searchParams] = useSearchParams()
	const params = Object.fromEntries(searchParams)

	const cityId = params?.cityId
	const type = params?.type as ActionTypesStories
	const action = params?.action as ActionTypes

	const isCreateCategory = useMemo(() => {
		return action === "create" && type === "category"
	}, [action, type])

	const isCreateStory = useMemo(() => {
		return action === "create" && type === "story"
	}, [action, type])

	const isEditCategory = useMemo(() => {
		return action === "edit" && type === "category"
	}, [action, type])

	const isEditStory = useMemo(() => {
		return action === "edit" && type === "story"
	}, [action, type])

	const [storiesIds, setStoriesIds] = useState<number[]>([])

	const { data: storiesData } = useGetStoriesQuery(cityId, { skip: !cityId })

	const [deleteStory] = useDeleteStoryMutation()

	const deleteStoryHandler = (id: number) => {
		deleteStory(id)
			.unwrap()
			.then(res => {
				if (res) {
					message.success("Сторис успешно удалён")
				}
			})
			.catch(error => {
				console.log("deleteStoryHandler error", error)
				message.error("Не удалось удалит сторис")
			})
	}

	const goEdit = (id?: number) =>
		navigate(
			AllRoutes.StorisStoriesEdit + `&cityId=${cityId}` + `&storyId=${id}`
		)

	const stories = useMemo(() => {
		return storiesData?.filter(it => !storiesIds.includes(it.id))
	}, [storiesData, storiesIds])

	return {
		setStoriesIds,
		deleteStoryHandler,
		goEdit,
		isCreateCategory,
		isCreateStory,
		isEditCategory,
		isEditStory,
		stories,
		storiesData,
	}
}
