import React, { ChangeEvent, useState } from "react"
import styles from "./EnrollmentsPage.module.scss"
import {
	useCreateEnrollmentResultMutation,
	useGetEnrollmentQuery,
} from "../../services/enrollments"
import { useSearchParams } from "react-router-dom"
import { tzMoment } from "../../helpers/date"
import { Button } from "../../common/Button"
import CancelEnrollModal from "./parts/CancelEnrollModal/CancelEnrollModal"
import { message } from "antd"

type Props = {}

const EnrollmentView = (props: Props) => {
	const [searchParams] = useSearchParams()
	const params = Object.fromEntries(searchParams)
	const currentId = params?.id

	const [updateEnrollmentResult] = useCreateEnrollmentResultMutation()

	const [showCancelEnroll, setShowCancelEnroll] = useState(false)

	const { data: enrollmentData } = useGetEnrollmentQuery({ id: +currentId })

	const handleChangeIcon = async (file: File) => {
		const formData = new FormData()

		formData.append("file", file)
		formData.append("is_public", "true")

		updateEnrollmentResult({
			id: +currentId,
			file: formData,
		})
			.then(() => {
				message.success("Результаты загружены")
			})
			.catch(() => {
				message.error("Не удалось загрузить результаты")
			})
	}

	const handleChange = async ({ target }: ChangeEvent<HTMLInputElement>) => {
		const fileReder = new FileReader()

		if (!!target.files) {
			handleChangeIcon(target.files[0])
			fileReder.readAsDataURL(target.files[0])
		}
	}

	return (
		<div>
			<table className={styles.EnrollmentView__table}>
				<tbody>
					<tr>
						<td className={styles.EnrollmentView__table_leftCol}>
							ФИО пациента
						</td>
						<td className={styles.EnrollmentView__table_rightCol}>
							{/* {enrollmentData?.data?.patient?.surname ?? "-"} {enrollmentData?.data?.patient?.name ?? "-"}{" "}
              {enrollmentData?.data?.patient?.patronymic ?? "-"} */}
							{enrollmentData?.data?.patient_surname ?? "-"}{" "}
							{enrollmentData?.data?.patient_name ?? "-"}{" "}
							{enrollmentData?.data?.patient_patronymic ?? "-"}
						</td>
					</tr>
					<tr>
						<td className={styles.EnrollmentView__table_leftCol}>ФИО врача</td>
						<td className={styles.EnrollmentView__table_rightCol}>
							{enrollmentData?.data?.specialist_surname ?? "-"}{" "}
							{enrollmentData?.data?.specialist_name ?? "-"}{" "}
							{enrollmentData?.data?.specialist_patronymic ?? "-"}
						</td>
					</tr>
					<tr>
						<td className={styles.EnrollmentView__table_leftCol}>
							Клиника (название и адрес)
						</td>
						<td className={styles.EnrollmentView__table_rightCol}>
							{enrollmentData?.data?.clinic_name}{" "}
							{enrollmentData?.data?.clinic_address}
						</td>
					</tr>
					<tr>
						<td className={styles.EnrollmentView__table_leftCol}>
							Создатель заявки
						</td>
						<td className={styles.EnrollmentView__table_rightCol}>
							{enrollmentData?.data?.creator_surname ?? "-"}{" "}
							{enrollmentData?.data?.creator_name ?? "-"}{" "}
							{enrollmentData?.data?.creator_patronymic ?? "-"}
						</td>
					</tr>
					<tr>
						<td className={styles.EnrollmentView__table_leftCol}>
							Дата и время приёма
						</td>
						<td className={styles.EnrollmentView__table_rightCol}>
							{enrollmentData?.data?.start_at
								? tzMoment(
										new Date(enrollmentData?.data?.start_at * 1000)
								  ).format("DD.MM.YYYY HH:mm")
								: "Неизвестно"}
						</td>
					</tr>
					<tr>
						<td className={styles.EnrollmentView__table_leftCol}>
							Продолжительность приёма
						</td>
						<td className={styles.EnrollmentView__table_rightCol}>
							{enrollmentData?.data?.duration} минут
						</td>
					</tr>
					<tr>
						<td className={styles.EnrollmentView__table_leftCol}>Цена</td>
						<td className={styles.EnrollmentView__table_rightCol}>
							{enrollmentData?.data?.total_price} ₽
						</td>
					</tr>
					<tr>
						<td className={styles.EnrollmentView__table_leftCol}>Статус</td>
						<td className={styles.EnrollmentView__table_rightCol}>
							{enrollmentData?.data.status === "planned"
								? "Запланирована"
								: enrollmentData?.data.status === "in_progress"
								? "В процессе"
								: enrollmentData?.data.status === "completed"
								? "Выполнена"
								: enrollmentData?.data.status === "cancel"
								? "Отменена" + ` (${enrollmentData.data.cancel_note || ""})`
								: null}
						</td>
					</tr>
					<tr>
						<td className={styles.EnrollmentView__table_leftCol}>Услуги</td>
						<td
							className={styles.EnrollmentView__table_rightCol}
							style={{ padding: 0 }}
						>
							{enrollmentData?.data?.services_enrollment?.map(service => (
								<div className={styles.multiRowTdItem}>{service.name}</div>
							))}
							{enrollmentData?.data?.services_enrollment?.length === 0 ? (
								<div className={styles.multiRowTdItem}>- - -</div>
							) : null}
						</td>
					</tr>
					<tr>
						<td className={styles.EnrollmentView__table_leftCol}>Комплексы</td>
						<td
							className={styles.EnrollmentView__table_rightCol}
							style={{ padding: 0 }}
						>
							{enrollmentData?.data?.services_combos_enrollment?.map(combo => (
								<div className={styles.multiRowTdItem}>{combo?.name}</div>
							))}
							{enrollmentData?.data?.services_combos_enrollment?.length ===
							0 ? (
								<div className={styles.multiRowTdItem}>- - -</div>
							) : null}
						</td>
					</tr>
					<tr>
						<td className={styles.EnrollmentView__table_leftCol}>Документы</td>
						<td
							className={styles.EnrollmentView__table_rightCol}
							style={{ padding: 0 }}
						>
							{enrollmentData?.data?.results?.map(result => (
								<div className={styles.multiRowTdItem}>
									<a
										href={result?.url}
										download
										style={{
											color: "#3580E4",
											textDecorationLine: "underline",
										}}
									>
										{result?.name}
									</a>
								</div>
							))}
							{!enrollmentData?.data?.results && (
								<div className={styles.multiRowTdItem}>- - -</div>
							)}
						</td>
					</tr>
				</tbody>
			</table>

			<div className={styles.bottomBtns}>
				<Button
					title='Отменить запись'
					disabled={false}
					classes={{ btn: styles.btn }}
					onClick={() => setShowCancelEnroll(true)}
				/>

				<label htmlFor='file' className={styles.inputWrapper}>
					<input
						id='file'
						onChange={handleChange}
						name='file'
						type='file'
						className={styles.input}
					/>
					<span className={styles.inputTitle}>Загрузить результат</span>
				</label>
			</div>

			<CancelEnrollModal
				visible={showCancelEnroll}
				onClose={() => setShowCancelEnroll(false)}
			/>
		</div>
	)
}

export default EnrollmentView
