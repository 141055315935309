import { IMailingFolder } from "./../models/Mailings"
import { createApi } from "@reduxjs/toolkit/query/react"
import { baseQuery } from "./baseQuery"
import { IMailings, MailingGender, TriggerSettings } from "../models/Mailings"

type RequestMailingsParams = {
	offset?: number
	limit?: number
	search?: string
}

type ResponseMailingsType = {
	data: IMailings[]
}

export type Deeplinks = {
	MAIN: "Главная"
	PRIVACY_POLICY: "Политика конфиденциальности"
	USER_AGREEMENT: "Пользовательское соглашение"
	USER_INFO: "Информация о пользователе"
	SERVICES: "Услуги"
	SERVICE_INFO: "Информация об услуге"
	MRT: "МРТ"
	PROFILE: "Личный профиль"
	NOTES: "Заметки"
	SUPPORT: "Тех поддержка"
	CLOSE_PEOPLE: "Близкие люди"
	ADD_PERSON: "Добавить персону"
	MRT_CONTRAST: "МРТ с контрастированием"
	SPECIALIST_INFO: "Информация о специалисте"
	SPECIALIST_SERVICES: "Услуги специалиста"
	ANALYZES: "Анализы"
	ONLINE_PAY: "Оплата"
	DETAIL_NOTES: "Детали записи"
	RESULT_DETAILS: "Результаты деталей"
	CHAT: "Чаты"
	INSIDE_CHAT: "Конкретный чат"
	NOTIFICATIONS: "Уведомления"
	COMPLEX_INFO: "Информация о комплексе"
	PROMOTION: "Информация о акции"
}

export type DeeplinksTypes = keyof Deeplinks

export interface IDeeplinks {
	message: string
	count: number
	data: Deeplinks
	errors: []
}

export type RequestCreateMailings = {
	subject: string
	message: string
	sending_time_type?: string
	trigger_settings: TriggerSettings
	sending_time?: string
	trigger_type_code?: string
	gender: MailingGender
	folder_id: null | number
	city: string | number
	title: string
}

type RequestCreateNotificationFolder = {
	name: string
}

type EditCreateNotificationFolder = {
	id: number | string
	name: string
}

type ResponseCreateNotificationFolder = {
	data: IMailingFolder
	success: boolean
}

type ResponseGetNotificationFolders = {
	data: IMailingFolder[]
	success: boolean
}

type ResponseGetNotificationFolderById = {
	data: IMailingFolder
	success: boolean
}

export interface RequestUpdateMailings extends RequestCreateMailings {
	id: number
}

enum Endpoints {
	Get = "admin/mailings",
	Create = "admin/city/",
	GetFolers = "admin/mailing-folders",
}

export const mailingApi = createApi({
	reducerPath: "mailingApi",
	baseQuery,
	tagTypes: ["getMailing", "deeplinks", "folders"],
	endpoints: build => ({
		getMailings: build.query<ResponseMailingsType, RequestMailingsParams>({
			query: params => ({
				url: Endpoints.Get,
				params,
			}),
			providesTags: ["getMailing"],
		}),
		getMailingsOfId: build.query<{ data: IMailings }, { id: number }>({
			query: ({ id }) => ({
				url: Endpoints.Get + "/" + id,
			}),
			providesTags: ["getMailing"],
		}),
		createMailings: build.mutation<{ data: IMailings }, RequestCreateMailings>({
			query: body => ({
				url: Endpoints.Create + body.city + "/mailings",
				body,
				method: "POST",
			}),
			invalidatesTags: () => ["getMailing", "folders"],
		}),
		updateMailings: build.mutation<{ data: IMailings }, RequestUpdateMailings>({
			query: body => ({
				url: Endpoints.Get + "/" + body.id,
				body,
				method: "PUT",
			}),
			invalidatesTags: () => ["getMailing", "folders"],
		}),
		deleteMailings: build.mutation<string, { id: number }>({
			query: ({ id }) => ({
				url: Endpoints.Get + "/" + id,
				method: "DELETE",
			}),
			invalidatesTags: () => ["getMailing", "folders"],
		}),
		getDeeplinks: build.query<IDeeplinks, any>({
			query: () => ({
				url: Endpoints.Get + "/deeplinks-list",
			}),
			providesTags: ["deeplinks"],
		}),
		// папки -----------------------------------------------------------------------
		getNotificationFolders: build.query<ResponseGetNotificationFolders, void>({
			query: () => ({
				url: Endpoints.GetFolers,
			}),
			providesTags: ["folders"],
		}),
		getNotificationFolderById: build.query<
			ResponseGetNotificationFolderById,
			number | string
		>({
			query: id => ({
				url: Endpoints.GetFolers + `/${id}`,
			}),
			providesTags: ["folders"],
		}),
		createNotificationFolder: build.mutation<
			ResponseCreateNotificationFolder,
			RequestCreateNotificationFolder
		>({
			query: body => ({
				url: Endpoints.GetFolers,
				body,
				method: "POST",
			}),
			invalidatesTags: () => ["folders"],
		}),
		deleteNotificationFolder: build.mutation<
			ResponseCreateNotificationFolder,
			{ id: number }
		>({
			query: ({ id }) => ({
				url: Endpoints.GetFolers + `/${id}`,
				method: "DELETE",
			}),
			invalidatesTags: () => ["folders", "getMailing"],
		}),
		editNotificationFolder: build.mutation<
			ResponseCreateNotificationFolder,
			EditCreateNotificationFolder
		>({
			query: body => ({
				url: Endpoints.GetFolers + `/${body.id}`,
				body,
				method: "PUT",
			}),
			invalidatesTags: () => ["folders", "getMailing"],
		}),
	}),
})

export const {
	useCreateMailingsMutation,
	useDeleteMailingsMutation,
	useGetMailingsOfIdQuery,
	useGetMailingsQuery,
	useUpdateMailingsMutation,
	useGetDeeplinksQuery,
	useGetNotificationFoldersQuery,
	useCreateNotificationFolderMutation,
	useDeleteNotificationFolderMutation,
	useGetNotificationFolderByIdQuery,
	useEditNotificationFolderMutation,
} = mailingApi
