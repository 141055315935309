import React, { ChangeEvent, useEffect, useMemo, useState } from "react"
import styles from "./ServicesPage.module.scss"
import "./ServicesPage.scss"
import { useForm } from "react-hook-form"
import { TextField } from "../../common/Form/TextField"
import { Button } from "../../common/Button"
import { useNavigate, useSearchParams } from "react-router-dom"
import { AllRoutes } from "../../constans/appRoutes"
import { message } from "antd"
import {
	RequestEditFieldType,
	useEditServiceFieldMutation,
	useGetServicesFieldQuery,
} from "../../services/services"
import { sortFields } from "./ServicesPage"
import { TreeSelect } from "../../common/TreeSelect"
import { TextArea } from "../../common/TextArea"
import { ServicesFieldsType } from "../../models/CatalogItems"
import Contraindications from "./Contraindications"
import { ContraindicationsType } from "./types"
import { subNumber } from "../../constans/formOptions"
import { Checkbox } from "../../common/CheckBox"

export type treeSelectorData = {
	title: string | React.ReactNode
	id: number
	parent_id?: number | null
	value: string
	children: treeSelectorData[]
}

type Props = {
	fieldsData: ServicesFieldsType[]
}

export const addKey = (array: any, parentKey: any) => {
	const res = array.map((item: any, i: number) => {
		const key = `${parentKey}-${i}`
		const newItem = { ...item }
		if (item.children) {
			newItem.children = addKey(item.children, key)
		}
		if (!item.children) {
			newItem.children = []
		}
		newItem.value = key
		newItem.title = item.name
		return newItem
	})

	return res
}

export const prepareData = (array: any[]) => {
	const mapItems = new Map(array.map(item => [item.id, item]))
	array.forEach(item => {
		if (item.parent) {
			const parent = mapItems.get(item.parent_id)
			mapItems.set(item.parent_id, {
				...parent,
				children: [...(parent.children ?? []), mapItems.get(item.id)],
			})
		}
	})
	const tt = Object.values(Object.fromEntries(mapItems)).filter(
		(item: any) => item.parent_id === null
	)

	return addKey(tt, "0")
}

const ServicesPageEditField = ({ fieldsData }: Props) => {
	const [searchParams] = useSearchParams()
	const params = Object.fromEntries(searchParams)
	const currentId = params?.fieldId

	const navigate = useNavigate()
	const {
		register,
		formState: { errors },
		watch,
		setValue,
	} = useForm({ mode: "onBlur" })

	const [editServiceField, { isLoading }] = useEditServiceFieldMutation()

	const [parentFieldValue, setParentFieldValue] = useState<string>()
	const [parentFieldId, setParentFieldId] = useState<number | null>(0)
	const [description, setDescription] = useState("")
	const [preparationDesc, setPreparationDesc] = useState("")
	const [contrindications, setContrindications] = useState<
		ContraindicationsType[]
	>([])
	const [searchDisabled, setSearchDisabled] = useState<boolean>(false)

	const { data: currentFieldData } = useGetServicesFieldQuery({
		id: +currentId,
	})

	const name = watch("name") ?? currentFieldData?.data.name
	const order = watch("order") ?? currentFieldData?.data.order

	const onChangeSearchDisabled = (e: any) => {
		setSearchDisabled(prev => !prev)
	}

	useEffect(() => {
		if (currentFieldData?.data) {
			setDescription(currentFieldData.data.description)
			setPreparationDesc(currentFieldData.data.preparation_desc)
			setContrindications(
				currentFieldData.data.service_contraindications.map(contr => ({
					contraindication: contr.description,
					note: contr.note,
					is_required: contr.is_required,
				}))
			)

			setValue("name", currentFieldData?.data.name)
			setValue("order", currentFieldData?.data.order)
			setSearchDisabled(!!currentFieldData.data.search_disabled)
		}
	}, [currentFieldData?.data, setValue])

	const onChangeSelect = (newValue: string) => {
		setParentFieldValue(newValue)

		!newValue && setParentFieldId(null)
	}

	const dataForPrepare = useMemo(() => {
		if (fieldsData) {
			const sortedData = [...fieldsData]
			return prepareData(sortFields(sortedData))
		}
	}, [fieldsData])

	const generatedFieldData = useMemo(() => {
		const data: any[] = []
		if (fieldsData) {
			dataForPrepare.forEach((item: any) => {
				if (item) {
					data.push(item)
				}
			})
		}
		return data
	}, [fieldsData, dataForPrepare])

	const handleDescriptionOnChange = (
		event: ChangeEvent<HTMLTextAreaElement>
	) => {
		setDescription(event.target.value)
	}

	const handlePreparationDescOnChange = (
		event: ChangeEvent<HTMLTextAreaElement>
	) => {
		setPreparationDesc(event.target.value)
	}

	const handleEditField = async () => {
		const sendData: RequestEditFieldType = {
			id: +currentId,
			name,
			description,
			preparation_desc: preparationDesc,
			external_id: name ? name : currentFieldData?.data.name,
			contraindications: contrindications.map(
				({ contraindication, note, is_required }) => ({
					description: contraindication,
					note,
					is_required,
				})
			),
			order,
			search_disabled: searchDisabled,
		}

		if (parentFieldId && parentFieldId > 0) {
			sendData.parent_id = parentFieldId.toString()
		}

		if (!parentFieldId) {
			sendData.parent_id = null
		}

		const response = await editServiceField(sendData)

		if (response && "data" in response) {
			message.success("Папка успешно редактирована")
			navigate(AllRoutes.Services)
		} else {
			message.error("Не удалось редактировать папку")
		}
	}

	const findFieldId = (key: string, fieldData: treeSelectorData[]) => {
		fieldData.forEach(field => {
			if (field.value === key) {
				setParentFieldId(field.id)
			}
			if (field.value !== key && field.children.length) {
				findFieldId(key, field.children)
			}
		})
	}

	const findFieldValue = (id: number, fieldData: treeSelectorData[]) => {
		fieldData.forEach(field => {
			if (field.id === id) {
				setParentFieldValue(field.value)
			}
			if (field.id !== id && field.children.length) {
				findFieldValue(id, field.children)
			}
		})
	}

	const findFieldValueResult = useMemo(() => {
		if (
			currentFieldData?.data &&
			generatedFieldData.length &&
			currentFieldData.data.parent_id !== null
		) {
			findFieldValue(currentFieldData.data.parent_id, generatedFieldData)
		}
	}, [currentFieldData, generatedFieldData])

	const findFieldIdResult = useMemo(() => {
		if (parentFieldValue && generatedFieldData.length) {
			return findFieldId(parentFieldValue, generatedFieldData)
		}
		return 0
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [parentFieldValue, generatedFieldData])

	return (
		<div style={{ padding: "30px 0" }}>
			<div className={styles.halfContaienr}>
				<TreeSelect
					value={parentFieldValue}
					dropdownStyle={{ maxHeight: 400, overflow: "auto" }}
					placeholder='Родительская папка'
					allowClear
					treeDefaultExpandAll
					onChange={onChangeSelect}
					treeData={generatedFieldData}
				/>
				<TextField
					fieldname='name'
					register={register}
					options={{ required: "" }}
					error={errors.name?.message as string}
					palcehoder='Название'
					isMarginBottom={false}
					defaultValue={currentFieldData?.data.name}
				/>
				<TextArea
					rows={9}
					draggable={false}
					placeholder='Описание*'
					required
					defaultValue={""}
					value={description}
					onChange={handleDescriptionOnChange}
				/>
				{/* <TextArea
					rows={9}
					draggable={false}
					placeholder='Подготовка*'
					required
					defaultValue={""}
					value={preparationDesc}
					onChange={handlePreparationDescOnChange}
				/> */}

				{/* <TextField
					defaultValue={currentFieldData?.data?.order?.toString()}
					fieldname='order'
					register={register}
					min={1}
					palcehoder='Порядок'
					type='number'
					options={subNumber}
					error={errors.order?.message as string}
					styled={{
						container: styles.orderInput,
					}}
				/> */}

				<Contraindications
					contrindications={contrindications}
					setContrindications={setContrindications}
				/>

				<div
					onClick={onChangeSearchDisabled}
					style={{
						display: "flex",
						alignItems: "center",
						marginBottom: 10,
						marginTop: -20,
						cursor: "pointer",
					}}
				>
					<Checkbox style={{ marginRight: 10 }} checked={searchDisabled} />
					<span>Исключить из поиска</span>
				</div>
			</div>
			<div className={styles.createServiceBtnWrapper}>
				<Button
					title='Сохранить'
					loading={isLoading}
					disabled={!name}
					onClick={handleEditField}
				/>
				<Button
					title='Отмена'
					disabled={false}
					onClick={() => navigate(AllRoutes.Services)}
					isTransparent
				/>
			</div>
		</div>
	)
}

export default ServicesPageEditField
