import { combineReducers, configureStore } from "@reduxjs/toolkit"
import { admin } from "../services/admin"
import { catalogItems } from "../services/catalog"
import { fileApi } from "../services/file"
import { login } from "../services/login"
import authReducer from "./Slices/authSlice"
import userReducer from "./Slices/userSlice"
import { cityItems } from "../services/cities"
import { specializationItems } from "../services/specialization"
import { clinicItems } from "../services/clinics"
import { cabinetItems } from "../services/cabinets"
import { servicesItems } from "../services/services"
import { complexItems } from "../services/complexes"
import { specialistItems } from "../services/specialist"
import { enrollmentsItems } from "../services/enrollments"
import { administratorItems } from "../services/administrators"
import { mailingApi } from "../services/mailing"
import { triggerTYpeApi } from "../services/trigerTypes"
import { mainBannerApi } from "../services/mainBanner"
import { mainServiceApi } from "../services/mainService"
import { mainSpecialistApi } from "../services/mainSpecialist"
import { preparationsApi } from "../services/prepare"
import { questionsAPI } from "../services/questions"
import { discountAPI } from "../services/discounts"
import { storiesApi } from "../services/stories"

const rootReducer = combineReducers({
	auth: authReducer,
	user: userReducer,
	[login.reducerPath]: login.reducer,
	[admin.reducerPath]: admin.reducer,
	[mainServiceApi.reducerPath]: mainServiceApi.reducer,
	[mainBannerApi.reducerPath]: mainBannerApi.reducer,
	[preparationsApi.reducerPath]: preparationsApi.reducer,
	[mainSpecialistApi.reducerPath]: mainSpecialistApi.reducer,
	[triggerTYpeApi.reducerPath]: triggerTYpeApi.reducer,
	[mailingApi.reducerPath]: mailingApi.reducer,
	[catalogItems.reducerPath]: catalogItems.reducer,
	[cityItems.reducerPath]: cityItems.reducer,
	[clinicItems.reducerPath]: clinicItems.reducer,
	[cabinetItems.reducerPath]: cabinetItems.reducer,
	[specializationItems.reducerPath]: specializationItems.reducer,
	[specialistItems.reducerPath]: specialistItems.reducer,
	[complexItems.reducerPath]: complexItems.reducer,
	[servicesItems.reducerPath]: servicesItems.reducer,
	[enrollmentsItems.reducerPath]: enrollmentsItems.reducer,
	[fileApi.reducerPath]: fileApi.reducer,
	[administratorItems.reducerPath]: administratorItems.reducer,
	[questionsAPI.reducerPath]: questionsAPI.reducer,
	[discountAPI.reducerPath]: discountAPI.reducer,
	[storiesApi.reducerPath]: storiesApi.reducer,
})

const store = configureStore({
	reducer: rootReducer,
	middleware: getDefaultMidleware =>
		getDefaultMidleware().concat(
			login.middleware,
			admin.middleware,
			catalogItems.middleware,
			cityItems.middleware,
			clinicItems.middleware,
			cabinetItems.middleware,
			fileApi.middleware,
			specializationItems.middleware,
			specialistItems.middleware,
			complexItems.middleware,
			servicesItems.middleware,
			enrollmentsItems.middleware,
			administratorItems.middleware,
			mailingApi.middleware,
			triggerTYpeApi.middleware,
			mainBannerApi.middleware,
			mainServiceApi.middleware,
			mainSpecialistApi.middleware,
			preparationsApi.middleware,
			questionsAPI.middleware,
			discountAPI.middleware,
			storiesApi.middleware
		),
})

export type RootState = ReturnType<typeof rootReducer>
export type AppStore = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch

export default store
