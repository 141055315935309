import { FC, memo } from "react"
import { Select } from "../../../../../../common/Select"
import { TextField } from "../../../../../../common/Form/TextField"
import styles from "./BannerTypeItem.module.scss"
import { subNumber } from "../../../../../../constans/formOptions"
import BannerTypeItemContent from "../BannerTypeItemContent/BannerTypeItemContent"
import { UseFormRegister, FieldValues, FieldErrors } from "react-hook-form"
import { BannerState } from "../CreateOrEditBanners.controller"
import { IServices, ISpecialist } from "../../../../../../models/Specialist"
import { KeyTypesBanners } from "../../../../../../services/mainBanner"
import { TypeBannerOptions } from "../hooks/useTypeBanner"
import { IComboService } from "../../../../../../models/ComboServices"
import JoditEditor, { Jodit } from "jodit-react"
import { buttons } from "../../../../../../constans/constans"

const config = {
	readonly: false,
	addNewLineOnDBLClick: false,
	toolbar: true,
	spellcheck: true,
	language: "ru",
	toolbarAdaptive: false,
	showCharsCounter: false,
	showWordsCounter: false,
	showXPathInStatusbar: false,
	askBeforePasteHTML: true,
	askBeforePasteFromWord: true,
	buttons,
	uploader: {
		...Jodit.defaultOptions.uploader,
		insertImageAsBase64URI: true,
	},
	width: 1000,
	height: "100%",
	tabIndex: 1,
}

type Props = {
	register: UseFormRegister<FieldValues>
	errors: FieldErrors<FieldValues>
	type: KeyTypesBanners
	handleTypeBannerChange: (value: KeyTypesBanners) => void
	state: BannerState[]
	description: string
	setDescription: React.Dispatch<React.SetStateAction<string>>
	complexOptions: {
		value: number
		label: string
	}[]
	handleComplexChange: (value: number, index: number) => void
	findComplexOfId: (id: number) => IComboService | undefined

	handleEntiyChange: (value: number, index: number) => void

	specOptions: {
		value: number
		label: string
	}[]
	handleSpecChange: (value: number, index: number) => void
	findSpecOfId: (id: number) => ISpecialist | undefined

	servOptions: {
		value: number
		label: string
	}[]
	handleServChange: (value: number, index: number) => void
	findServOfId: (id: number) => IServices | undefined

	handleChangeIcon: (file: File, index: number) => Promise<void>
	handledeleteImage: (index: number) => void
}

const BannerTypeItem: FC<Props> = ({
	register,
	errors,
	type,
	handleTypeBannerChange,
	state,
	servOptions,
	specOptions,
	complexOptions,
	description,
	setDescription,
	findComplexOfId,
	findServOfId,
	findSpecOfId,
	handleComplexChange,
	handleEntiyChange,
	handleSpecChange,
	handleServChange,
	handleChangeIcon,
	handledeleteImage,
}) => {
	return (
		<div className={styles.item}>
			{/* <h2 className={styles.title}>{item.title}</h2> */}
			<div className={styles.content}>
				<TextField
					fieldname='name'
					register={register}
					error={errors?.order?.message as string}
					palcehoder='название'
					title='Название баннера'
					options={subNumber}
					editeIcon={{ style: styles.iconEdite }}
					classes={{ title: styles.titleField }}
				/>
				<div className={styles.selector}>
					<Select
						value={type}
						onChange={handleTypeBannerChange}
						options={TypeBannerOptions}
						placeholder='Тип баннера'
						classes={{ placeholder: styles.titleField }}
					/>
				</div>

				{state.map((item, i) => {
					return (
						<BannerTypeItemContent
							key={i}
							index={i}
							state={item}
							type={type}
							servOptions={servOptions}
							specOptions={specOptions}
							complexOptions={complexOptions}
							findComplexOfId={findComplexOfId}
							findServOfId={findServOfId}
							findSpecOfId={findSpecOfId}
							handleEntiyChange={handleEntiyChange}
							handleSpecChange={handleSpecChange}
							handleComplexChange={handleComplexChange}
							handleServChange={handleServChange}
							handleChangeIcon={handleChangeIcon}
							handledeleteImage={handledeleteImage}
						/>
					)
				})}

				{type === "TYPE_1" && (
					<>
						<TextField
							fieldname='title'
							register={register}
							error={errors?.order?.message as string}
							palcehoder='Заголовок'
							options={subNumber}
							classes={{ title: styles.titleField }}
							editeIcon={{ style: styles.iconEdite }}
						/>
						<div className={styles.textEditor}>
							<span className={styles.title}>Описание баннера</span>
							<JoditEditor
								value={description}
								config={config}
								onBlur={() => null}
								onChange={setDescription}
							/>
						</div>
					</>
				)}

				<div className={styles.order}>
					<TextField
						fieldname='order'
						register={register}
						error={errors?.order?.message as string}
						palcehoder='Порядок'
						type='number'
						options={subNumber}
						classes={{ title: styles.titleField }}
						editeIcon={{ style: styles.iconEdite }}
					/>
				</div>
			</div>
		</div>
	)
}

export default memo(BannerTypeItem)
