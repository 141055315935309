import React, { ChangeEvent, useMemo, useState } from "react"
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import styles from "./SpecialistsPage.module.scss"
import { Layout } from "../../common/Layout"
import { useLocation, useNavigate, useSearchParams } from "react-router-dom"
import { MainTable } from "../../common/MainTable"
import { AllRoutes } from "../../constans/appRoutes"
import { message } from "antd"
import { useDebounce } from "../../hooks/useDebaunce"
import {
	useDeleteSpecialistMutation,
	useDeleteSpecialistReviewMutation,
	useGetSpecialistReviewsQuery,
	useGetSpecialistsQuery,
} from "../../services/specialist"
import SpecialistPageEdit from "./SpecialistPageEdit"
import SpecialistPageCreate from "./SpecialistPageCreate"
import SpecialistReviewEdit from "./SpecialistReviewEdit"
import SpecialistReviewCreate from "./SpecialistReviewCreate"
import { columns, columnsReviews, getTitle, getUrlsList } from "./helpers"
import { useSpecialistContext } from "./SpecialistContext"
import { AdditionalServicesModalProvider } from "./parts/AdditionalServicesModal/AdditionalServicesModalContext"
import SpecialistsField from "./parts/SpecialistsField/SpecialistsField"
import SpecialistsFields from "./parts/SpecialistsFields/SpecialistsFields"

type Props = {}

const SpecialistsPage = (props: Props) => {
	const navigate = useNavigate()
	const [searchParams] = useSearchParams()
	const params = Object.fromEntries(searchParams)
	const currentSpecialistId = params?.id

	const isEdit = !!params?.edit
	const isCreate = !!params?.create
	const isReviews = !!params?.reviews
	const isReviewEdit = !!params?.editReview
	const isReviewCreate = !!params?.createReview

	const isCreateFolder = !!params?.fieldCreate
	const isEditFolder = !!params?.fieldEdit

	const [search, setSearch] = useState("")
	const [specialistsIds, setSpecialistsIds] = useState<number[]>([])

	const debouncedSearch = useDebounce(search, 500)

	const handleSearch = ({ target }: ChangeEvent<HTMLInputElement>) =>
		setSearch(target.value)

	const { isCustomize, loadingContextSpesialist } = useSpecialistContext()

	const { data, isLoading } = useGetSpecialistsQuery(debouncedSearch)

	const dataFilter = useMemo(() => {
		return data?.data?.filter(it => !specialistsIds.includes(it.id))
	}, [data, specialistsIds])

	const [deleteSpecialist] = useDeleteSpecialistMutation()
	const { data: specialistReviewsData, isLoading: isLoadingRew } =
		useGetSpecialistReviewsQuery(
			{
				specialistId: +currentSpecialistId,
			},
			{ skip: !currentSpecialistId }
		)

	const loading = useMemo(() => {
		return (
			isLoading ||
			isLoadingRew ||
			(loadingContextSpesialist && !!currentSpecialistId)
		)
	}, [isLoading, isLoadingRew, loadingContextSpesialist, currentSpecialistId])

	const [deleteSpecialistReview] = useDeleteSpecialistReviewMutation()

	const onHandleDelete = async (id: number) => {
		const result = await deleteSpecialist({ id })

		if (result && "data" in result) {
			message.success("Специалист успешно удален")
			navigate(AllRoutes.Specialists)
		}
	}

	const onHandleDeleteReview = (id: number) => {
		deleteSpecialistReview({ id })
			.unwrap()
			.then(result => {
				if (result.message === "Success") {
					message.success("Отзыв успешно удален")
				}
			})
			.catch(err => {
				message.error("Не удалось удалить отзыв")
			})
	}

	const goEdit = (id?: number) =>
		navigate(AllRoutes.Specialists + `?edit=true&id=${id}`)
	const goCreate = () => navigate(AllRoutes.Specialists + `?create=true`)
	const goEditReview = (id?: number) =>
		navigate(AllRoutes.Specialists + `?edit=true&id=1&editReview=${id}`)
	const goCreateReview = () =>
		navigate(
			AllRoutes.Specialists +
				`?edit=true&id=${currentSpecialistId}&createReview=true`
		)
	const goCreateFolder = () =>
		navigate(AllRoutes.Specialists + `?fieldCreate=true`)

	const title = useMemo(() => {
		return getTitle({
			isCreate,
			isEdit,
			isReviews,
			isReviewEdit,
			isReviewCreate,
			isCustomize,
			isCreateFolder,
			isEditFolder,
		})
	}, [
		isCreate,
		isEdit,
		isReviews,
		isReviewEdit,
		isReviewCreate,
		isCustomize,
		isCreateFolder,
		isEditFolder,
	])

	const urlsList = useMemo(() => {
		return getUrlsList({
			isCreate,
			isCustomize,
			isEdit,
			isReviews,
			isReviewCreate,
			isReviewEdit,
			currentSpecialistId,
			isCreateFolder,
			isEditFolder,
		})
	}, [
		isCreate,
		isCustomize,
		isEdit,
		isReviews,
		isReviewCreate,
		isReviewEdit,
		currentSpecialistId,
		isCreateFolder,
		isEditFolder,
	])

	return (
		<Layout
			search={
				!isEdit && !isCreate && !isCreateFolder
					? { value: search, onSearch: handleSearch }
					: undefined
			}
			createBtn={
				!!isReviews ||
				(!isCreate && !isEdit && !isCreateFolder && !isEditFolder)
			}
			noData={isLoading ? false : !data}
			onClickCreateBtn={!isReviews ? goCreate : goCreateReview}
			currentUrl={{
				path: `${useLocation().pathname}${useLocation().search}`,
				title,
			}}
			load={loading}
			prevLinks={urlsList}
			secondCreateBtn={!isCreateFolder && !isEditFolder && !isCreate && !isEdit}
			secondCreateBtnTitle='добавить папку'
			onClickSecondCreateBtn={goCreateFolder}
			classes={{ content: styles.content }}
		>
			{!isEdit &&
				!isCreate &&
				!isReviews &&
				!isReviewEdit &&
				!isCreateFolder &&
				!isEditFolder && (
					<MainTable
						columns={columns}
						data={dataFilter ? dataFilter : ([] as any)}
						onDelete={onHandleDelete}
						onEdit={goEdit}
						isPerson
						isSpecialist
					/>
				)}
			{!isCreate &&
				isEdit &&
				!isReviews &&
				!isReviewEdit &&
				!isReviewCreate &&
				!isCreateFolder && (
					<AdditionalServicesModalProvider>
						<SpecialistPageEdit />
					</AdditionalServicesModalProvider>
				)}

			{!isEdit &&
				isCreate &&
				!isReviews &&
				!isReviewEdit &&
				!isReviewCreate &&
				!isCreateFolder && (
					<AdditionalServicesModalProvider>
						<SpecialistPageCreate />
					</AdditionalServicesModalProvider>
				)}
			{isEdit &&
				isReviews &&
				!isCreate &&
				!isReviewEdit &&
				!isReviewCreate &&
				!isCreateFolder && (
					<MainTable
						columns={columnsReviews}
						data={specialistReviewsData?.data ? specialistReviewsData.data : []}
						onDelete={onHandleDeleteReview}
						onEdit={goEditReview}
						isReviews
					/>
				)}

			<SpecialistsFields
				specialists={data?.data}
				setSpecialistsIds={setSpecialistsIds}
			/>

			{!isCreate &&
				!isReviews &&
				isReviewEdit &&
				isEdit &&
				!isReviewCreate &&
				!isCreateFolder && <SpecialistReviewEdit />}
			{!isCreate &&
				!isReviews &&
				!isReviewEdit &&
				isEdit &&
				isReviewCreate &&
				!isCreateFolder && <SpecialistReviewCreate />}
		</Layout>
	)
}

export default SpecialistsPage
