import { ChangeEvent, useMemo, useState, useCallback, FC } from "react"
import styles from "./ComplexesPage.module.scss"
import { useForm } from "react-hook-form"
import { TextField } from "../../common/Form/TextField"
import { Button } from "../../common/Button"
import { useNavigate } from "react-router-dom"
import { AllRoutes } from "../../constans/appRoutes"
import { SelectProps, message } from "antd"
import { useGetServicesQuery } from "../../services/services"
import { fileApi } from "../../services/file"
import { FileField } from "../../common/Form/FileField"
import { ReactComponent as NoFileIcon } from "../../constans/icons/noFileIcon.svg"
import { Select } from "../../common/Select"
import { IServices } from "../../models/Specialist"
import { useComplexServices } from "../../hooks/useService"
import { TextArea } from "../../common/TextArea"
import { Select as SelectField } from "../../common/Select"
import { TreeSelect } from "../../common/TreeSelect"
import {
	useCreateComplexMutation,
	useGetCompelxesFieldsQuery,
} from "../../services/complexes"
import { prepareData, sortFields } from "./helpers"
import { treeSelectorData } from "../ServicesPage/ServicesPageEditField"

type Props = {}

export type Service = {
	id: number
	name: string
	full_name: string
	description: string
	duration: number
	notAddedToSchedule: number
	icon: null
	price: null
}

const ComplexesPageCreate: FC<Props> = () => {
	const navigate = useNavigate()
	const {
		register,
		formState: { isValid, errors },
		watch,
	} = useForm({ mode: "onBlur" })

	const { data: servicesData } = useGetServicesQuery("")
	const { data: complexesFields } = useGetCompelxesFieldsQuery("")
	const [createComplex, { isLoading: isLoadingCreate }] =
		useCreateComplexMutation()

	const complexServices = useComplexServices()
	const [sendFile] = fileApi.useSendFileMutation()

	const generatedServicesSelectorData = useMemo(() => {
		const selectorData: SelectProps["options"] = []

		if (servicesData?.data) {
			servicesData.data.forEach((service: IServices) => {
				const obj = {
					label: service.name,
					value: service.id,
				}
				selectorData.push(obj)
			})
		}
		return selectorData
	}, [servicesData?.data])

	const generatedComplexSelectorData = useMemo(() => {
		const selectorData: SelectProps["options"] = []
		complexServices.forEach(complex => {
			const obj = {
				label: complex.name,
				value: complex.id,
			}
			selectorData.push(obj)
		})
		return selectorData
	}, [complexServices])

	const [fileId, setFileId] = useState<number>(0)
	const [photoUrl, setPhotoUrl] = useState("")
	const name = watch("name")
	const duration = watch("duration")

	const [selectedServicesId, setSelectedServicesId] = useState<number[]>([])
	const [selectedComplexsId, setSelectedComplexsId] = useState<number[]>([])
	const [combosShowables, setCombosShowables] = useState<number[]>([])
	const [
		selectedComplexsCombosShowablesId,
		setSelectedComplexsCombosShowablesId,
	] = useState<number[]>([])
	const [description, setDescription] = useState("")
	const [tooltip, setTooltip] = useState("")
	const [selectedAdditionalServices, setSelectedAdditionalServices] = useState<
		number[]
	>([])

	const selectedServices: string[] = useMemo((): string[] => {
		const result: string[] = []
		selectedServicesId.forEach(serviceId => {
			result.push(serviceId.toString())
		})
		return result
	}, [selectedServicesId])

	const selectedServicesCombosShowables: string[] = useMemo((): string[] => {
		const result: string[] = []
		combosShowables.forEach(serviceId => {
			result.push(serviceId.toString())
		})
		return result
	}, [combosShowables])

	const [parentFieldValue, setParentFieldValue] = useState<string>()
	const [parentFieldId, setParentFieldId] = useState<number>()

	const handleChangeIcon = async (file: File) => {
		const formData = new FormData()

		formData.append("file", file)
		formData.append("is_public", "true")

		const response = await sendFile(formData)

		if (response && "data" in response) {
			const { id, url } = response.data.data
			setPhotoUrl(url)
			setFileId(id)
		}
	}

	const onSave = () => {
		const selectedServiceIds = [
			...selectedComplexsId.map(complexId => [
				Array.from(
					complexServices.find(it => it.id === complexId)?.services || []
				),
			]),
			...selectedServices,
		].join(",")

		const selectedCombosShowablesIds = [
			...selectedComplexsCombosShowablesId.map(complexId => [
				Array.from(
					complexServices.find(it => it.id === complexId)?.services || []
				),
			]),
			...selectedServicesCombosShowables,
		].join(",")

		const desc = `${description} {<tooltip: ${tooltip} :tooltip>}`

		const sendData = {
			name,
			duration,
			services: selectedServiceIds,
			show: {
				services: selectedCombosShowablesIds,
			},
			icon_id: fileId !== 0 && fileId ? fileId : null,
			combo_field_id: parentFieldId,
			additional_services: selectedAdditionalServices,
			description: desc,
		}

		createComplex(sendData)
			.unwrap()
			.then(result => {
				if (result.message === "Success") {
					message.success("Комплекс успешно создан")
					navigate(AllRoutes.Complexes)
				}
			})
			.catch(err => {
				message.error("Не удалось создать комплекс")
			})
	}

	const hadnleDeleteIcon = () => setFileId(0)

	const handleDescriptionOnChange = (
		event: ChangeEvent<HTMLTextAreaElement>
	) => {
		setDescription(event.target.value)
	}

	const handleTooltipOnChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
		setTooltip(event.target.value)
	}

	const dataForPrepare = useMemo(() => {
		if (complexesFields) {
			const sortedData = [...complexesFields.data]
			return prepareData(sortFields(sortedData as any))
		}
	}, [complexesFields])

	const generatedFieldData = useMemo(() => {
		const data: any[] = []
		if (complexesFields) {
			dataForPrepare.forEach((item: any) => {
				if (item) {
					data.push(item)
				}
			})
		}
		return data
	}, [complexesFields, dataForPrepare])

	const findFieldIdByValue = (value: string, fieldData: treeSelectorData[]) => {
		fieldData.forEach(field => {
			if (field.value === value) {
				setParentFieldId(field.id)
			}
			if (field.value !== value && field.children.length) {
				findFieldIdByValue(value, field.children)
			}
		})
	}

	const findFieldIdResult = useMemo(() => {
		if (parentFieldValue && generatedFieldData.length) {
			findFieldIdByValue(parentFieldValue, generatedFieldData)
		}
	}, [parentFieldValue, generatedFieldData])

	const onChangeSelect = useCallback((newValue: string) => {
		setParentFieldValue(newValue)
	}, [])

	return (
		<div className={styles.createComplexWrapper}>
			<FileField
				Icon={NoFileIcon}
				onChange={handleChangeIcon}
				onDelete={hadnleDeleteIcon}
				src={photoUrl}
			/>
			<TreeSelect
				value={parentFieldValue}
				dropdownStyle={{ maxHeight: 400, overflow: "auto" }}
				placeholder='Родительская папка'
				allowClear
				treeDefaultExpandAll
				onChange={onChangeSelect}
				treeData={generatedFieldData}
			/>
			<TextField
				fieldname='name'
				register={register}
				options={{ required: "Поле обязательно для заполнения" }}
				error={errors.name?.message as string}
				palcehoder='Название*'
				isMarginBottom={false}
			/>
			<TextField
				fieldname='duration'
				register={register}
				options={{ required: "Поле обязательно для заполнения" }}
				error={errors.duration?.message as string}
				palcehoder='Продолжительность (в минутах)*'
				type='number'
				isMarginBottom={false}
			/>

			<Select
				mode='multiple'
				optionFilterProp='label'
				allowClear
				style={{ width: "100%" }}
				placeholder='Услуги'
				value={selectedServicesId}
				onChange={setSelectedServicesId}
				options={generatedServicesSelectorData}
			/>
			<Select
				mode='multiple'
				optionFilterProp='label'
				allowClear
				style={{ width: "100%" }}
				placeholder='Комплекс услуг'
				value={selectedComplexsId}
				onChange={setSelectedComplexsId}
				options={generatedComplexSelectorData}
			/>
			<Select
				mode='multiple'
				optionFilterProp='label'
				allowClear
				style={{ width: "100%" }}
				placeholder='Расположение в услуге'
				value={combosShowables}
				onChange={setCombosShowables}
				options={generatedServicesSelectorData}
			/>
			<Select
				mode='multiple'
				optionFilterProp='label'
				allowClear
				style={{ width: "100%" }}
				placeholder='Расположение в папке услуг'
				value={selectedComplexsCombosShowablesId}
				onChange={setSelectedComplexsCombosShowablesId}
				options={generatedComplexSelectorData}
			/>
			<SelectField
				mode='multiple'
				allowClear
				showSearch
				optionFilterProp='label'
				style={{ width: "100%" }}
				placeholder='Дополнительные услуги'
				value={selectedAdditionalServices}
				onChange={setSelectedAdditionalServices}
				options={servicesData?.data.map((service: IServices, i) => ({
					value: service.id,
					label: service.name,
				}))}
			/>

			<TextArea
				rows={1}
				draggable={false}
				placeholder='Описание*'
				required
				defaultValue={""}
				value={description}
				onChange={handleDescriptionOnChange}
			/>

			<TextArea
				rows={1}
				draggable={false}
				placeholder='Примечание (Tooltip)*'
				required
				defaultValue={""}
				value={tooltip}
				onChange={handleTooltipOnChange}
			/>

			<div className={styles.createComplexBtnWrapper}>
				<Button
					title='Сохранить'
					disabled={!isValid}
					onClick={onSave}
					loading={isLoadingCreate}
				/>
				<Button
					title='Отмена'
					disabled={false}
					onClick={() => navigate(AllRoutes.Complexes)}
					isTransparent
				/>
			</div>
		</div>
	)
}

export default ComplexesPageCreate
