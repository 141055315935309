import { createApi } from "@reduxjs/toolkit/query/react"
import { baseQuery } from "./baseQuery"
import { IServices } from "../models/Specialist"
import { ServicesFieldsType } from "../models/CatalogItems"
import { GendersType, MinAgeType } from "../models/Services"

export type ContraindicationsType = {
	description: string
	note: string
	is_required: boolean
}

export type ResponseServicesFieldType = {
	data: ServicesFieldsType
	message: string
}

type ResponseDeleteFieldType = {
	message: string
	data: any[]
}

type ResponseCreateFieldType = {
	message: string
	data: ServicesFieldsType
}

type ResponseGetServiceType = {
	message: string
	data: IServices
}

type ResponseEditServiceType = {
	message: string
	data: IServices
}

export type RequestEditServiceType = {
	id?: number
	name: string
	description: string
	full_name: string
	duration: number
	notAddedToSchedule: number
	specialization_id: number
	service_field_id: number
	icon_id: number | null
	external_id: string
	additional_services: number[]
	order?: number | string
	with_contrast: boolean
	note: string
	training: string
	search_disabled: boolean
	required_for: number[]
	contrasts?: number[]
	max_age?: MinAgeType
	min_age?: MinAgeType
	gender?: GendersType
	invalid_gender_description?: string
	invalid_age_description?: string
	invalid_both_description?: string
}

export type RequestCreateFieldType = {
	name: string
	description: string
	preparation_desc: string
	parent_id?: string
	external_id: string
	contraindications: ContraindicationsType[]
	search_disabled: boolean
}

export type RequestCreateFieldComplexType = {
	name: string
	description: string
	parent_id?: number | string
}

export type RequestEditFieldType = {
	id: number
	name: string
	description: string | null
	preparation_desc: string | null
	parent_id?: string | null
	external_id: string
	contraindications: ContraindicationsType[]
	order: number
	search_disabled: boolean
}

export type ServicePricesType = {
	city: { id: number; name: string }
	id: number
	price: number
	old_price: number
	discount_percent: number
	service: { id: number; name: string }[]
}

export type RequestEditServicePrice = {
	id?: number
	price: number
	old_price: number
	discount_percent: number
	service_id: number
	city_id: number
}

export type ResponseServicePricesType = {
	data: ServicePricesType[]
	message: string
}

export type ResponseServicePriceType = {
	data: ServicePricesType
	message: string
}

export const servicesItems = createApi({
	reducerPath: "adminServices",
	baseQuery,
	tagTypes: ["Services", "ServicesField"],
	endpoints: build => ({
		getServicesFields: build.query<{ data: ServicesFieldsType[] }, {}>({
			query: () => ({
				url: `/admin/service-fields`,
			}),
			providesTags: ["ServicesField"],
		}),
		getServicesField: build.query<ResponseServicesFieldType, { id: number }>({
			query: ({ id }: { id: number }) => ({
				url: `/admin/service-fields/${id}`,
			}),
			providesTags: ["ServicesField"],
		}),
		deleteServiceField: build.mutation<ResponseDeleteFieldType, { id: number }>(
			{
				query: ({ id }: { id: number }) => ({
					url: `/admin/service-fields/${id}`,
					method: "DELETE",
				}),
				invalidatesTags: ["ServicesField"],
			}
		),
		createServiceField: build.mutation<
			ResponseCreateFieldType,
			RequestCreateFieldType
		>({
			query: (body: RequestCreateFieldType) => ({
				url: `/admin/service-fields/`,
				method: "POST",
				body: {
					...body,
				},
			}),
			invalidatesTags: () => ["ServicesField"],
		}),
		editServiceField: build.mutation<
			ResponseCreateFieldType,
			RequestEditFieldType
		>({
			query: (body: RequestEditFieldType) => ({
				url: `/admin/service-fields/${body.id}`,
				method: "PUT",
				body: {
					...body,
				},
			}),
			invalidatesTags: () => ["ServicesField"],
		}),
		getServices: build.query<{ data: IServices[] }, {}>({
			query: () => ({
				url: `/admin/services`,
			}),
			providesTags: result =>
				result && result.data.length
					? [
							...result.data.map(({ id }: { id: number }) => ({
								type: "Services" as const,
								id,
							})),
							{ type: "Services", id: "LIST" },
					  ]
					: [{ type: "Services", id: "LIST" }],
		}),
		getService: build.query<ResponseGetServiceType, { id: number }>({
			query: ({ id }: { id: number }) => ({
				url: `/admin/services/${id}`,
			}),
			providesTags: result => {
				return result && Array.isArray(result) && result.length
					? [
							...result.map(({ id }: { id: number }) => ({
								type: "Services" as const,
								id,
							})),
							{ type: "Services", id: "LIST" },
					  ]
					: [{ type: "Services", id: "LIST" }]
			},
		}),
		deleteService: build.mutation<ResponseDeleteFieldType, { id: number }>({
			query: ({ id }: { id: number }) => ({
				url: `/admin/services/${id}`,
				method: "DELETE",
			}),
			invalidatesTags: [{ type: "Services", id: "LIST" }],
		}),
		editService: build.mutation<
			ResponseEditServiceType,
			RequestEditServiceType
		>({
			query: (body: RequestEditServiceType) => ({
				url: `/admin/services/${body.id}`,
				method: "PUT",
				body,
			}),
			invalidatesTags: [{ type: "Services", id: "LIST" }],
		}),
		createService: build.mutation<
			ResponseEditServiceType,
			RequestEditServiceType
		>({
			query: (body: RequestEditServiceType) => ({
				url: `/admin/services`,
				method: "POST",
				body: {
					...body,
				},
			}),
			invalidatesTags: [{ type: "Services", id: "LIST" }],
		}),
		getServicePrices: build.query<ResponseServicePricesType, { id: number }>({
			query: ({ id }: { id: number }) => ({
				url: `/admin/services-prices?serviceId=${id}`,
			}),
			providesTags: result =>
				result && result.data.length
					? [
							...result.data.map(({ id }: { id: number }) => ({
								type: "Services" as const,
								id,
							})),
							{ type: "Services", id: "LIST" },
					  ]
					: [{ type: "Services", id: "LIST" }],
		}),
		getServicePrice: build.query({
			query: ({ id }: { id: number }) => ({
				url: `/admin/services-prices/${id}`,
			}),
			providesTags: result =>
				result && result.data.length
					? [
							...result.data.map(({ id }: { id: number }) => ({
								type: "Services" as const,
								id,
							})),
							{ type: "Services", id: "LIST" },
					  ]
					: [{ type: "Services", id: "LIST" }],
		}),
		editServicePrice: build.mutation<
			{ message: string },
			RequestEditServicePrice
		>({
			query: ({
				id,
				city_id,
				discount_percent,
				old_price,
				price,
				service_id,
			}: RequestEditServicePrice) => ({
				url: `/admin/services-prices/${id}`,
				method: "PUT",
				body: { city_id, discount_percent, old_price, price, service_id },
			}),
			invalidatesTags: [{ type: "Services", id: "LIST" }],
		}),
		createServicePrice: build.mutation<
			{ message: string },
			RequestEditServicePrice
		>({
			query: ({
				city_id,
				discount_percent,
				old_price,
				price,
				service_id,
			}: RequestEditServicePrice) => ({
				url: `/admin/services-prices`,
				method: "POST",
				body: { city_id, discount_percent, old_price, price, service_id },
			}),
			invalidatesTags: [{ type: "Services", id: "LIST" }],
		}),
	}),
})

export const {
	useGetServicesFieldsQuery,
	useGetServicesQuery,
	useGetServiceQuery,
	useDeleteServiceFieldMutation,
	useDeleteServiceMutation,
	useGetServicesFieldQuery,
	useCreateServiceFieldMutation,
	useEditServiceFieldMutation,
	useEditServiceMutation,
	useCreateServiceMutation,
	useGetServicePricesQuery,
	useGetServicePriceQuery,
	useEditServicePriceMutation,
	useCreateServicePriceMutation,
} = servicesItems
