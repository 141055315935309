import { createApi } from "@reduxjs/toolkit/query/react"
import { baseQuery } from "./baseQuery"
import { ISpecialist, ISpecialistFolder } from "../models/Specialist"

export type ResponseSpecialistType = {
	data: ISpecialist
}

type RequestSpecialistTypeParams = {
	id: number
}

type RequestSpecialistReviewsType = {
	specialistId: number
}

type RequestSpecialistReviewType = {
	reviewId: number
}

type ReviewType = {
	id: number
	name: string
	sign: string
	avatar: null | AvatarType
	summary: number
	body: string
	ext: null | string
	size: null | string
	path: null | string
	review_date: string
}

type ResponseSpecialistReviewsType = {
	count: number
	data: ReviewType[]
	message: string
}

type ResponseSpecialistReviewType = {
	data: ReviewType
	message: string
}

type ResponseSpecialistFoldersType = {
	data: ISpecialistFolder[]
	message: string
}

type ResponseSpecialistFolderType = {
	data: ISpecialistFolder
	message: string
}

type ResponseDeleteSpecialistType = {
	message: string
	data: string[]
}

type ResponseEditSpecialistType = {
	message: string
}

export type RequestEditSpecialistType = {
	id?: number
	surname: string
	name: string
	patronymic: string
	phone: string
	work_experience: number
	servicesId: {
		service_id: number | undefined
		type: string | undefined
		id?: number
	}[]
	description: string
	education: string
	photo_id: number | null
	clinics: number[]
	specialization_id: number
	ultra_sound: boolean
	normal_service_duration: number
	search_disabled: boolean
	field_id?: number | string
}

export type RequestEditReviewType = {
	id?: number
	name?: string
	sign?: string
	summary?: number
	body?: string
	avatar_id?: number
}

export type RequestCreateReviewType = {
	specialist_id: number
	name: string
	sign: string
	summary: number
	body: string
	avatar_id?: number
	review_date: string
}

export type RequestCreateSpecialistFolderType = {
	name: string
	id?: number
}

export type AvatarType = {
	created_at: number
	ext: string
	id: number
	name: string
	size: number
	updated_at: number
	url: string
}

export const specialistItems = createApi({
	reducerPath: "adminSpecialists",
	baseQuery,
	tagTypes: ["Specialist", "SpecialistReview", "folders", "folder"],
	endpoints: build => ({
		getSpecialist: build.query<
			ResponseSpecialistType,
			RequestSpecialistTypeParams
		>({
			query: ({ id }: RequestSpecialistTypeParams) => ({
				url: `/admin/specialists/${id}`,
			}),
			providesTags: result =>
				result
					? [{ type: "Specialist", id: "LIST" }]
					: [{ type: "Specialist", id: "LIST" }],
		}),
		getSpecialists: build.query<{ data: ISpecialist[] }, string>({
			query: (search: string) => ({
				url: `/admin/specialists${search !== "" ? `?search=${search}` : ""}`,
			}),
			providesTags: ["Specialist"],
		}),
		deleteSpecialist: build.mutation<
			ResponseDeleteSpecialistType,
			RequestSpecialistTypeParams
		>({
			query: ({ id }: RequestSpecialistTypeParams) => ({
				url: `/admin/specialists${id ? `/${id}` : ""}`,
				method: "DELETE",
			}),
			invalidatesTags: () => ["Specialist"],
		}),
		editSpecialist: build.mutation<
			ResponseEditSpecialistType,
			RequestEditSpecialistType
		>({
			query: (body: RequestEditSpecialistType) => ({
				url: `/admin/specialists/${body.id}`,
				method: "PUT",
				body,
			}),
			invalidatesTags: ["Specialist"],
		}),
		createSpecialist: build.mutation<
			ResponseEditSpecialistType,
			RequestEditSpecialistType
		>({
			query: ({
				surname,
				name,
				patronymic,
				phone,
				work_experience,
				servicesId,
				description,
				education,
				photo_id,
				clinics,
				specialization_id,
				normal_service_duration,
				field_id,
			}: RequestEditSpecialistType) => ({
				url: `/admin/specialists`,
				method: "POST",
				body: {
					surname,
					name,
					patronymic,
					phone,
					work_experience,
					servicesId,
					description,
					education,
					photo_id,
					clinics,
					specialization_id,
					normal_service_duration,
					field_id,
				},
			}),
			invalidatesTags: ["Specialist"],
		}),
		getSpecialistReviews: build.query<
			ResponseSpecialistReviewsType,
			RequestSpecialistReviewsType
		>({
			query: ({ specialistId }: RequestSpecialistReviewsType) => ({
				url: `/admin/reviews?specialistId=${specialistId}`,
			}),

			providesTags: ["SpecialistReview"],
		}),
		getSpecialistReview: build.query<
			ResponseSpecialistReviewType,
			RequestSpecialistReviewType
		>({
			query: ({ reviewId }: RequestSpecialistReviewType) => ({
				url: `/admin/reviews/${reviewId}`,
			}),
			providesTags: ["SpecialistReview"],
		}),
		deleteSpecialistReview: build.mutation<
			ResponseDeleteSpecialistType,
			RequestSpecialistTypeParams
		>({
			query: ({ id }: RequestSpecialistTypeParams) => ({
				url: `/admin/reviews/${id}`,
				method: "DELETE",
			}),
			invalidatesTags: () => ["SpecialistReview"],
		}),
		editSpecialistReview: build.mutation<
			ResponseEditSpecialistType,
			RequestEditReviewType
		>({
			query: ({
				id,
				avatar_id,
				body,
				summary,
				name,
				sign,
			}: RequestEditReviewType) => ({
				url: `/admin/reviews/${id}`,
				method: "PUT",
				body: { avatar_id, body, summary, name, sign },
			}),
			invalidatesTags: () => [{ type: "SpecialistReview", id: "LIST" }],
		}),
		createSpecialistReview: build.mutation<
			ResponseEditSpecialistType,
			RequestCreateReviewType
		>({
			query: ({
				specialist_id,
				avatar_id,
				body,
				sign,
				summary,
				name,
				review_date,
			}: RequestCreateReviewType) => ({
				url: `/admin/reviews`,
				method: "POST",
				body: {
					specialist_id,
					avatar_id,
					body,
					summary,
					name,
					sign,
					review_date,
				},
			}),
			invalidatesTags: () => ["SpecialistReview"],
		}),
		// получение папок
		getSpecialistFolders: build.query<ResponseSpecialistFoldersType, void>({
			query: () => ({
				url: `/admin/specialist_fields`,
			}),
			providesTags: ["folders"],
		}),
		// создание папки
		createSpecialistFolder: build.mutation<
			any,
			RequestCreateSpecialistFolderType
		>({
			query: (body: RequestCreateSpecialistFolderType) => ({
				url: `/admin/specialist_fields`,
				method: "POST",
				body,
			}),
			invalidatesTags: () => ["folders"],
		}),
		// удаление папки
		deleteSpecialistFolder: build.mutation<any, number>({
			query: id => ({
				url: `/admin/specialist_fields/${id}`,
				method: "DELETE",
			}),
			invalidatesTags: () => ["folders", "SpecialistReview", "Specialist"],
		}),
		// получение папки
		getSpecialistFolder: build.query<
			ResponseSpecialistFolderType,
			number | string
		>({
			query: id => ({
				url: `/admin/specialist_fields/${id}`,
			}),
			providesTags: ["folder"],
		}),
		// редактирование папки
		editSpecialistFolder: build.mutation<
			any,
			RequestCreateSpecialistFolderType
		>({
			query: (body: RequestCreateSpecialistFolderType) => ({
				url: `/admin/specialist_fields/${body.id}`,
				method: "PUT",
				body: {
					name: body.name,
				},
			}),
			invalidatesTags: () => ["folders", "folder"],
		}),
	}),
})

export const {
	useGetSpecialistsQuery,
	useGetSpecialistQuery,
	useDeleteSpecialistMutation,
	useEditSpecialistMutation,
	useCreateSpecialistMutation,
	useGetSpecialistReviewsQuery,
	useGetSpecialistReviewQuery,
	useDeleteSpecialistReviewMutation,
	useEditSpecialistReviewMutation,
	useCreateSpecialistReviewMutation,
	useGetSpecialistFoldersQuery,
	useCreateSpecialistFolderMutation,
	useDeleteSpecialistFolderMutation,
	useGetSpecialistFolderQuery,
	useEditSpecialistFolderMutation,
} = specialistItems
