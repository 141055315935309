import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom"
import { AllRoutes, SidebarLinks } from "../constans/appRoutes"
import { CancellationIssues } from "../pages/CancellationIssues"

function MainRoute() {
	const links = SidebarLinks.map(it => it.links).flat()

	return (
		<BrowserRouter>
			<Routes>
				{links.map(({ path, Page }) => (
					<Route key={path} path={path} element={Page} />
				))}
				<Route path='*' element={<Navigate replace to={AllRoutes.Cities} />} />

				<Route
					path={AllRoutes.CancellationIssues}
					element={<Navigate replace to={AllRoutes.CancellationIssues} />}
					Component={() => <CancellationIssues />}
				/>
			</Routes>
		</BrowserRouter>
	)
}

export default MainRoute
