import cn from "classnames"
import { FC, useState } from "react"
import { Link } from "react-router-dom"
import { SidebarLinks } from "../../constans/appRoutes"
import { ReactComponent as LogoIcon } from "./icons/logo.svg"
import { useSidebarController } from "./Sidebar.controller"
import { ReactComponent as DropArrow } from "../../constans/icons/dropArrow.svg"
import styles from "./Sidebar.module.scss"

type dropDownMenu = {
	title: string
	path: string
	Page: JSX.Element
}

const Sidebar: FC = () => {
	const { pathname } = useSidebarController()

	const [dropMenuOpen, setDropMenuOpen] = useState(false)

	const dropMenuHandler = () => {
		setDropMenuOpen(prev => !prev)
	}

	const checkSubActive = (dropDownMenu: dropDownMenu[]) => {
		return dropDownMenu?.some(it => it.path === pathname)
	}

	return (
		<div className={styles.container}>
			<div className={styles.top}>
				<LogoIcon />
			</div>
			<ul className={styles.linkList}>
				{SidebarLinks.map(({ title, links }, i) => {
					return (
						<div key={title}>
							<p className={cn(styles.linkTitle)}>{title}</p>

							{links.map(({ path, title, Icon, IconActive, dropDownMenu }) => {
								const active = pathname === path

								const icon = active ? IconActive ?? Icon : Icon

								return (
									<li
										className={cn(styles.linkItem, {
											[styles.active]: active,
											[styles.subMenu]: !!dropDownMenu,
										})}
										key={path}
									>
										<div className={styles.linkItemWrapp}>
											<div className={styles.linkWrapp}>
												{icon}
												<Link className={styles.link} to={path}>
													{title}
												</Link>
											</div>

											{!!dropDownMenu && (
												<div
													className={cn(styles.dropDownMenuBtn, {
														[styles.open]:
															dropMenuOpen || checkSubActive(dropDownMenu),
													})}
													onClick={dropMenuHandler}
												>
													<DropArrow />
												</div>
											)}
										</div>

										{!!dropDownMenu &&
											dropDownMenu?.length &&
											(dropMenuOpen || checkSubActive(dropDownMenu)) && (
												<div className={styles.dropDownMenu}>
													{dropDownMenu.map(({ path, title }) => {
														const active = pathname === path
														return (
															<div
																key={path}
																className={cn(styles.linkWrapp, {
																	[styles.active]: active,
																})}
															>
																<Link className={styles.link} to={path}>
																	{title}
																</Link>
															</div>
														)
													})}
												</div>
											)}
									</li>
								)
							})}
						</div>
					)
				})}
			</ul>
		</div>
	)
}

export default Sidebar
