import React from "react"
import styles from "./DeleteSpecialistInFolderModal.module.scss"
import { Modal } from "../../../../common/Modal"
import { message } from "antd"
import cn from "classnames"
import { useNavigate } from "react-router-dom"
import { AllRoutes } from "../../../../constans/appRoutes"
import {
	useDeleteSpecialistMutation,
	useGetSpecialistQuery,
} from "../../../../services/specialist"

type Props = {
	deleteId: number
	setId: (id: number) => void
}

const DeleteSpecialistInFolderModal = ({ deleteId, setId }: Props) => {
	const navigate = useNavigate()

	const { data: specialistData, isLoading } = useGetSpecialistQuery(
		{
			id: +deleteId,
		},
		{ skip: !deleteId }
	)
	const [deleteSpecialist] = useDeleteSpecialistMutation()

	const onHandleDelete = async () => {
		if (deleteId) {
			const result = await deleteSpecialist({ id: +deleteId })

			if (result && "data" in result) {
				message.success("Специалист успешно удален")
				navigate(AllRoutes.Specialists)
				setId(0)
			}
		}
	}

	return (
		<Modal visible={!!deleteId} onClose={() => setId(0)}>
			<div className={styles.modalWrapper}>
				<span className={styles.modalTitle}>Удалить</span>
				<p className={styles.modalDescription}>
					Вы уверены, что хотите удалить{" "}
					{`${specialistData?.data.user.surname} ${
						specialistData?.data.user.name
					} ${specialistData?.data.user.patronymic || ""}`}
				</p>
				<div className={styles.modalButtons}>
					<button
						onClick={() => setId(0)}
						className={cn(styles.modalBtn, styles.cancel)}
					>
						Отмена
					</button>
					<button
						onClick={onHandleDelete}
						disabled={isLoading}
						className={styles.modalBtn}
					>
						Удалить
					</button>
				</div>
			</div>
		</Modal>
	)
}

export default DeleteSpecialistInFolderModal
