import React, { memo, FC } from "react"
import styles from "./SpecialistsFields.module.scss"
import "./Tree.scss"
import { Spin, Tree } from "antd"
import { LoadingOutlined } from "@ant-design/icons"
import { useSpecialistsFields } from "./useSpecialistsFields.controller"
import SpecialistsField from "../SpecialistsField/SpecialistsField"
import { ISpecialist } from "../../../../models/Specialist"
import DeleteSpecialistFolderModal from "../DeleteSpecialistFolderModal/DeleteSpecialistFolderModal"
import cn from "classnames"
import DeleteSpecialistInFolderModal from "../DeleteSpecialistInFolderModal/DeleteSpecialistInFolderModal"

const { DirectoryTree } = Tree

const antIcon = <LoadingOutlined style={{ fontSize: 24, marginTop: 20 }} spin />

type Props = {
	specialists: ISpecialist[] | undefined
	setSpecialistsIds: (arr: number[]) => void
}

const SpecialistsFields: FC<Props> = props => {
	const {
		isFieldCreate,
		isFieldEdit,
		deleteFieldId,
		isEdit,
		isCreate,
		del,
		generatedData,
		setDeleteFieldId,
		isLoading,
		setDel,
		onSelect,
		isEditFolder,
		isCreateFolder,
	} = useSpecialistsFields(props)

	return (
		<div
			className={cn(styles.fields, {
				[styles.fieldsEditOrCreate]: isFieldEdit || isFieldCreate,
			})}
		>
			{(isCreateFolder || isEditFolder) && <SpecialistsField />}

			<DeleteSpecialistInFolderModal deleteId={del} setId={setDel} />

			{!!deleteFieldId && (
				<DeleteSpecialistFolderModal
					deleteFieldId={deleteFieldId}
					setDeleteFieldId={setDeleteFieldId}
				/>
			)}

			{!isFieldCreate && !isFieldEdit && !isEdit && !isCreate && (
				<>
					{isLoading ? (
						<div style={{ display: "flex", justifyContent: "center" }}>
							<Spin indicator={antIcon} />
						</div>
					) : generatedData.length ? (
						<div className='specialist-folders'>
							<DirectoryTree
								defaultExpandAll={false}
								onSelect={onSelect}
								treeData={generatedData}
								itemHeight={64}
								rootStyle={{
									background: "transparent",
									border: "0.5px solid #DADADA",
								}}
							/>
						</div>
					) : (
						<></>
					)}
				</>
			)}
		</div>
	)
}

export default memo(SpecialistsFields)
