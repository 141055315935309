import { IMailingFolder } from "../../models/Mailings"

const addForChildrenKey = (array: any, parentKey: any) => {
	const res = array.map((item: any, i: number) => {
		const key = `${parentKey}-${i}`
		const newItem = { ...item }
		if (item.children) {
			newItem.children = addForChildrenKey(item.children, key)
		}
		if (!item.children) {
			newItem.children = []
		}
		newItem.value = key
		newItem.title = item.name
		return newItem
	})

	return res
}

export const prepareData = (array: any[], addKey?: any) => {
	const mapItems = new Map(array.map(item => [item.id, item]))

	array.forEach(it => {
		const item = { ...it }
		if (item.id) {
			const parent = mapItems.get(item.id)
			mapItems.set(item.id, {
				...parent,
				children: [],
			})
		}
	})

	const tt = Object.values(Object.fromEntries(mapItems))

	const res = addKey ? addKey(tt, "0") : addForChildrenKey(array, "0")

	return res
}

export function sortFields(arr: IMailingFolder[]) {
	return arr.sort((a, b) => {
		return a.id - b.id
	})
}
