import React from "react"
import styles from "./DeleteSpecialistFolderModal.module.scss"
import { Modal } from "../../../../common/Modal"
import { message } from "antd"
import cn from "classnames"
import { useNavigate } from "react-router-dom"
import { AllRoutes } from "../../../../constans/appRoutes"
import {
	useDeleteSpecialistFolderMutation,
	useGetSpecialistFolderQuery,
} from "../../../../services/specialist"

type Props = {
	deleteFieldId: number
	setDeleteFieldId: (id: number) => void
}

const DeleteSpecialistFolderModal = ({
	deleteFieldId,
	setDeleteFieldId,
}: Props) => {
	const navigate = useNavigate()
	const [deleteSpecialistFolder] = useDeleteSpecialistFolderMutation()
	const { data: currentField } = useGetSpecialistFolderQuery(deleteFieldId)

	const handleDeleteField = async () => {
		await deleteSpecialistFolder(deleteFieldId)
			.unwrap()
			.then(result => {
				if (result) {
					message.success("Папка успешно удалена")
					setDeleteFieldId(0)
					navigate(AllRoutes.Specialists)
				}
			})
			.catch(err => {
				message.error("Не удалось удалить папку")
			})
	}
	return (
		<Modal
			visible={!!deleteFieldId && !!currentField}
			onClose={() => setDeleteFieldId(0)}
		>
			<div className={styles.modalWrapper}>
				<span className={styles.modalTitle}>Удалить</span>
				<p className={styles.modalDescription}>
					Вы уверены, что хотите удалить {currentField?.data.name}
				</p>
				<div className={styles.modalButtons}>
					<button
						onClick={() => setDeleteFieldId(0)}
						className={cn(styles.modalBtn, styles.cancel)}
					>
						Отмена
					</button>
					<button onClick={handleDeleteField} className={styles.modalBtn}>
						Удалить
					</button>
				</div>
			</div>
		</Modal>
	)
}

export default DeleteSpecialistFolderModal
