import { createApi } from "@reduxjs/toolkit/query/react"
import { baseQuery } from "./baseQuery"
import { ComplexType } from "./complexes"
import {
	IEnrollmentServices,
	IServices,
	ISpecialist,
} from "../models/Specialist"

export type EnrollmentType = {
	cancel_note: null | string
	clinic_id: number
	duration: number
	id: number
	payd_at: null | number
	preparation_desc: null | string
	start_at: number | null
	status: string
	total_price: number
	patient: { surname: string; name: string; patronymic: string }
	patient_surname: string ///
	patient_name: string ///
	patient_patronymic: string ///
	specialist_surname: string ///
	specialist_name: string ///
	specialist_patronymic: string ///
	creator_name: string
	creator_patronymic: string
	creator_surname: string
	clinic_name: string
	clinic_address: string
	specialization_name: string
	specialist: ISpecialist
	creator: { name: string; surname: string; patronymic: string }
	services_enrollment: IServices[]
	enrollment_services: IEnrollmentServices[]
	services_combos_enrollment: ComplexType[]
	enrollment_services_combos: ComplexType[]

	results:
		| {
				id: number
				name: string
				ext: string
				size: string
				url: string
				created_at: number
				updated_at: number
		  }[]
		| null
}

type ResponseEnrollmentType = {
	message: string
	data: EnrollmentType
}

type ResponseEnrollmentsType = {
	message: string
	data: EnrollmentType[]
}

type RequestEnrollmentTypeParams = {
	id: number
}

type RequestEnrollmentResultTypeParams = {
	id: number | string
	file: FormData
}

export const enrollmentsItems = createApi({
	reducerPath: "adminEnrollments",
	baseQuery,
	tagTypes: ["Enrollments"],
	endpoints: build => ({
		getEnrollment: build.query<
			ResponseEnrollmentType,
			RequestEnrollmentTypeParams
		>({
			query: ({ id }: RequestEnrollmentTypeParams) => ({
				url: `/admin/enrollments/${id}`,
			}),
			providesTags: result => [{ type: "Enrollments", id: "LIST" }],
		}),
		getEnrollments: build.query<ResponseEnrollmentsType, string>({
			query: (search: string) => ({
				url: `/admin/enrollments${search}`,
			}),
			serializeQueryArgs: ({ endpointName }) => {
				return endpointName
			},
			forceRefetch({ currentArg, previousArg }) {
				return currentArg !== previousArg
			},
			providesTags: result =>
				result && result.data.length
					? [
							...result.data.map(({ id }: { id: number }) => ({
								type: "Enrollments" as const,
								id,
							})),
							{ type: "Enrollments", id: "LIST" },
					  ]
					: [{ type: "Enrollments", id: "LIST" }],
		}),
		deleteEnrollment: build.mutation<any, number | string>({
			query: id => ({
				url: `/admin/enrollments/${id}`,
				method: "DELETE",
			}),
			invalidatesTags: result => [{ type: "Enrollments", id: "LIST" }],
		}),
		createEnrollmentResult: build.mutation<
			any,
			RequestEnrollmentResultTypeParams
		>({
			query: ({ id, file }: RequestEnrollmentResultTypeParams) => ({
				url: `/admin/enrollment/${id}/results`,
				method: "POST",
				body: file,
			}),
			invalidatesTags: result =>
				result && result.data.length
					? [
							...result.data.map(({ id }: { id: number }) => ({
								type: "Enrollments" as const,
								id,
							})),
							{ type: "Enrollments", id: "LIST" },
					  ]
					: [{ type: "Enrollments", id: "LIST" }],
		}),
	}),
})

export const {
	useGetEnrollmentQuery,
	useGetEnrollmentsQuery,
	useDeleteEnrollmentMutation,
	useCreateEnrollmentResultMutation,
} = enrollmentsItems
