import { FC } from "react"
import { useStoriesCategory } from "./StoriesCategory.controller"
import { TextField } from "../../../../common/Form/TextField"
import styles from "./StoriesCategory.module.scss"
import { FileField } from "../../../../common/Form/FileField"
import { ReactComponent as NoFileIcon } from "../../../../constans/icons/noFileIcon.svg"
import { Button } from "../../../../common/Button"

const StoriesCategory: FC = () => {
	const {
		register,
		hadnleDeleteIcon,
		handleChangeIcon,
		onSubmin,
		onCancel,
		errors,
		photoUrl,
		disabled,
		isCreate,
	} = useStoriesCategory()
	return (
		<div className={styles.wrapper}>
			<div className={styles.container}>
				<TextField
					fieldname='name'
					hiddenRightIcons
					register={register}
					options={{ required: "Поле обязательно для заполнения" }}
					error={errors.name?.message as string}
					palcehoder='Введите название категории'
					isMarginBottom={false}
					title='Название категории'
				/>

				<div className={styles.fileSelect}>
					<FileField
						Icon={NoFileIcon}
						onChange={handleChangeIcon}
						onDelete={hadnleDeleteIcon}
						title='Иконка'
						btnText='Загрузить фото'
						src={photoUrl}
					/>
				</div>
			</div>

			<div className={styles.btnsWrapper}>
				<div className={styles.btns}>
					<Button
						title={isCreate ? "Создать" : "Сохранить"}
						disabled={disabled}
						onClick={onSubmin}
						loading={false}
					/>
					<Button
						title='Отмена'
						disabled={false}
						onClick={onCancel}
						isTransparent
					/>
				</div>
			</div>
		</div>
	)
}

export default StoriesCategory
