import { FC } from "react"
import { Layout } from "../../common/Layout"
import { SearchField } from "../../common/Form/SearchField"
import { ReactComponent as EditIcon } from "../../constans/icons/redact.svg"
import { ReactComponent as CoppyIcon } from "../../constans/icons/copy.svg"
import { ReactComponent as DeleteIcon } from "../../constans/icons/delete.svg"
import { NotofycationHeader } from "../../constans/constans"
import styles from "./Notifications.module.scss"
import { useNotifycationController } from "./Notifycations.controller"
import NotifycationEdit from "./parts/NotifycationEdit"
import { Modal } from "../../common/Modal"
import { dateFormat } from "../../helpers/date"
import { useNotifycationEditController } from "./parts/NotifycationEdit.controller"
import { useLocation } from "react-router-dom"
import NotificationFolders from "./parts/NotificationFolders/NotificationFolders"
import { Cities } from "../../common/Cities"

const Notifications: FC = () => {
	const {
		action,
		mailingsFilter,
		deletedItem,
		titlePage,
		prevLinks,
		showBtnCreateFolder,
		isFieldCreate,
		mailings,
		isFieldEdit,
		mailingData,
		cityId,
		isCreate,
		isEdit,
		goToCreateField,
		endDelete,
		handleCreate,
		startDelete,
		handleDeelte,
		setNotificationIds,
		handleSelectCity,
		handleEdit,
	} = useNotifycationController()

	const { isLoading } = useNotifycationEditController()

	return (
		<Layout
			load={isLoading}
			isLink={true}
			createBtn={!action}
			currentUrl={{
				path: `${useLocation().pathname}${useLocation().search}`,
				title: titlePage,
			}}
			prevLinks={prevLinks}
			onClickCreateBtn={handleCreate}
			secondCreateBtnTitle='Добавить папку'
			secondCreateBtn={showBtnCreateFolder}
			onClickSecondCreateBtn={goToCreateField}
		>
			{!cityId && isCreate && <Cities onSelect={handleSelectCity} />}

			{((cityId && isCreate) || isEdit) && action && (
				<NotifycationEdit action={action} />
			)}

			{!action && !isFieldCreate && !isFieldEdit && (
				<div>
					<SearchField maxWidth='40%' value='' onChange={() => null} />

					<ul className={styles.header}>
						{NotofycationHeader.map(item => (
							<li key={item}>{item}</li>
						))}
					</ul>

					<ul className={styles.body}>
						{mailingsFilter.map(item => (
							<li key={item.id.toString()}>
								<span>{item.id}</span>
								<span style={{ textTransform: "capitalize" }}>
									{item.subject}
								</span>
								<span>
									{item.created_at && dateFormat(item.created_at, true)}
								</span>
								<span>
									<CoppyIcon
										onClick={handleEdit.bind(null, item.id)}
										style={{ marginRight: 30 }}
									/>
									<EditIcon
										style={{ marginRight: 30 }}
										onClick={handleEdit.bind(null, item.id)}
									/>
									<DeleteIcon onClick={startDelete.bind(null, item.id)} />
								</span>
							</li>
						))}
					</ul>
				</div>
			)}

			<NotificationFolders
				notifications={mailings}
				setNotificationIds={setNotificationIds}
				deleteNotification={startDelete}
			/>

			<Modal
				visible={deletedItem > 0}
				onClose={endDelete}
				title='Удалить'
				subTitle={`Вы уверены, что хотите удалить рассылку “${mailingData?.data.subject}”?`}
				successBtn={{
					text: "Отмена",
					onClick: endDelete,
				}}
				cancelBtn={{
					text: "Удалить",
					onClick: handleDeelte,
				}}
			/>
		</Layout>
	)
}

export default Notifications
