import classNames from "classnames"
import { FC, HTMLInputTypeAttribute, memo, useState } from "react"
import { FieldValues, RegisterOptions, UseFormRegister } from "react-hook-form"
import { ReactComponent as DeleteIcon } from "../../../constans/icons/delete.svg"
import { ReactComponent as EditeIcon } from "../../../constans/icons/redact.svg"
import { ReactComponent as SaveIcon } from "../../../constans/icons/submitArrow.svg"
import styles from "./TextField.module.scss"

interface Props<T extends FieldValues> {
	fieldname: string
	Icon?: FC
	error?: string
	defaultValue?: string
	palcehoder?: string
	required?: boolean
	isTopDescr?: boolean
	type?: HTMLInputTypeAttribute
	register: UseFormRegister<T>
	options: RegisterOptions<FieldValues, string>
	value?: string
	styled?: {
		container?: string
		input?: string
	}
	title?: string
	isDisabled?: boolean
	isMarginBottom?: boolean
	max?: number
	min?: number
	deleteIcon?: {
		onClick: () => void
	}
	classes?: {
		title?: string
	}
	editeIcon?: {
		onClick?: (value: boolean) => void
		style?: string
		show?: boolean
	}
	hiddenRightIcons?: boolean
}

const TextField: FC<Props<FieldValues>> = ({
	fieldname,
	Icon,
	register,
	error,
	palcehoder,
	isTopDescr = true,
	options,
	defaultValue,
	value,
	type = "text",
	styled,
	isDisabled = false,
	isMarginBottom = true,
	max,
	min,
	deleteIcon,
	required,
	editeIcon = {
		show: true,
	},
	title,
	classes,
	hiddenRightIcons = false,
}) => {
	const [editeMode, setEidteMode] = useState(false)

	const editeHandler = () => {
		if (editeMode) {
			editeIcon?.onClick && editeIcon?.onClick(false)
			setEidteMode(false)
		} else {
			editeIcon?.onClick && editeIcon?.onClick(true)
			setEidteMode(true)
		}
	}

	return (
		<div
			className={classNames(
				styles.container,
				{
					[styles.containerError]: !!error && !!options.required,
					[styles.containerSuccess]: !error?.length && !!options.required,
				},
				[styled?.container],
				!isMarginBottom && styles.noMarginBottom
			)}
		>
			{isTopDescr && (
				<span
					className={classNames(styles.topPlaceholder, classes?.title, {
						[styles.error]: error,
						[styles.success]: !error,
						[styles.required]: required,
					})}
				>
					{title || palcehoder}
				</span>
			)}
			{Icon && <Icon />}

			<input
				type={type}
				disabled={isDisabled ?? false}
				defaultValue={defaultValue}
				max={max}
				min={min}
				placeholder={palcehoder}
				className={classNames(styles.input, styled?.input)}
				{...register(fieldname, options)}
			/>

			{hiddenRightIcons ? (
				<></>
			) : !!editeIcon && editeMode ? (
				<SaveIcon className={editeIcon.style} onClick={editeHandler} />
			) : (
				<EditeIcon className={editeIcon?.style} onClick={editeHandler} />
			)}

			{!!deleteIcon && <DeleteIcon onClick={deleteIcon.onClick} />}

			{error && !value?.length && <span className={styles.error}>{error}</span>}
		</div>
	)
}

export default memo(TextField)
