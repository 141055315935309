import React, { useState } from "react"
import styles from "./CitiesPage.module.scss"
import { FieldValues, RegisterOptions, UseFormRegister } from "react-hook-form"
import classNames from "classnames"
import { TextField } from "../../common/Form/TextField"
import { Button } from "../../common/Button"
import { ReactComponent as EditIcon } from "../../constans/icons/redact.svg"
import { ReactComponent as DeleteIcon } from "../../constans/icons/delete.svg"
import { ReactComponent as CloseIcon } from "../../constans/icons/Close.svg"
import { ReactComponent as SubmitIcon } from "../../constans/icons/submitArrow.svg"
import { message } from "antd"

type Props = {
	fieldname: string
	defaultValue: string
	title: string
	isDisabled: boolean
	register: UseFormRegister<FieldValues>
	options: RegisterOptions<FieldValues, string>
	onSubmit: () => Promise<void>
	onDelete: () => void
}

const CabinetInput = ({
	isDisabled,
	defaultValue,
	register,
	fieldname,
	options,
	onSubmit,
	onDelete,
	title,
}: Props) => {
	const [isEditing, setIsEditing] = useState(false)

	const submitEditing = () => {
		onSubmit().then(response => {
			setIsEditing(false)
		})
	}

	const editHandler = () => {
		setIsEditing(prev => {
			if (prev) {
				submitEditing()
			}
			return !prev
		})
	}

	return (
		<div className={styles.cabinetInput__root}>
			<TextField
				fieldname={fieldname}
				defaultValue={defaultValue}
				isDisabled={!isEditing}
				register={register}
				options={options}
				editeIcon={{
					style: isEditing ? styles.save : styles.edit,
					onClick: editHandler,
				}}
				// error={errors.name?.message as string}
				palcehoder={fieldname}
				title={title}
				isTopDescr={true}
			/>
			<div className={styles.cabinetInput__controleBtns}>
				{isEditing && (
					<div className={styles.cabinetInput__controleBtns_active}>
						{/* <Button
							isTransparent
							title={<SubmitIcon />}
							onClick={submitEditing}
							disabled={false}
							classes={{ btn: styles.cabinetInput__controleBtns_btn_edit }}
						/> */}
						{/* <Button
							isTransparent
							title={<CloseIcon />}
							onClick={() => setIsEditing(false)}
							disabled={false}
							classes={{ btn: styles.cabinetInput__controleBtns_btn }}
						/> */}
					</div>
				)}
				{!isEditing && (
					<div className={styles.cabinetInput__controleBtns_inactive}>
						{/* <Button
							isTransparent
							title={<EditIcon />}
							onClick={() => setIsEditing(true)}
							disabled={false}
							classes={{ btn: styles.cabinetInput__controleBtns_btn }}
						/> */}
						<Button
							isTransparent
							title={<DeleteIcon />}
							onClick={onDelete}
							disabled={false}
							classes={{ btn: styles.cabinetInput__controleBtns_btn }}
						/>
					</div>
				)}
			</div>
		</div>
	)
}

export default CabinetInput
