import { FC } from "react"
import { useStoriesStory } from "./StoriesStory.controller"
import { TextField } from "../../../../common/Form/TextField"
import styles from "./StoriesStory.module.scss"
import { FileField } from "../../../../common/Form/FileField"
import { ReactComponent as NoFileIcon } from "../../../../constans/icons/noFileIcon.svg"
import { Button } from "../../../../common/Button"
import { Select } from "../../../../common/Select"

const StoriesStory: FC = () => {
	const {
		register,
		hadnleDeleteIcon,
		handleChangeIcon,
		onSubmin,
		onCancel,
		handleCategoryChange,
		errors,
		photoUrl_1,
		photoUrl_2,
		photoUrl_3,
		disabled,
		isCreate,
		category,
		categoriesOptions,
	} = useStoriesStory()

	return (
		<div className={styles.wrapper}>
			<div className={styles.container}>
				<TextField
					fieldname='name'
					hiddenRightIcons
					register={register}
					options={{ required: "Поле обязательно для заполнения" }}
					error={errors.name?.message as string}
					palcehoder='Введите название сторис'
					isMarginBottom={false}
					title='Название сторис'
				/>

				<div className={styles.categorySelect}>
					<Select
						placeholder='Категория'
						value={category}
						onChange={handleCategoryChange}
						options={categoriesOptions}
					/>
				</div>

				<div className={styles.fileSelect}>
					<FileField
						Icon={NoFileIcon}
						onChange={file => handleChangeIcon(file, "regular")}
						onDelete={() => hadnleDeleteIcon("regular")}
						title='Фото/GIF/видео (390 x 844)'
						btnText='Загрузить файл'
						src={photoUrl_2}
					/>
				</div>

				<div className={styles.fileSelect}>
					<FileField
						Icon={NoFileIcon}
						onChange={file => handleChangeIcon(file, "small")}
						onDelete={() => hadnleDeleteIcon("small")}
						title='Фото/GIF/видео (414 x 736)'
						btnText='Загрузить файл'
						src={photoUrl_1}
					/>
				</div>

				<div className={styles.fileSelect}>
					<FileField
						Icon={NoFileIcon}
						onChange={file => handleChangeIcon(file, "big")}
						onDelete={() => hadnleDeleteIcon("big")}
						title='Фото/GIF/видео (414 x 896)'
						btnText='Загрузить файл'
						src={photoUrl_3}
					/>
				</div>
			</div>

			<div className={styles.btnsWrapper}>
				<div className={styles.btns}>
					<Button
						title={isCreate ? "Создать" : "Сохранить"}
						disabled={disabled}
						onClick={onSubmin}
						loading={false}
					/>
					<Button
						title='Отмена'
						disabled={false}
						onClick={onCancel}
						isTransparent
					/>
				</div>
			</div>
		</div>
	)
}

export default StoriesStory
