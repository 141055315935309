import React, { useEffect, useMemo } from "react"
import styles from "./SpecialistsField.module.scss"
import { useForm } from "react-hook-form"
import { TextField } from "../../../../common/Form/TextField"
import { Button } from "../../../../common/Button"
import { useNavigate, useSearchParams } from "react-router-dom"
import { AllRoutes } from "../../../../constans/appRoutes"
import { message } from "antd"
import {
	RequestCreateSpecialistFolderType,
	useCreateSpecialistFolderMutation,
	useEditSpecialistFolderMutation,
	useGetSpecialistFolderQuery,
} from "../../../../services/specialist"

const SpecialistsField = () => {
	const navigate = useNavigate()
	const [searchParams] = useSearchParams()
	const params = Object.fromEntries(searchParams)

	const isCreate = !!params?.fieldCreate
	const isEdit = !!params?.fieldEdit
	const fieldId = params?.fieldId

	const [createSpecialistFolder] = useCreateSpecialistFolderMutation()
	const [editSpecialistFolder] = useEditSpecialistFolderMutation()

	const { data: currentField } = useGetSpecialistFolderQuery(fieldId, {
		skip: !fieldId,
	})

	const {
		register,
		formState: { errors },
		watch,
		setValue,
	} = useForm({ mode: "onBlur" })

	const name = watch("name")

	const disabled = useMemo(() => {
		return !name
	}, [name])

	const onCancel = () => {
		navigate(AllRoutes.Specialists)
	}

	const handleEditeField = async () => {
		const sendData: RequestCreateSpecialistFolderType = {
			name,
		}

		if (isCreate) {
			await createSpecialistFolder(sendData)
				.unwrap()
				.then(result => {
					if (result) {
						message.success("Папка успешно создана")
						navigate(AllRoutes.Specialists)
					}
				})
				.catch(err => {
					console.log(err)
					message.error("Не удалось создать папку")
				})
		} else {
			sendData.id = currentField?.data.id

			editSpecialistFolder(sendData)
				.unwrap()
				.then(result => {
					if (result) {
						message.success("Папка успешно изменена")
						navigate(AllRoutes.Specialists)
					}
				})
				.catch(err => {
					console.log(err)
					message.error("Не удалось изменить папку")
				})
		}
	}

	useEffect(() => {
		if (currentField && isEdit) {
			setValue("name", currentField.data.name)
		}
	}, [currentField, isEdit, setValue])

	return (
		<div className={styles.wrapper}>
			<div className={styles.container}>
				<TextField
					title='Название папки'
					fieldname='name'
					register={register}
					options={{ required: "" }}
					error={errors.name?.message as string}
					palcehoder='Введите назание папки'
					isMarginBottom={false}
					hiddenRightIcons
				/>
			</div>

			<div className={styles.btnsWrapper}>
				<div className={styles.btns}>
					<Button
						title={isCreate ? "Создать" : "Сохранить"}
						disabled={disabled}
						onClick={handleEditeField}
						loading={false}
					/>
					<Button
						title='Отмена'
						disabled={false}
						onClick={onCancel}
						isTransparent
					/>
				</div>
			</div>
		</div>
	)
}

export default SpecialistsField
