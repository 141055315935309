import { useNavigate, useSearchParams } from "react-router-dom"
import { useCallback, useMemo, useState } from "react"
import { ReactComponent as DeleteIcon } from "../../../../constans/icons/delete.svg"
import { ReactComponent as EditIcon } from "../../../../constans/icons/redact.svg"
import { ReactComponent as ServiceIcon } from "../../../../constans/icons/serviceIcon.svg"
import { ReactComponent as GreyField } from "../../../../constans/icons/greyField.svg"
import { ReactComponent as GreenField } from "../../../../constans/icons/greenField.svg"
import styles from "./StoriesFields.module.scss"
import { AllRoutes } from "../../../../constans/appRoutes"
import type { DirectoryTreeProps } from "antd/es/tree"
import { prepareDataStory, sortFieldsStory } from "../../helpers"
import {
	useDeleteStoryCategoryMutation,
	useDeleteStoryMutation,
	useGetStoryByIdQuery,
	useGetStoryCategoriesQuery,
	useGetStoryCategoryByIdQuery,
} from "../../../../services/stories"
import { ActionTypesStories } from "../../StoriesPage.controller"
import { ActionTypes } from "../../../MainEditPage/types"
import { message } from "antd"
import { IStory } from "../../../../models/Stories"

type Props = {
	stories: IStory[] | undefined
	setStoriesIds: (arr: number[]) => void
}

export const useStoriesFields = ({ stories, setStoriesIds }: Props) => {
	const navigate = useNavigate()
	const [searchParams] = useSearchParams()
	const params = Object.fromEntries(searchParams)

	const type = params?.type as ActionTypesStories
	const action = params?.action as ActionTypes
	const cityId = params?.cityId

	const isCreateCategory = useMemo(() => {
		return action === "create" && type === "category"
	}, [action, type])

	const isCreateStory = useMemo(() => {
		return action === "create" && type === "story"
	}, [action, type])

	const isEditCategory = useMemo(() => {
		return action === "edit" && type === "category"
	}, [action, type])

	const isEditStory = useMemo(() => {
		return action === "edit" && type === "story"
	}, [action, type])

	const { data: categories, isLoading } = useGetStoryCategoriesQuery(
		cityId || "",
		{ skip: !cityId }
	)

	const [deleteStoryCategory] = useDeleteStoryCategoryMutation()
	const [deleteStory] = useDeleteStoryMutation()

	const [selectedFieldId, setSelectedFieldId] = useState(0)
	const [deleteFieldId, setDeleteFieldId] = useState<string | number>(0)
	const [del, setDel] = useState<number>(0)

	const { data: categoryDelete } = useGetStoryCategoryByIdQuery(deleteFieldId, {
		skip: !deleteFieldId,
	})

	const { data: storyDelete } = useGetStoryByIdQuery(del, { skip: !del })

	const addKey = (array: any, parentKey: any) => {
		const res = array.map((item: any, i: number) => {
			const key = `${parentKey}-${i}`
			const newItem = { ...item }
			if (item.children) {
				newItem.children = addKey(item.children, key)
			}
			if (!item.children) {
				newItem.children = []
			}
			newItem.key = key
			newItem.icon = key.length > 3 ? <GreyField /> : <GreenField />
			newItem.title = (
				<>
					{item.name}
					<div className={styles.tree__btns} style={{ flex: 1 }}>
						<button
							onClick={() =>
								navigate(
									AllRoutes.StorisCategoriesEdit +
										`&cityId=${cityId}` +
										`&categoryId=${item.id}`
								)
							}
							className={styles.tree__btn}
						>
							<EditIcon />
						</button>
						<button
							onClick={() => setDeleteFieldId(item.id)}
							className={styles.tree__btn}
						>
							<DeleteIcon />
						</button>
					</div>
				</>
			)
			newItem.type = "field"
			return newItem
		})

		return res
	}

	const goEdit = (id?: number) =>
		navigate(
			AllRoutes.StorisStoriesEdit + `&cityId=${cityId}` + `&storyId=${id}`
		)

	const findParentField = useCallback((dataArr: any[]) => {
		dataArr.forEach(item => {
			item.stories_items.forEach((it: IStory) => {
				item.children?.push({
					title: (
						<>
							{it.name}
							<div className={styles.tree__btns} style={{ flex: 1 }}>
								<button
									onClick={() => goEdit(it.id)}
									className={styles.tree__btn}
								>
									<EditIcon />
								</button>
								<button
									onClick={() => setDel(it.id)}
									className={styles.tree__btn}
								>
									<DeleteIcon />
								</button>
							</div>
						</>
					),
					id: it.id,
					key: `${item.key}-${item.children.length}`,
					children: [],
					icon: <ServiceIcon />,
				})
			})
		})
	}, [])

	const dataForPrepare = useMemo(() => {
		if (categories) {
			const sortedData = [...categories]

			const sortFieldsdata = [...sortFieldsStory(sortedData)]
			const prepareDataFields: any = [
				...prepareDataStory(sortFieldsdata, addKey),
			]

			return prepareDataFields
		}
	}, [categories, stories])

	const generateData = useCallback(() => {
		const data: any = []

		dataForPrepare.forEach((item: any) => {
			data.push(item)
		})

		findParentField(data)

		const listIds = data
			.map((item: any) => {
				const childrenIds = item.children.map((it: any) => it.id)
				return childrenIds
			})
			.flat()

		setStoriesIds(listIds)

		return data
	}, [dataForPrepare, findParentField])

	const generatedData = useMemo(() => {
		if (categories) {
			return generateData()
		}
		return []
	}, [categories, generateData])

	const onSelect: DirectoryTreeProps<any>["onSelect"] = (keys, info) => {
		if (info.node.type === "field") {
			setSelectedFieldId(info.node.id)
		} else {
			setSelectedFieldId(0)
		}
	}

	const deleteStoryCategoryHandler = useCallback(() => {
		deleteStoryCategory(deleteFieldId)
			.unwrap()
			.then(result => {
				if (result) {
					message.success("Категория успешно удалена")
					setDeleteFieldId(0)
				}
			})
			.catch(err => {
				message.error("Не удалось удалить категорию")
			})
	}, [deleteStoryCategory, deleteFieldId, setDeleteFieldId])

	const deleteStoryHandler = useCallback(() => {
		deleteStory(del)
			.unwrap()
			.then(result => {
				if (result) {
					message.success("Сторис успешно удален")
					setDel(0)
				}
			})
			.catch(err => {
				message.error("Не удалось удалить сторис")
			})
	}, [deleteStory, del, setDel])

	return {
		isCreateCategory,
		isEditCategory,
		deleteFieldId,
		del,
		isCreateStory,
		isEditStory,
		generatedData,
		categories,
		setDeleteFieldId,
		setDel,
		onSelect,
		deleteStoryCategoryHandler,
		isLoading,
		categoryDelete,
		storyDelete,
		deleteStoryHandler,
	}
}
