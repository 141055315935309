import { createApi } from "@reduxjs/toolkit/query/react"
import { baseQuery } from "./baseQuery"
import { IStory, IStoryCategory } from "../models/Stories"

export type StorySizeType = "small" | "regular" | "big"

export type RequestCreateCategoryStory = {
	id?: number | string
	name: string
	active?: number
	order?: number
	image?: string | null
	city_id: string | number
}

export type RequestCreateStory = {
	id?: number | string
	name: string
	active?: number
	order?: number
	image?: string | null
	category_id?: number
	city_id: string
}

export const storiesApi = createApi({
	reducerPath: "storiesApi",
	baseQuery,
	tagTypes: ["categories", "stories", "category", "story"],
	endpoints: build => ({
		// получение категорий
		getStoryCategories: build.query<IStoryCategory[], string>({
			query: city_id => ({
				url: `/admin/stories-categorys?city_id=${city_id}`,
				method: "GET",
			}),
			providesTags: ["categories"],
		}),
		// получение категории
		getStoryCategoryById: build.query<IStoryCategory, string | number>({
			query: id => ({
				url: `/admin/stories-category/${id}`,
				method: "GET",
			}),
			providesTags: ["category"],
		}),
		// создание категории
		createStoryCategory: build.mutation<any, RequestCreateCategoryStory>({
			query: (body: RequestCreateCategoryStory) => {
				return {
					url: `/admin/stories-category`,
					method: "POST",
					body,
				}
			},
			invalidatesTags: () => ["categories"],
		}),
		// обновление категории
		updateStoryCategory: build.mutation<any, RequestCreateCategoryStory>({
			query: (body: RequestCreateCategoryStory) => {
				return {
					url: `/admin/stories-category/${body.id}`,
					method: "PUT",
					body,
				}
			},
			invalidatesTags: () => ["categories", "category"],
		}),
		// удаление категории
		deleteStoryCategory: build.mutation<any, number | string>({
			query: id => ({
				url: `/admin/stories-category/${id}`,
				method: "DELETE",
			}),
			invalidatesTags: () => ["categories", "stories", "category"],
		}),
		// получение сторисов
		getStories: build.query<IStory[], number | string>({
			query: city_id => ({
				url: `/admin/stories-items?city_id=${city_id}`,
				method: "GET",
			}),
			providesTags: ["stories"],
		}),
		// получение сториса
		getStoryById: build.query<IStory, number | string>({
			query: id => ({
				url: `/admin/stories-item/${id}`,
				method: "GET",
			}),
			providesTags: ["story"],
		}),
		// создание сториса
		createStory: build.mutation<any, RequestCreateStory>({
			query: (body: RequestCreateStory) => ({
				url: `/admin/stories-items`,
				method: "POST",
				body,
			}),
			invalidatesTags: () => ["categories", "stories"],
		}),
		// обновление сториса
		updateStory: build.mutation<any, RequestCreateStory>({
			query: (body: RequestCreateStory) => ({
				url: `/admin/stories-items/${body.id}`,
				method: "PUT",
				body,
			}),
			invalidatesTags: () => ["categories", "stories", "story"],
		}),
		// удаление сториса
		deleteStory: build.mutation<any, number>({
			query: id => ({
				url: `/admin/stories-items/${id}`,
				method: "DELETE",
			}),
			invalidatesTags: () => ["categories", "stories", "story"],
		}),
	}),
})

export const {
	useGetStoryCategoriesQuery,
	useCreateStoryCategoryMutation,
	useUpdateStoryCategoryMutation,
	useDeleteStoryCategoryMutation,
	useGetStoriesQuery,
	useCreateStoryMutation,
	useUpdateStoryMutation,
	useDeleteStoryMutation,
	useGetStoryCategoryByIdQuery,
	useGetStoryByIdQuery,
} = storiesApi
