import { Jodit } from "jodit-react"
import { ISpecialistFolder } from "../../models/Specialist"

export const columns = [
	{
		title: "Индентификатор",
		width: 2,
	},
	{
		title: "ФИО",
		width: 2,
	},
	{
		title: "Специальность",
		width: 2,
	},
	{
		title: "",
		width: 1,
	},
]

export const columnsReviews = [
	{
		title: "Индентификатор",
		width: 2,
	},
	{
		title: "Имя",
		width: 2,
	},
	{
		title: "Оценка",
		width: 2,
	},
	{
		title: "Дата",
		width: 2,
	},
	{
		title: "",
		width: 1,
	},
]

export const buttons = [
	"undo",
	"redo",
	"|",
	"bold",
	"strikethrough",
	"underline",
	"italic",
	"|",
	"superscript",
	"subscript",
	"|",
	"align",
	"|",
	"ul",
	"ol",
	"outdent",
	"indent",
	"|",
	"font",
	"fontsize",
	"brush",
	"paragraph",
	"|",
	"image",
	"link",
	"table",
	"|",
	"hr",
	"eraser",
	"copyformat",
	"|",
	"fullsize",
	"selectall",
	"print",
	"|",
	"source",
	"|",
]

export const config = {
	readonly: false,
	addNewLineOnDBLClick: false,
	toolbar: true,
	spellcheck: true,
	language: "ru",
	toolbarAdaptive: false,
	showCharsCounter: false,
	showWordsCounter: false,
	showXPathInStatusbar: false,
	askBeforePasteHTML: true,
	askBeforePasteFromWord: true,
	buttons: buttons,
	uploader: {
		...Jodit.defaultOptions.uploader,
		insertImageAsBase64URI: true,
	},
	width: 1000,
	height: "100%",
	tabIndex: 1,
}

type TitleCreate = {
	isCreate: boolean
	isEdit: boolean
	isReviewEdit: boolean
	isReviews: boolean
	isReviewCreate: boolean
	isCustomize: boolean
	isCreateFolder: boolean
	isEditFolder: boolean
	currentSpecialistId?: string | number
}

export const getTitle = ({
	isCreate,
	isEdit,
	isReviewEdit,
	isReviews,
	isReviewCreate,
	isCustomize,
	isCreateFolder,
	isEditFolder,
}: TitleCreate) => {
	if (isCreate) {
		if (
			!isEdit &&
			!isReviews &&
			!isReviewEdit &&
			!isCustomize &&
			!isReviewCreate &&
			isCreate
		) {
			return "Добавить специалиста"
		} else {
			return "Настройка услуг"
		}
	}

	if (isEdit && !isReviews && !isReviewEdit && !isReviewCreate) {
		if (
			isEdit &&
			!isReviews &&
			!isReviewEdit &&
			!isCustomize &&
			!isReviewCreate
		) {
			return "Редактирование специалиста"
		} else {
			return "Настройка услуг"
		}
	}

	if (isEdit && isReviews && !isReviewEdit && !isReviewCreate) {
		return "Отзывы"
	}

	if (isReviewCreate || isReviewEdit) {
		return "Создание/редактирование отзыва"
	}

	if (isCreateFolder) {
		return "Добавить папку"
	}

	if (isEditFolder) {
		return "Редактирование папки"
	}

	return ""
}

export const getUrlsList = ({
	isCreate,
	isEdit,
	isReviewEdit,
	isReviews,
	isReviewCreate,
	isCustomize,
	currentSpecialistId,
	isCreateFolder,
	isEditFolder,
}: TitleCreate) => {
	const prevUrls = [{ title: "Специалисты", path: "/specialists" }]

	const cusomizeUrls = [
		...prevUrls,
		{ title: "Добавить специалиста", path: "/specialists?create=true" },
	]

	const prevUrlsReviews = [
		...prevUrls,
		{
			title: "Редактирование специалиста",
			path: `/specialists?edit=true&id=${currentSpecialistId}`,
		},
	]

	const prevUrlsReviewEdit = [
		...prevUrlsReviews,
		{
			title: "Отзывы",
			path: `/specialists?edit=true&id=${currentSpecialistId}&reviews=true`,
		},
	]
	if (isCreate && !isCustomize) {
		return prevUrls
	}

	if (isCreate && isCustomize) {
		return cusomizeUrls
	}

	if (isEdit && !isReviews && !isReviewCreate && !isReviewEdit && isCustomize) {
		return prevUrlsReviews
	}

	if (isEdit && isReviews && !isReviewCreate && !isReviewEdit && !isCustomize) {
		return prevUrlsReviews
	}

	if (isEdit && !isReviews && !isReviewCreate && !isReviewEdit) {
		return prevUrls
	}

	if (isEdit && isReviewCreate) {
		return prevUrlsReviewEdit
	}

	if (isEdit && isReviewEdit) {
		return prevUrlsReviewEdit
	}

	if (isCreateFolder || isEditFolder) {
		return prevUrls
	}

	return []
}

export const addForChildrenKeySpecilist = (array: any, parentKey: any) => {
	const res = array.map((item: any, i: number) => {
		const key = `${parentKey}-${i}`
		const newItem = { ...item }
		if (item.children) {
			newItem.children = addForChildrenKeySpecilist(item.children, key)
		}
		if (!item.children) {
			newItem.children = []
		}
		newItem.value = key
		newItem.title = item.name
		return newItem
	})

	return res
}

export const prepareDataSpecialist = (array: any[], addKey?: any) => {
	const mapItems = new Map(array.map(item => [item.id, item]))

	array.forEach(it => {
		const item = { ...it }
		if (item.id) {
			const parent = mapItems.get(item.id)
			mapItems.set(item.id, {
				...parent,
			})
		}
	})
	const tt = Object.values(Object.fromEntries(mapItems))
	return addKey ? addKey(tt, "0") : addForChildrenKeySpecilist(tt, "0")
}

export function sortFieldsSpecialicts(arr: ISpecialistFolder[]) {
	return arr.sort((a, b) => {
		if (b.id === null || b.id === undefined) return -1
		if (a.id === null || a.id === undefined) return 1
		return b.id - a.id
	})
}
