import { FC } from "react"
import { Layout } from "../../common/Layout"
import { useMAinEditContrller } from "./MainEdit.controller"
import { Cities } from "../../common/Cities"
import { Main } from "./parts/Main"
import { AllRoutes } from "../../constans/appRoutes"
import { MainProvider } from "./parts/Main/Main.context"

const MainEdit: FC = () => {
	const {
		action,
		cityId,
		cities,
		cityOfId,
		isLoading,
		actionparams,
		bredCrumbsTitle,
		entity,
		handleSelectCity,
		onCreateAction,
	} = useMAinEditContrller()

	return (
		<Layout
			load={isLoading}
			createBtn={!entity}
			createBtnTitle='Создать группировку'
			onClickCreateBtn={onCreateAction}
			currentUrl={
				cityOfId && !action
					? { path: AllRoutes.Main + `?cityId=${cityId}`, title: cityOfId.name }
					: action
					? {
							path: AllRoutes.Main + `?${actionparams}`,
							title: bredCrumbsTitle,
					  }
					: undefined
			}
			prevLinks={
				cityOfId && !action
					? [{ path: AllRoutes.Main, title: "Города" }]
					: action && cityOfId
					? [
							{ path: AllRoutes.Main, title: "Города" },
							{
								path: AllRoutes.Main + `?cityId=${cityId}`,
								title: cityOfId?.name,
							},
					  ]
					: undefined
			}
		>
			{!cityId && cities && <Cities onSelect={handleSelectCity} />}

			{cityId && (
				<MainProvider>
					<Main />
				</MainProvider>
			)}
		</Layout>
	)
}

export default MainEdit
