import { message } from "antd"
import {
	ITypesBanners,
	KeyTypesBanners,
	useCreateBannerMutation,
} from "../../../../../../services/mainBanner"
import { EntitiesBanners, EntityType } from "../../../../types"
import { BannerState } from "../CreateOrEditBanners.controller"
import { useNavigate } from "react-router-dom"

type Props = {
	state: BannerState[]
	description: string
	title: string
	typesBanners:
		| {
				data: ITypesBanners
		  }
		| undefined
	type: KeyTypesBanners
	name: string
	order: string
	params: {
		[k: string]: string
	}
}

export const useCreateBanner = ({
	state,
	typesBanners,
	type,
	name,
	order,
	params,
	description,
	title,
}: Props) => {
	const navigate = useNavigate()

	const [createBanner, { isLoading: loadingCreate }] = useCreateBannerMutation()

	const messageError = () => message.error("Ошибка, попробуйте позже")

	const handleCreate = async () => {
		const items = state.map((it, i) => {
			let entity = EntityType.Specialists
			let entity_id = 0

			if (it.entity === EntitiesBanners.Spec) {
				entity = EntityType.Specialists
				entity_id = it.spec
			}

			if (it.entity === EntitiesBanners.Serv) {
				entity = EntityType.Service
				entity_id = it.service
			}

			if (it.entity === EntitiesBanners.Complex) {
				entity = EntityType.Complex
				entity_id = it.complex
			}

			if (it.entity === EntitiesBanners.SERVICES) {
				entity = EntityType.Services
			}
			if (it.entity === EntitiesBanners.DETAIL_NOTES) {
				entity = EntityType.DETAIL_NOTES
			}
			if (it.entity === EntitiesBanners.RESULT_DETAILS) {
				entity = EntityType.RESULT_DETAILS
			}

			return {
				entity,
				entity_id,
				angle: typesBanners?.data[type][i] || "MAIN",
				photo_id: it.fileId,
			}
		})

		const data = {
			name,
			order: +order,
			city_id: +params?.cityId,
			type,
			items,
			description,
			title,
		}

		const result = await createBanner(data)
		if (result && "data" in result) {
			message.success("Группа баннеров добавлена")
			navigate(-1)
		} else {
			messageError()
		}
	}

	return { loadingCreate, handleCreate }
}
