import { message } from "antd"
import {
	ITypesBanners,
	KeyTypesBanners,
	useUpdateBannerMutation,
} from "../../../../../../services/mainBanner"
import { EntitiesBanners, EntityType } from "../../../../types"
import { BannerState } from "../CreateOrEditBanners.controller"
import { useNavigate } from "react-router-dom"
import { IMainBanners } from "../../../../../../models/MainEntities"

type Props = {
	state: BannerState[]
	description: string
	title: string
	typesBanners:
		| {
				data: ITypesBanners
		  }
		| undefined
	type: KeyTypesBanners
	name: string
	order: string
	params: {
		[k: string]: string
	}
	bannerData:
		| {
				data: IMainBanners
		  }
		| undefined
}

export const useUpdateBanner = ({
	state,
	typesBanners,
	type,
	name,
	order,
	params,
	bannerData,
	description,
	title,
}: Props) => {
	const navigate = useNavigate()

	const messageError = () => message.error("Ошибка, попробуйте позже")

	const [updateBanner, { isLoading: loadingUpdate }] = useUpdateBannerMutation()

	const handleUpdate = async () => {
		const items = state.map((it, i) => {
			let entity = EntityType.Specialists
			let entity_id = 0

			if (it.entity === EntitiesBanners.Spec) {
				entity = EntityType.Specialists
				entity_id = it.spec
			}

			if (it.entity === EntitiesBanners.Serv) {
				entity = EntityType.Service
				entity_id = it.service
			}

			if (it.entity === EntitiesBanners.Complex) {
				entity = EntityType.Complex
				entity_id = it.complex
			}

			if (it.entity === EntitiesBanners.SERVICES) {
				entity = EntityType.Services
			}
			if (it.entity === EntitiesBanners.DETAIL_NOTES) {
				entity = EntityType.DETAIL_NOTES
			}
			if (it.entity === EntitiesBanners.RESULT_DETAILS) {
				entity = EntityType.RESULT_DETAILS
			}

			return {
				entity,
				entity_id,
				angle: typesBanners?.data[type][i] || "MAIN",
				photo_id: it.fileId,
			}
		})

		const data = {
			id: bannerData?.data.id ? bannerData?.data.id : 0,
			name,
			order: +order,
			city_id: +params?.cityId,
			type,
			items,
			description,
			title,
		}

		if (data.id) {
			const result = await updateBanner(data)

			if (result && "data" in result) {
				message.success("Баннер Обновлен")
				navigate(-1)
			} else {
				messageError()
			}
		}
	}

	return { loadingUpdate, handleUpdate }
}
