import React, { useCallback, useMemo, useState, memo, FC } from "react"
import styles from "./NotificationFolders.module.scss"
import "./Tree.scss"
import { useNavigate, useSearchParams } from "react-router-dom"
import { Spin, Tree } from "antd"
import { LoadingOutlined } from "@ant-design/icons"
import { ReactComponent as DeleteIcon } from "../../../../constans/icons/delete.svg"
import { ReactComponent as EditIcon } from "../../../../constans/icons/redact.svg"
import { ReactComponent as ServiceIcon } from "../../../../constans/icons/serviceIcon.svg"
import { ReactComponent as GreyField } from "../../../../constans/icons/greyField.svg"
import { ReactComponent as GreenField } from "../../../../constans/icons/greenField.svg"
import type { DirectoryTreeProps } from "antd/es/tree"
import { AllRoutes } from "../../../../constans/appRoutes"
import { prepareData, sortFields } from "../../helpers"
import NotificationFolder from "../NotificationFolder/NotificationFolder"
import { useGetNotificationFoldersQuery } from "../../../../services/mailing"
import { IMailings } from "../../../../models/Mailings"
import DeleteNotificationFolderModal from "../DeleteNotificationFolderModal/DeleteNotificationFolderModal"

const { DirectoryTree } = Tree

const antIcon = <LoadingOutlined style={{ fontSize: 24, marginTop: 20 }} spin />

type Props = {
	notifications: IMailings[] | undefined
	setNotificationIds: (arr: number[]) => void
	deleteNotification: (id: number) => void
}

const NotificationFolders: FC<Props> = ({
	notifications,
	setNotificationIds,
	deleteNotification,
}) => {
	const navigate = useNavigate()
	const [searchParams] = useSearchParams()
	const params = Object.fromEntries(searchParams)

	const isFieldCreate = !!params?.fieldCreate
	const isFieldEdit = !!params?.fieldEdit
	const action = !!params?.action

	const { data: notificationFolders, isLoading } =
		useGetNotificationFoldersQuery()

	const [selectedFieldId, setSelectedFieldId] = useState(0)
	const [deleteFieldId, setDeleteFieldId] = useState<string | number>(0)

	const addKey = useCallback(
		(array: any[], parentKey: any) => {
			const res = array.map((item: any, i: number) => {
				const key = `${parentKey}-${i}`
				const newItem = { ...item }
				if (item.children) {
					newItem.children = addKey(item.children, key)
				}
				if (!item.children) {
					newItem.children = []
				}
				newItem.key = key
				newItem.icon = key.length > 3 ? <GreyField /> : <GreenField />
				newItem.title = (
					<>
						{item.name}
						<div className={styles.tree__btns} style={{ flex: 1 }}>
							<button
								onClick={() =>
									navigate(AllRoutes.NotifycationFieldEdite + item.id)
								}
								className={styles.tree__btn}
							>
								<EditIcon />
							</button>
							<button
								onClick={() => setDeleteFieldId(item.id)}
								className={styles.tree__btn}
							>
								<DeleteIcon />
							</button>
						</div>
					</>
				)
				newItem.type = "field"
				return newItem
			})

			return res
		},
		[navigate]
	)

	const handleEdit = useCallback(
		(id: number) => {
			const editeditempath = id ? `&id=${id.toString()}` : ""
			navigate(AllRoutes.NotifycationEdit + editeditempath)
		},
		[navigate]
	)

	const findParentField = useCallback(
		(notify: IMailings, dataArr: any[]) => {
			dataArr.forEach(item => {
				if (notify.folder_id === item.id) {
					item.children?.push({
						title: (
							<>
								{/* @ts-ignore */}
								{notify.subject}
								<div className={styles.tree__btns} style={{ flex: 1 }}>
									<button
										onClick={() => handleEdit(notify.id)}
										className={styles.tree__btn}
									>
										<EditIcon />
									</button>
									<button
										onClick={() => deleteNotification(notify.id)}
										className={styles.tree__btn}
									>
										<DeleteIcon />
									</button>
								</div>
							</>
						),
						id: notify.id,
						key: `${item.key}-${item.children.length}`,
						children: [],
						icon: <ServiceIcon />,
					})

					const seenIds = new Set()

					const uniquchildren = item.children.filter((it: any) => {
						if (seenIds.has(it.id)) {
							return false
						} else {
							seenIds.add(it.id)
							return true
						}
					})

					item.children = uniquchildren
				}
			})
		},
		[handleEdit]
	)

	const dataForPrepare = useMemo(() => {
		if (notificationFolders?.data) {
			const sortedData = [...notificationFolders.data]

			const sortFieldsdata = [...sortFields(sortedData)]

			const prepareDataFields: any = [...prepareData(sortFieldsdata, addKey)]

			return prepareDataFields
		}
	}, [notificationFolders, addKey])

	const generateData = useCallback(
		(notifications: any[]) => {
			const data: any = []

			dataForPrepare.forEach((item: any) => {
				data.push(item)
			})

			notifications.forEach(n => {
				findParentField(n, data)
			})

			const listIds = data
				.map((item: any) => {
					const childrenIds = item.children.map((it: any) => it.id)
					return childrenIds
				})
				.flat()

			setNotificationIds(listIds)

			return data
		},
		[dataForPrepare, findParentField, setNotificationIds]
	)

	const generatedData = useMemo(() => {
		if (notificationFolders?.data && notifications) {
			return generateData(notifications)
		}
		return []
	}, [notificationFolders?.data, notifications, generateData])

	const onSelect: DirectoryTreeProps<any>["onSelect"] = (keys, info) => {
		if (info.node.type === "field") {
			setSelectedFieldId(info.node.id)
		} else {
			setSelectedFieldId(0)
		}
	}

	return (
		<div className={styles.fields}>
			{(isFieldCreate || isFieldEdit) && <NotificationFolder />}

			{!!deleteFieldId && (
				<DeleteNotificationFolderModal
					deleteFieldId={deleteFieldId}
					setDeleteFieldId={setDeleteFieldId}
				/>
			)}

			{!isFieldCreate && !isFieldEdit && !action && (
				<>
					{isLoading ? (
						<div style={{ display: "flex", justifyContent: "center" }}>
							<Spin indicator={antIcon} />
						</div>
					) : (
						<>
							{!!generatedData.length ? (
								<DirectoryTree
									defaultExpandAll={false}
									onSelect={onSelect}
									treeData={generatedData}
									itemHeight={64}
									rootStyle={{
										background: "transparent",
										border: "0.5px solid #DADADA",
									}}
								/>
							) : (
								<></>
							)}
						</>
					)}
				</>
			)}
		</div>
	)
}

export default memo(NotificationFolders)
