import React from "react"
import styles from "./ServicesPage.module.scss"
import "./ServicesPage.scss"
import { Checkbox } from "antd"
import { TextField } from "../../common/Form/TextField"
import Button from "../../common/Button/Button"
import { AllRoutes } from "../../constans/appRoutes"
import PriceCitiesPage from "./PriceCitiesPage"
import { FileField } from "../../common/Form/FileField"
import { ReactComponent as NoFileIcon } from "../../constans/icons/noFileIcon.svg"
import { TreeSelect } from "../../common/TreeSelect"
import { TextArea } from "../../common/TextArea"
import { Select, Select as SelectField } from "../../common/Select"
import { ServicesFieldsType } from "../../models/CatalogItems"
import { IServices } from "../../models/Specialist"
import { subNumber } from "../../constans/formOptions"
import { useServicesPageEdit } from "./useServicesPageEdit"
import SelectedServicesFilds from "../../common/Services/SelectedServicesFilds/SelectedServicesFilds"
import { optionsGenders, optionsMinAges } from "../../models/Services"

type Props = {
	fieldsData: ServicesFieldsType[]
}

const ServicesPageEdit = ({ fieldsData }: Props) => {
	const {
		isShowPrices,
		photoUrl,
		parentFieldValue,
		generatedFieldData,
		errors,
		currentServiceData,
		description,
		descriptionAge,
		specializationId,
		specializationSelectData,
		isNotSchedule,
		selectedAdditionalServices,
		servicesData,
		currentId,
		isUseContrasting,
		loadingSave,
		servicePrices,
		note,
		preparation,
		searchDisabled,
		selectedServices,
		requiredServices,
		generatedServicesSelectorData,
		selectedServicesId,
		setGender,
		setMaxAge,
		maxAge,
		gender,
		descriptionGender,
		descriptionGenderAndAge,
		handleDescriptionGenderAndAgeOnChange,
		handleDescriptionGenderOnChange,
		setSelectedServicesId,
		setSelectedServices,
		onChangeRequiredServices,
		handleNoteOnChange,
		handlePreparationOnChange,
		handleChangeIcon,
		hadnleDeleteIcon,
		onChangeSelect,
		register,
		handleDescriptionOnChange,
		handleSpecializationChange,
		onChangeCheckbox,
		setSelectedAdditionalServices,
		navigate,
		onChangeUseContrasting,
		handleSave,
		onChangeSearchDisabled,
		handleDescriptionAgeOnChange,
	} = useServicesPageEdit({ fieldsData })

	return (
		<div className={styles.createServiceWrapper}>
			{!isShowPrices && (
				<>
					<div className={styles.servicesInner}>
						<FileField
							Icon={NoFileIcon}
							onChange={handleChangeIcon}
							onDelete={hadnleDeleteIcon}
							src={photoUrl}
						/>
						<TreeSelect
							value={parentFieldValue}
							dropdownStyle={{ maxHeight: 400, overflow: "auto" }}
							placeholder='Родительская папка'
							allowClear
							treeDefaultExpandAll
							onChange={onChangeSelect}
							treeData={generatedFieldData}
						/>
						<TextField
							fieldname='shortname'
							register={register}
							options={{ required: "" }}
							error={errors.shortname?.message as string}
							palcehoder='Краткое название'
							isMarginBottom={false}
							defaultValue={currentServiceData?.data.name}
						/>
						<TextField
							fieldname='fullname'
							register={register}
							options={{ required: "" }}
							error={errors.fullname?.message as string}
							palcehoder='Полное название'
							isMarginBottom={false}
							defaultValue={currentServiceData?.data.full_name}
						/>

						<SelectField
							optionFilterProp='label'
							allowClear
							style={{ width: "100%" }}
							placeholder='Ограничения по полу'
							title='Ограничения по полу'
							value={gender}
							onChange={setGender}
							options={optionsGenders}
						/>

						{!!gender && (
							<TextArea
								rows={9}
								draggable={false}
								placeholder='Описание ограничения по полу*'
								required
								defaultValue={""}
								value={descriptionGender}
								onChange={handleDescriptionGenderOnChange}
							/>
						)}

						<SelectField
							optionFilterProp='label'
							allowClear
							style={{ width: "100%" }}
							placeholder='Ограничения по возрасту'
							title='Ограничения по возрасту'
							value={maxAge}
							onChange={setMaxAge}
							options={optionsMinAges}
						/>

						{!!maxAge && (
							<TextArea
								rows={9}
								draggable={false}
								placeholder='Описание ограничения по возрасту*'
								required
								defaultValue={""}
								value={descriptionAge}
								onChange={handleDescriptionAgeOnChange}
							/>
						)}

						{!!maxAge && !!gender && (
							<TextArea
								rows={9}
								draggable={false}
								placeholder='Описание ограничения по возрасту и по полу*'
								required
								defaultValue={""}
								value={descriptionGenderAndAge}
								onChange={handleDescriptionGenderAndAgeOnChange}
							/>
						)}

						<TextArea
							rows={9}
							draggable={false}
							placeholder='Описание*'
							required
							defaultValue={""}
							value={description}
							onChange={handleDescriptionOnChange}
						/>
						<TextArea
							rows={9}
							draggable={false}
							placeholder='Примечание (Tooltip)*'
							required
							defaultValue={""}
							value={note}
							onChange={handleNoteOnChange}
						/>
						{/* <TextArea
							rows={9}
							draggable={false}
							placeholder='Подготовка *'
							required
							defaultValue={""}
							value={preparation}
							onChange={handlePreparationOnChange}
						/> */}
						<TextField
							fieldname='duration'
							register={register}
							options={{ required: "" }}
							error={errors.duration?.message as string}
							palcehoder='Длительность (в минутах)'
							isTopDescr={false}
							isMarginBottom={false}
							defaultValue={currentServiceData?.data.duration.toString()}
							type='number'
						/>
						<SelectField
							placeholder='Специализация'
							value={specializationId}
							style={{ width: "100%" }}
							onChange={handleSpecializationChange}
							options={specializationSelectData}
						/>

						<Checkbox
							onChange={onChangeRequiredServices}
							value={requiredServices}
							checked={requiredServices}
						>
							Обязательная услуга для анализов
						</Checkbox>

						{requiredServices && (
							<SelectedServicesFilds
								setServicesSelected={setSelectedServices}
								servicesSelected={selectedServices}
							/>
						)}

						<div className={styles.scheduleWrapper}>
							<h4 className={styles.scheduleTitle}>У услуги есть расписание</h4>
							<Checkbox
								onChange={onChangeCheckbox}
								value={isNotSchedule}
								checked={isNotSchedule}
							>
								Нет расписания
							</Checkbox>
						</div>

						<SelectField
							mode='multiple'
							allowClear
							showSearch
							style={{ width: "100%" }}
							placeholder='Дополнительные услуги'
							value={selectedAdditionalServices}
							onChange={setSelectedAdditionalServices}
							options={servicesData?.data.map((service: IServices, i) => ({
								value: service.name,
								label: service.name,
							}))}
						/>

						<Button
							title='Цены'
							disabled={false}
							classes={{ btn: styles.citiesBtn }}
							isTransparent
							onClick={() =>
								navigate(AllRoutes.ServiceEdit + currentId + "&showPrices=true")
							}
						/>

						<TextField
							defaultValue={currentServiceData?.data?.order?.toString()}
							fieldname='order'
							register={register}
							min={1}
							palcehoder='Порядок'
							type='number'
							options={subNumber}
							error={errors.order?.message as string}
							styled={{
								container: styles.orderInput,
							}}
						/>

						<Checkbox
							onChange={onChangeUseContrasting}
							value={isUseContrasting}
							checked={isUseContrasting}
						>
							Может использоваться с контрастированием
						</Checkbox>

						{isUseContrasting && (
							<Select
								mode='multiple'
								optionFilterProp='label'
								allowClear
								style={{ width: "100%" }}
								placeholder='Выберите контраст'
								title='Контрастирование'
								value={selectedServicesId}
								onChange={setSelectedServicesId}
								options={generatedServicesSelectorData}
							/>
						)}

						<Checkbox
							onChange={onChangeSearchDisabled}
							value={searchDisabled}
							checked={searchDisabled}
						>
							Исключить из поиска
						</Checkbox>
					</div>

					<div className={styles.createServiceBtnWrapper}>
						<Button
							title='Сохранить'
							disabled={false}
							onClick={handleSave}
							loading={loadingSave}
						/>
						<Button
							title='Отмена'
							disabled={false}
							onClick={() => navigate(AllRoutes.Services)}
							isTransparent
						/>
					</div>
				</>
			)}
			{isShowPrices && servicePrices && (
				<PriceCitiesPage
					onPriceEdit={() => null}
					onPriceEditCancel={() => null}
				/>
			)}
		</div>
	)
}

export default ServicesPageEdit
