import { EntitiesOptionBanners } from "./../../../../types"
import { useCallback } from "react"
import { BannerState } from "../CreateOrEditBanners.controller"

type Props = {
	state: BannerState[]
	setState: React.Dispatch<React.SetStateAction<BannerState[]>>
}

export const useEntiyBanner = ({ state, setState }: Props) => {
	const handleEntiyChange = useCallback(
		(value: number, index: number) => {
			const find = EntitiesOptionBanners.find(
				ent => +ent.value === +value
			)?.label

			if (find) {
				const newState = state.map((item, i) => {
					if (i === index) {
						return {
							...item,
							complex: 0,
							service: 0,
							spec: 0,
							entity: find,
						}
					}
					return item
				})
				setState(newState)
			}
		},
		[setState, state]
	)
	return { handleEntiyChange }
}
