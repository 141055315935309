import { useForm } from "react-hook-form"
import { useCallback, useEffect, useMemo, useState } from "react"
import { useNavigate, useSearchParams } from "react-router-dom"
import { AllRoutes } from "../../../../constans/appRoutes"
import { ActionTypes } from "../../../MainEditPage/types"
import {
	RequestCreateCategoryStory,
	useCreateStoryCategoryMutation,
	useGetStoryCategoryByIdQuery,
	useUpdateStoryCategoryMutation,
} from "../../../../services/stories"
import { message } from "antd"
import { fileApi } from "../../../../services/file"

export const useStoriesCategory = () => {
	const navigate = useNavigate()
	const [searchParams] = useSearchParams()
	const params = Object.fromEntries(searchParams)
	const {
		register,
		formState: { errors },
		watch,
		setValue,
	} = useForm({ mode: "onBlur" })

	const cityId = params?.cityId
	const action = params?.action as ActionTypes
	const categoryId = params?.categoryId

	const isCreate = useMemo(() => {
		return action === "create"
	}, [action])

	const [fileId, setFileId] = useState<number>(0)
	const [photoUrl, setPhotoUrl] = useState("")

	const name = watch("name")

	const disabled = useMemo(() => {
		return !name
	}, [name])

	const [sendFile] = fileApi.useSendFileMutation()
	const [createStoryCategory] = useCreateStoryCategoryMutation()
	const [updateStoryCategory] = useUpdateStoryCategoryMutation()

	const { data: category } = useGetStoryCategoryByIdQuery(categoryId, {
		skip: !categoryId,
	})

	const handleChangeIcon = async (file: File) => {
		const formData = new FormData()

		formData.append("file", file)
		formData.append("is_public", "true")

		const response = await sendFile(formData)

		if (response && "data" in response) {
			const { id, url } = response.data.data
			setPhotoUrl(url)
			setFileId(id)
		}
	}

	const hadnleDeleteIcon = () => setFileId(0)

	const onCancel = () => {
		navigate(AllRoutes.StorisCategories + cityId)
	}

	const onSubmin = useCallback(() => {
		const data: RequestCreateCategoryStory = {
			name,
			city_id: cityId,
			active: 1,
			order: 1,
			image: null,
		}

		if (fileId !== 0 && fileId) {
			data.image = fileId.toString()
		}

		if (isCreate) {
			createStoryCategory(data)
				.unwrap()
				.then(res => {
					if (res) {
						message.success("категория успешно создана")
						navigate(AllRoutes.StorisCategories + cityId)
					}
				})
				.catch(error => {
					console.log("createStoryCategory error", error)
					message.error("Не удалось создать категорию")
				})
		} else {
			data.id = categoryId

			updateStoryCategory(data)
				.unwrap()
				.then(res => {
					if (res) {
						message.success("категория успешно изменена")
						navigate(AllRoutes.StorisCategories + cityId)
					}
				})
				.catch(error => {
					console.log("updateStoryCategory error", error)
					message.error("Не удалось изменить категорию")
				})
		}
	}, [
		name,
		cityId,
		fileId,
		createStoryCategory,
		updateStoryCategory,
		navigate,
		categoryId,
		isCreate,
	])

	useEffect(() => {
		if (category) {
			setValue("name", category.name)
			if (category.image) {
				setPhotoUrl(category.image?.url)
				setFileId(+category.image?.id)
			}
		}
	}, [category, setValue])

	return {
		register,
		onSubmin,
		hadnleDeleteIcon,
		handleChangeIcon,
		onCancel,
		errors,
		photoUrl,
		disabled,
		isCreate,
	}
}
