import { useNavigate, useSearchParams } from "react-router-dom"
import { AllRoutes } from "../../constans/appRoutes"
import { useEffect, useMemo, useState } from "react"
import {
	useDeleteMailingsMutation,
	useGetMailingsOfIdQuery,
	useGetMailingsQuery,
} from "../../services/mailing"
import { message } from "antd"
import { ActionType } from "../../models/Mailings"

export const useNotifycationController = () => {
	const navigate = useNavigate()
	const [searchParams] = useSearchParams()
	const params = Object.fromEntries(searchParams)

	const cityId = params?.cityId

	const isFieldCreate = useMemo(() => {
		return !!params?.fieldCreate
	}, [params])

	const isFieldEdit = useMemo(() => {
		return !!params?.fieldEdit
	}, [params])

	const [action, setAction] = useState<ActionType | null>(null)
	const [deletedItem, setDeletedItem] = useState(0)
	const [notificationIds, setNotificationIds] = useState<number[]>([])

	const { data: mailingsData } = useGetMailingsQuery({})

	const [fetchDelete] = useDeleteMailingsMutation()
	const { data: mailingData } = useGetMailingsOfIdQuery(
		{
			id: deletedItem,
		},
		{ skip: !deletedItem }
	)

	const isEdit = useMemo(() => {
		return action === "edit"
	}, [action])

	const isCreate = useMemo(() => {
		return action === "create"
	}, [action])

	const handleCreate = () => {
		setAction(action)
		navigate(AllRoutes.NotifycationsCreate)
	}

	const handleEdit = (id?: number) => {
		setAction(action)

		const editeditempath = id ? `&id=${id.toString()}` : ""
		navigate(AllRoutes.NotifycationEdit + editeditempath)
	}

	const startDelete = (id: number) => {
		setDeletedItem(id)
	}

	const endDelete = () => setDeletedItem(0)

	const handleDeelte = async () => {
		const result = await fetchDelete({ id: deletedItem })

		if (result && "data" in result) {
			setDeletedItem(0)
			message.success("Рассылка удалена")
		}
	}

	const handleSelectCity = (id: number) =>
		navigate(AllRoutes.NotifycationsCreate + id)

	const mailings = useMemo(() => {
		return mailingsData?.data || []
	}, [mailingsData])

	const defaultPrevLinks = useMemo(() => {
		return [
			{
				title: "Рассылка уведомлений",
				path: AllRoutes.Notifycations,
			},
		]
	}, [])

	const linksCities = useMemo(() => {
		return [{ path: AllRoutes.NotifycationsCreate, title: "Выбор города" }]
	}, [])

	const linksNotifications = useMemo(() => {
		return [...defaultPrevLinks, ...linksCities]
	}, [defaultPrevLinks, linksCities])

	const prevLinks = useMemo(() => {
		if (isCreate && cityId) {
			return linksNotifications
		}
		if (cityId && !isCreate && !isEdit && !isFieldCreate && !isFieldEdit) {
			return linksNotifications
		}

		if (isCreate || isEdit || isFieldCreate || isFieldEdit) {
			return defaultPrevLinks
		}

		return []
	}, [
		isFieldCreate,
		isFieldEdit,
		defaultPrevLinks,
		linksNotifications,
		cityId,
		isEdit,
		isCreate,
		linksCities,
	])

	const showBtnCreateFolder = useMemo(() => {
		return !isFieldCreate && !isFieldEdit && !action
	}, [isFieldCreate, isFieldEdit, action])

	const titlePage = useMemo(() => {
		if (cityId && !isFieldCreate && !isFieldEdit && !isCreate && !isEdit) {
			return "Рассылка уведомлений"
		}
		if (isFieldCreate) {
			return "Добавить папку"
		}

		if (isFieldEdit) {
			return "Редактировать папку"
		}

		if (isCreate && !cityId) {
			return "Выбор города"
		}

		if (isCreate && cityId) {
			return "Создание"
		}

		if (isEdit) {
			return "Редактирование"
		}

		return ""
	}, [isFieldCreate, isFieldEdit, isCreate, isEdit, cityId])

	const isAction = useMemo(() => {
		return !!Object.keys(params).length
	}, [params])

	const goToCreateField = () => {
		navigate(AllRoutes.NotifycationsFieldCreate)
	}

	const mailingsFilter = useMemo(() => {
		return mailings.filter(it => !notificationIds.includes(it.id))
	}, [mailings, notificationIds])

	useEffect(() => {
		!isAction && setAction(null)
	}, [isAction])

	useEffect(() => {
		if (!action && params?.action) {
			setAction(params?.action as ActionType)
		}
	}, [params, action])

	return {
		action,
		mailingsFilter,
		deletedItem,
		titlePage,
		prevLinks,
		showBtnCreateFolder,
		isFieldCreate,
		isFieldEdit,
		mailings,
		mailingData,
		cityId,
		isCreate,
		isEdit,
		goToCreateField,
		endDelete,
		handleCreate,
		startDelete,
		handleDeelte,
		setNotificationIds,
		handleSelectCity,
		handleEdit,
	}
}
