import { FC } from "react"
import { useStoriesCategories } from "./StoriesCategories.controller"
import { MainTable } from "../../../../common/MainTable"
import StoriesFields from "../StoriesFields/StoriesFields"
import styles from "./StoriesCategories.module.scss"
import StoriesStory from "../StoriesStory/StoriesStory.view"

const columns = [
	{
		title: "Категории",
		width: 1,
	},
]

const StoriesCategories: FC = () => {
	const {
		setStoriesIds,
		deleteStoryHandler,
		goEdit,
		isCreateCategory,
		isCreateStory,
		isEditCategory,
		isEditStory,
		storiesData,
	} = useStoriesCategories()

	return (
		<div className={styles.content}>
			{!isCreateCategory &&
				!isCreateStory &&
				!isEditCategory &&
				!isEditStory && (
					<>
						<MainTable
							columns={columns}
							data={[]}
							onDelete={deleteStoryHandler}
							onEdit={goEdit}
						/>
					</>
				)}

			{!isCreateStory && !isEditStory && (
				<StoriesFields stories={storiesData} setStoriesIds={setStoriesIds} />
			)}

			{(isCreateStory || isEditStory) && <StoriesStory />}
		</div>
	)
}

export default StoriesCategories
