import { DefaultOptionType } from "antd/es/select"
import { IServices } from "./../../models/Specialist"
export const findAdditionalServices = (
	services: IServices[],
	additionalIds: number[]
) => {
	const result: string[] = []
	if (services?.length && additionalIds?.length) {
		additionalIds.forEach(additionalId => {
			const findedService = services.find(
				service => service.id === additionalId
			)
			if (findedService) {
				result.push(findedService.name)
			}
		})
	}
	return result
}

export const findAdditionalServicesForComplex = (
	services: IServices[],
	additionalIds: number[]
) => {
	const result: number[] = []
	if (services?.length && additionalIds?.length) {
		additionalIds.forEach(additionalId => {
			const findedService = services.find(
				service => service.id === additionalId
			)
			if (findedService) {
				result.push(findedService.id)
			}
		})
	}
	return result
}
