import React, { memo, FC } from "react"
import styles from "./StoriesFields.module.scss"
import "./Tree.scss"
import { Spin, Tree } from "antd"
import { LoadingOutlined } from "@ant-design/icons"
import { useStoriesFields } from "./useStoriesFields.controller"
import StoriesCategory from "../StoriesCategory/StoriesCategory.view"
import DeleteModal from "../../../../common/DeleteModal/DeleteModal"
import { IStory } from "../../../../models/Stories"

const { DirectoryTree } = Tree

const antIcon = <LoadingOutlined style={{ fontSize: 24, marginTop: 20 }} spin />

type Props = {
	stories: IStory[] | undefined
	setStoriesIds: (arr: number[]) => void
}

const StoriesFields: FC<Props> = props => {
	const {
		isCreateCategory,
		isEditCategory,
		deleteFieldId,
		isEditStory,
		isCreateStory,
		del,
		generatedData,
		setDeleteFieldId,
		deleteStoryCategoryHandler,
		deleteStoryHandler,
		isLoading,
		setDel,
		onSelect,
		categoryDelete,
		storyDelete,
	} = useStoriesFields(props)

	return (
		<div className={styles.fields}>
			{(isCreateCategory || isEditCategory) && <StoriesCategory />}

			<DeleteModal
				title={`Вы уверены, что хотите удалить ${
					del
						? storyDelete?.name
						: categoryDelete?.name +
						  ". Все сторисы будут удалены вместе с категорией."
				}`}
				visible={
					(!!deleteFieldId || !!del) && (!!storyDelete || !!categoryDelete)
				}
				onClose={() => {
					setDeleteFieldId(0)
					setDel(0)
				}}
				onDelete={del ? deleteStoryHandler : deleteStoryCategoryHandler}
			/>

			{!isCreateCategory &&
				!isEditCategory &&
				!isCreateStory &&
				!isEditStory && (
					<>
						{isLoading ? (
							<div style={{ display: "flex", justifyContent: "center" }}>
								<Spin indicator={antIcon} />
							</div>
						) : generatedData.length ? (
							<DirectoryTree
								defaultExpandAll={false}
								onSelect={onSelect}
								treeData={generatedData}
								itemHeight={64}
								rootStyle={{
									background: "transparent",
									border: "0.5px solid #DADADA",
								}}
							/>
						) : (
							<></>
						)}
					</>
				)}
		</div>
	)
}

export default memo(StoriesFields)
