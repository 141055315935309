import { useCallback, useEffect, useMemo, useState } from "react"
import styles from "./SpecialistsPage.module.scss"
import { useForm } from "react-hook-form"
import { TextField } from "../../common/Form/TextField"
import { Button } from "../../common/Button"
import { useLocation, useNavigate, useSearchParams } from "react-router-dom"
import { AllRoutes } from "../../constans/appRoutes"
import {
	RequestEditSpecialistType,
	useEditSpecialistMutation,
	useGetSpecialistFoldersQuery,
} from "../../services/specialist"
import JoditEditor from "jodit-react"
import { message } from "antd"
import { fileApi } from "../../services/file"
import { FileField } from "../../common/Form/FileField"
import { ReactComponent as NoFileIcon } from "../../constans/icons/noFileIcon.svg"
import { ClinicType, useGetClinicItemsQuery } from "../../services/clinics"
import {
	SpecializationType,
	useGetSpecializationsQuery,
} from "../../services/specialization"
import { Modal } from "../../common/Modal"
import { Select } from "../../common/Select"
import { Checkbox } from "../../common/CheckBox"
import CustomizeServices from "./parts/CustomizeServices/CustomizeServices"
import { config } from "./helpers"
import cn from "classnames"
import { useSpecialistContext } from "./SpecialistContext"
import AdditionalServicesModal from "./parts/AdditionalServicesModal/AdditionalServicesModal"
import DescriptionServicesModal from "./parts/DescriptionServicesModal/DescriptionServicesModal"
import { TreeSelect } from "../../common/TreeSelect"
import { prepareData, sortFields } from "../DiscountPage/helpers"
import { treeSelectorData } from "../ServicesPage/ServicesPageEditField"

const SpecialistPageEdit = () => {
	const navigate = useNavigate()
	const [searchParams] = useSearchParams()
	const params = Object.fromEntries(searchParams)
	const currentId = params?.id

	const currentUrl = `${useLocation().pathname}${useLocation().search}`

	const {
		register,
		formState: { errors },
		watch,
		setValue,
	} = useForm({ mode: "onBlur" })

	const { specialistData, isCustomize, selectedServices } =
		useSpecialistContext()

	const { data: clinicsData } = useGetClinicItemsQuery({})
	const { data: specializationsData } = useGetSpecializationsQuery("")
	const { data: specialistsFields, isLoading: isLoadingFolders } =
		useGetSpecialistFoldersQuery()
	const [editSpecialist, { isLoading: editSpecialistLoading }] =
		useEditSpecialistMutation()

	const [sendFile] = fileApi.useSendFileMutation()

	const selectSpecializationsData = useMemo(() => {
		const result: { value: number; label: string; disabled?: boolean }[] = []
		if (specializationsData?.data) {
			specializationsData.data.forEach((spec: SpecializationType) => {
				const obj = {
					value: spec.id,
					label: spec.name,
				}
				result.push(obj)
			})
		}
		return result
	}, [specializationsData?.data])

	const selectClinicsData = useMemo(() => {
		const result: { value: number; label: string; disabled?: boolean }[] = []
		if (clinicsData?.data) {
			clinicsData.data.forEach((clinic: ClinicType) => {
				const obj = {
					value: clinic.id,
					label: clinic.name,
				}
				result.push(obj)
			})
		}
		return result
	}, [clinicsData?.data])

	const surname: string = watch("surname")
	const name: string = watch("name")
	const patronymic: string = watch("patronymic")
	const phone: string = watch("phone")
	const workExperience: string = watch("workExperience")
	const normalServiceDuration: string = watch("normalServiceDuration")
	const [specializationId, setSpecializationId] = useState<number>()
	const [fileId, setFileId] = useState<number>(0)
	const [photoUrl, setPhotoUrl] = useState("")
	const [description, setDescription] = useState("")
	const [education, setEducation] = useState("")
	const [selectedClinicsId, setSelectedClinicsId] = useState<number[]>([])
	const [isUzi, setIsUzi] = useState(() => !!specialistData?.data?.ultra_sound)
	const [error, setError] = useState("")

	const [parentFieldValue, setParentFieldValue] = useState<string | number>()

	const [searchDisabled, setSearchDisabled] = useState<boolean>(
		!!specialistData?.data?.search_disabled
	)

	const dataForPrepare = useMemo(() => {
		if (specialistsFields) {
			const sortedData = [...specialistsFields.data]

			const sortFieldsData = sortFields(sortedData as any)

			return prepareData(sortFieldsData)
		}
	}, [specialistsFields])

	const generatedFieldData = useMemo(() => {
		const data: any[] = []
		if (specialistsFields) {
			dataForPrepare.forEach((item: any) => {
				if (item) {
					data.push(item)
				}
			})
		}

		return data
	}, [specialistsFields, dataForPrepare])

	const onChangeSelect = useCallback((newValue: string) => {
		setParentFieldValue(newValue)
	}, [])

	const findFieldIdByValue = (
		value: string | number,
		fieldData: treeSelectorData[]
	) =>
		fieldData.find(field => {
			if (field.value === value) {
				return field
			}
		})

	const validateSelectService = () => {
		const types = selectedServices.map(serv => serv.type).flat()
		const manyPrimary = types.filter(type => type === "primary").length > 1
		const manySecondary = types.filter(type => type === "primary").length > 1

		if (manyPrimary) {
			setError("Первичная услуга может быть только одна")
			return false
		}

		if (manySecondary) {
			setError("Вторичная услуга может быть только одна")
			return false
		}

		return true
	}

	const handleSave = () => {
		const valid = validateSelectService()

		if (!valid) {
			return
		}

		if (specialistData?.data) {
			const sendData: RequestEditSpecialistType = {
				id: +currentId,
				surname: surname !== "" ? surname : specialistData.data.user.surname,
				name: name !== "" ? name : specialistData.data.user.name,
				patronymic:
					patronymic !== "" ? patronymic : specialistData.data.user.patronymic,
				phone: phone !== "" ? phone : specialistData.data.user.phone,
				work_experience:
					workExperience !== ""
						? +workExperience
						: +specialistData.data.work_experience,
				servicesId: selectedServices.map(selectedService => ({
					service_id: selectedService.service_id,
					type: selectedService.type,
					required_service_id: selectedService.required_service_id,
					req_service_description: selectedService.req_service_description,
				})),
				description,
				education,
				photo_id: fileId !== 0 && fileId ? fileId : null,
				clinics: selectedClinicsId,
				specialization_id: specializationId ?? 0,
				ultra_sound: isUzi,
				search_disabled: searchDisabled,
				normal_service_duration:
					normalServiceDuration !== ""
						? +normalServiceDuration
						: specialistData.data.normal_service_duration,
			}

			if (parentFieldValue) {
				sendData.field_id = parentFieldValue
			}

			if (parentFieldValue) {
				const field_id = findFieldIdByValue(
					parentFieldValue,
					generatedFieldData
				)?.id

				sendData.field_id = field_id
			}

			editSpecialist(sendData)
				.unwrap()
				.then(result => {
					if (result.message === "Success") {
						message.success("Специалист успешно редактирован")
						navigate(AllRoutes.Specialists)
					}
				})
				.catch(err => {
					console.log("editSpecialist error", error)
					message.error("Не удалось редактировать специалиста")
				})
		}
	}

	function handleDescrChange(newContent: string) {
		setDescription(newContent)
	}

	const onChangeSearchDisabled = (e: any) => {
		setSearchDisabled(prev => !prev)
	}

	function handleEducationChange(newContent: string) {
		setEducation(newContent)
	}

	const handleChangeIcon = async (file: File) => {
		const formData = new FormData()

		formData.append("file", file)
		formData.append("is_public", "true")

		const response = await sendFile(formData)

		if (response && "data" in response) {
			const { id, url } = response.data.data
			setPhotoUrl(url)
			setFileId(id)
		}
	}

	const hadnleDeleteIcon = () => setFileId(0)

	const chackUzi = () => {
		setIsUzi(prev => !prev)
	}

	useEffect(() => {
		if (specialistData?.data) {
			setValue("name", specialistData?.data.user.name)
			setValue("surname", specialistData?.data.user.surname)
			setValue("patronymic", specialistData?.data.user.patronymic)
			setValue("phone", specialistData?.data.user.phone)
			setValue("workExperience", specialistData?.data.work_experience)
			setValue(
				"normalServiceDuration",
				specialistData?.data.normal_service_duration?.toString()
			)
			// console.log(
			// 	"🚀 ~ file: SpecialistPageEdit.tsx:209 ~ useEffect ~ specialistData?.data.normal_service_duration:",
			// 	specialistData?.data
			// )

			setDescription(specialistData.data.description)
			setEducation(specialistData.data.education)
			const selectedServicesArr: {
				service_id: number | undefined
				type: string | undefined
				id: number
			}[] = []
			specialistData.data.services_specialists?.forEach((service, index) => {
				selectedServicesArr.push({
					service_id: service.service.id,
					type: service.type,
					id: index,
				})
			})
			setFileId(specialistData.data.user?.photo?.id ?? 0)
			setPhotoUrl(specialistData.data.user?.photo?.url ?? "")
			setSelectedClinicsId(specialistData.data.clinics)
			setSpecializationId(specialistData.data.specialization.id)
			setIsUzi(!!specialistData?.data?.ultra_sound)
			setSearchDisabled(!!specialistData?.data?.search_disabled)

			const findFieldData = generatedFieldData?.find(
				it => it.id === specialistData?.data.field_id
			)

			findFieldData && setParentFieldValue(findFieldData.value)
		}
	}, [specialistData?.data, setValue, generatedFieldData])

	return (
		<>
			<AdditionalServicesModal />
			<DescriptionServicesModal />

			<div
				className={cn(styles.page, {
					[styles.hidden]: !isCustomize,
				})}
			>
				<CustomizeServices />
			</div>

			<div
				className={cn(styles.page, {
					[styles.hidden]: isCustomize,
				})}
			>
				<div className={styles.editSpecialistWrapper}>
					<div className={styles.fileField}>
						<FileField
							Icon={NoFileIcon}
							onChange={handleChangeIcon}
							onDelete={hadnleDeleteIcon}
							src={photoUrl}
						/>
					</div>
					<TextField
						fieldname='surname'
						register={register}
						options={{ required: "Поле обязательно для заполнения" }}
						error={errors.surname?.message as string}
						palcehoder='Фамилия*'
						defaultValue={specialistData?.data.user.surname}
					/>
					<TextField
						fieldname='name'
						register={register}
						options={{ required: "Поле обязательно для заполнения" }}
						error={errors.name?.message as string}
						palcehoder='Имя*'
						defaultValue={specialistData?.data.user.name}
					/>
					<TextField
						fieldname='patronymic'
						register={register}
						options={{ required: "Поле обязательно для заполнения" }}
						error={errors.patronymic?.message as string}
						palcehoder='Отчество*'
						defaultValue={specialistData?.data.user.patronymic}
					/>
					<TextField
						fieldname='phone'
						register={register}
						options={{ required: "Поле обязательно для заполнения" }}
						error={errors.phone?.message as string}
						palcehoder='Номер телефона*'
						defaultValue={specialistData?.data.user.phone}
					/>
					<TextField
						fieldname='workExperience'
						register={register}
						options={{ required: "Поле обязательно для заполнения" }}
						error={errors.workExperience?.message as string}
						palcehoder='Опыт работы (в годах)*'
						defaultValue={specialistData?.data.work_experience.toString()}
					/>
					<Select
						style={{ width: "100%", marginBottom: 28 }}
						value={specializationId}
						placeholder='Специализация'
						onChange={e => (e ? setSpecializationId(e) : null)}
						options={selectSpecializationsData}
					/>
					<TextField
						fieldname='normalServiceDuration'
						register={register}
						options={{ required: "Поле обязательно для заполнения" }}
						error={errors?.normalServiceDuration?.message as string}
						palcehoder='Средняя длительность приема*'
						defaultValue={
							specialistData?.data.normal_service_duration?.toString() || ""
						}
					/>

					{!!specialistsFields?.data.length && (
						<div className={styles.wrapp}>
							<TreeSelect
								value={parentFieldValue}
								dropdownStyle={{
									maxHeight: 400,
									overflow: "auto",
								}}
								style={{
									marginBottom: 24,
								}}
								placeholder='Родительская папка'
								allowClear
								treeDefaultExpandAll
								onChange={onChangeSelect}
								treeData={generatedFieldData}
							/>
						</div>
					)}

					<Button
						title='Настроить услуги'
						onClick={() => navigate(currentUrl + "&cusomize=true")}
						isGrey
						disabled={false}
						classes={{ btn: styles.clinicAddBtn }}
					/>
					<Select
						mode='multiple'
						optionFilterProp='label'
						allowClear
						style={{ width: "100%" }}
						placeholder='Клиники'
						value={selectedClinicsId}
						onChange={setSelectedClinicsId}
						options={selectClinicsData}
					/>
					<div className={styles.textEditor__wrapper}>
						<h3 className={styles.textEditor__title}>Описание*</h3>
						<JoditEditor
							value={description}
							config={config}
							onBlur={handleDescrChange}
							onChange={handleDescrChange}
						/>
					</div>
					{/* <div
            style={{ marginBottom: 28 }}
            className={styles.textEditor__wrapper}
          >
            <h3 className={styles.textEditor__title}>Образование*</h3>
            <JoditEditor
              value={education}
              config={config}
              onBlur={handleEducationChange}
              onChange={handleEducationChange}
            />
          </div> */}

					<div
						onClick={chackUzi}
						style={{
							display: "flex",
							alignItems: "center",
							marginBottom: 10,
							cursor: "pointer",
						}}
					>
						<Checkbox
							style={{ marginRight: 10 }}
							checked={isUzi}
							onChange={chackUzi}
						/>
						<span>Специалист по направлению УЗИ</span>
					</div>

					<div
						onClick={onChangeSearchDisabled}
						style={{
							display: "flex",
							alignItems: "center",
							marginBottom: 30,
							cursor: "pointer",
						}}
					>
						<Checkbox style={{ marginRight: 10 }} checked={searchDisabled} />
						<span>Исключить из поиска</span>
					</div>

					<Button
						title='Отзывы'
						disabled={false}
						classes={{ btn: styles.reviewBtn }}
						isTransparent
						onClick={() => navigate(currentUrl + "&reviews=true")}
					/>
					<div className={styles.editSpecialistBtnWrapper}>
						{
							<Button
								title='Сохранить'
								disabled={false}
								onClick={handleSave}
								loading={editSpecialistLoading}
							/>
						}
						{
							<Button
								title='Отмена'
								disabled={false}
								onClick={() => navigate(AllRoutes.Specialists)}
								isTransparent
							/>
						}
					</div>

					<Modal
						visible={!!error}
						onClose={setError.bind(null, "")}
						title='Внимание'
						subTitle={error}
						successBtn={{ onClick: () => setError(""), text: "Понятно" }}
					/>
				</div>
			</div>
		</>
	)
}

export default SpecialistPageEdit
