import React from "react"
import { Modal } from "../../../../common/Modal"
import { message } from "antd"
import { useNavigate } from "react-router-dom"
import { AllRoutes } from "../../../../constans/appRoutes"
import {
	useDeleteNotificationFolderMutation,
	useGetNotificationFolderByIdQuery,
} from "../../../../services/mailing"

type Props = {
	deleteFieldId: number | string
	setDeleteFieldId: (id: number | string) => void
}

const DeleteNotificationFolderModal = ({
	deleteFieldId,
	setDeleteFieldId,
}: Props) => {
	const navigate = useNavigate()
	const [deleteNotification] = useDeleteNotificationFolderMutation()
	const { data: currentField } =
		useGetNotificationFolderByIdQuery(deleteFieldId)

	const handleDeleteField = async () => {
		await deleteNotification({ id: +deleteFieldId })
			.unwrap()
			.then(result => {
				message.success("Папка успешно удалена")
				setDeleteFieldId(0)
				navigate(AllRoutes.Notifycations)
			})
			.catch(err => {
				message.error("Не удалось удалить папку")
			})
	}
	return (
		<Modal
			visible={!!deleteFieldId && !!currentField}
			onClose={() => setDeleteFieldId(0)}
			title='Удалить'
			subTitle={`Вы уверены, что хотите удалить папку “${currentField?.data.name}”?`}
			successBtn={{
				text: "Отмена",
				onClick: () => setDeleteFieldId(0),
			}}
			cancelBtn={{
				text: "Удалить",
				onClick: handleDeleteField,
			}}
		/>
	)
}

export default DeleteNotificationFolderModal
