import { ServiceType } from "../models/Specialist"

export const LSKeys = {
	refresh_token: "refresh_token",
}

export const NotofycationHeader = [
	"ID",
	"Заголовок",
	"Дата создания",
	"Actions",
]

export const pushSelector = {
	given_time: "В установленное время",
	by_trigger: "По событию",
}

export const pushTriggets = {
	remaining_until: "До приема осталось",
	application_created: "Заявка создана",
}

export const timeSettings = [
	{ value: 1, label: pushSelector.given_time },
	{ value: 2, label: pushSelector.by_trigger },
]

export const dateFormats = [
	{ value: 2, label: "Минуты" },
	{ value: 1, label: "Часы" },
	{ value: 3, label: "Дни" },
]

export const ServiecesTypes: Record<
	ServiceType,
	{ type: ServiceType; label: string }
> = {
	primary: { type: "primary", label: "Первичная" },
	secondary: { type: "secondary", label: "Вторичная" },
	additionaly: { type: "additionaly", label: "Прочее" },
}

export const ServicesTypeOptions = [
	{ value: "primary", label: "Первичный" },
	{ value: "secondary", label: "Вторичный" },
	{ value: "additionaly", label: "Прочее" },
]

export const buttons = [
	"undo",
	"redo",
	"|",
	"bold",
	"strikethrough",
	"underline",
	"italic",
	"|",
	"superscript",
	"subscript",
	"|",
	"align",
	"|",
	"ul",
	"ol",
	"outdent",
	"indent",
	"|",
	"font",
	"fontsize",
	"brush",
	"paragraph",
	"|",
	"image",
	"link",
	"table",
	"|",
	"hr",
	"eraser",
	"copyformat",
	"|",
	"fullsize",
	"selectall",
	"print",
	"|",
	"source",
	"|",
]
