import { FC, useState } from "react"
import { SearchField } from "../Form/SearchField"
import { ReactComponent as ArrowIcon } from "../../constans/icons/goArrow.svg"
import styles from "./Cities.module.scss"
import { useDebounce } from "../../hooks/useDebaunce"
import { useGetCityItemsQuery } from "../../services/cities"

interface Props {
	onSelect: (cityId: number) => void
}

const Cities: FC<Props> = ({ onSelect }) => {
	const [search, setSeach] = useState("")
	const debauncedSearch = useDebounce(search, 200)
	const { data: cityes } = useGetCityItemsQuery({
		search: debauncedSearch,
	})

	const handleSearch = (text: string) => {
		setSeach(text)
	}

	return (
		<div style={{ width: "50%" }}>
			<SearchField
				maxWidth={539}
				value={search}
				onChange={e => handleSearch(e.target.value)}
			/>

			<ul className={styles.cityList}>
				{cityes?.data?.map(({ name, id }) => (
					<li onClick={onSelect.bind(null, id)} key={id}>
						<span>{name}</span>
						<ArrowIcon />
					</li>
				))}
			</ul>
		</div>
	)
}

export default Cities
