import { AdminPage } from "../pages/AdminPage"
import { CatalogPage } from "../pages/CatalogPage"
import LoginPage from "../pages/LoginPage/LoginPage.view"
import { RecoveryPage } from "../pages/RecoveryPage"
import { SavePasswordPage } from "../pages/SavePasswordPage"
import { ReactComponent as AdminsAcon } from "./icons/admins.svg"
import { ReactComponent as CatalogIcon } from "./icons/catalog.svg"
import { ReactComponent as CatalogActiveIcon } from "./icons/catalog_active.svg"
import { ReactComponent as ChatIcon } from "./icons/chat.svg"
import { ReactComponent as OnbordingIcon } from "./icons/onboring.svg"
import { ReactComponent as UsersIcon } from "./icons/users.svg"
import { ReactComponent as ServicesIcon } from "./icons/services.svg"
import { ReactComponent as ServicesActiveIcon } from "./icons/services_active.svg"
import { ReactComponent as ComplexesIcon } from "./icons/complexes.svg"
import { ReactComponent as ComplexesActiveIcon } from "./icons/complexes_active.svg"
import { ReactComponent as SalesIcon } from "./icons/sale.svg"
import { ReactComponent as StaticIcon } from "./icons/static.svg"
import { ReactComponent as BanersIcon } from "./icons/banners.svg"
import { ReactComponent as NotifyIcon } from "./icons/notify.svg"
import { ReactComponent as NotifyActiveIcon } from "./icons/notify_active.svg"
import { ReactComponent as CitiesIcon } from "./icons/cities.svg"
import { ReactComponent as CitiesActiveIcon } from "./icons/cities_active.svg"
import { ReactComponent as DiscountIcon } from "./icons/discount.svg"
import { ReactComponent as DiscountActiveIcon } from "./icons/discount_active.svg"
import { ReactComponent as SpecializationIcon } from "./icons/specialization.svg"
import { ReactComponent as SpecializationActiveIcon } from "./icons/specialization_active.svg"
import { ReactComponent as SpecialistsIcon } from "./icons/specialistIcon.svg"
import { ReactComponent as SpecialistsActiveIcon } from "./icons/specialistIcon_active.svg"
import { ReactComponent as EnrollmentsIcon } from "./icons/enrollments.svg"
import { ReactComponent as StoriesIcon } from "./icons/stories.svg"
import { ReactComponent as StoriesActiveIcon } from "./icons/stories_active.svg"
import { ReactComponent as ScheduleIcon } from "./icons/schedule.svg"
import { ReactComponent as ScheduleActiveIcon } from "./icons/schedule_active.svg"
import { ReactComponent as Homeicon } from "./icons/home.svg"
import { ReactComponent as HomeActiveIcon } from "./icons/home_active.svg"
import { ReactComponent as Preparationicon } from "./icons/preparation.svg"
import { ReactComponent as PreparationActiveIcon } from "./icons/preparation_active.svg"
import { UsersPage } from "../pages/UsersPage"
import { CitiesPage } from "../pages/CitiesPage"
import { SpecializationPage } from "../pages/SpecializationPage"
import ServicesPage from "../pages/ServicesPage/ServicesPage"
import ComplexesPage from "../pages/ComplexesPage/ComplexesPage"
import SpecialistsPage from "../pages/SpecialistsPage/SpecialistsPage"
import EnrollmentsPage from "../pages/EnrollmentsPage/EnrollmentsPage"
import { NotifyPage } from "../pages/Notifycations"
import { MainEditPage } from "../pages/MainEditPage"
import { PreparePage } from "../pages/PreparePage"
import { CancellationIssues } from "../pages/CancellationIssues"
import { SpecialistProvider } from "../pages/SpecialistsPage/SpecialistContext"
import DiscountPage from "../pages/DiscountPage/DiscountPage"
import { StoriesPage } from "../pages/StoriesPage"

export const AuthLinks = [
	{ path: "/login", Page: <LoginPage /> },
	{ path: "/recovery", Page: <RecoveryPage /> },
	{
		path: "/auth/recovery-password-confirm/:token",
		Page: <SavePasswordPage />,
	},
]

export type SidebarLinkItem = {
	title: string
	path: string
	Page: JSX.Element
	Icon?: JSX.Element
	IconActive?: JSX.Element
	dropDownMenu?: SidebarLinkItem[]
}

export type SidebarLink = {
	title: string
	links: SidebarLinkItem[]
}

export const SidebarLinks: SidebarLink[] = [
	{
		title: "Номенклатура",
		links: [
			{
				title: "Специализации",
				path: "/specializations",
				Page: <SpecializationPage />,
				Icon: <SpecializationIcon />,
				IconActive: <SpecializationActiveIcon />,
			},
			{
				title: "Услуги",
				path: "/services",
				Page: <ServicesPage />,
				Icon: <ServicesIcon />,
				IconActive: <ServicesActiveIcon />,
			},
			{
				title: "Скидки",
				path: "/discounts",
				Page: <DiscountPage />,
				Icon: <DiscountIcon />,
				IconActive: <DiscountActiveIcon />,
			},
			{
				title: "Комплексы",
				path: "/complexes",
				Page: <ComplexesPage />,
				Icon: <ComplexesIcon />,
				IconActive: <ComplexesActiveIcon />,
			},
			{
				title: "Подготовка",
				path: "/prepare",
				Icon: <Preparationicon />,
				Page: <PreparePage />,
				IconActive: <PreparationActiveIcon />,
			},
		],
	},
	{
		title: "Настройки города",
		links: [
			{
				title: "Города",
				path: "/cities",
				Page: <CitiesPage />,
				Icon: <CitiesIcon />,
				IconActive: <CitiesActiveIcon />,
			},
			{
				title: "Главная",
				path: "/main",
				Page: <MainEditPage />,
				Icon: <Homeicon />,
				IconActive: <HomeActiveIcon />,
			},
			{
				title: "Каталог",
				path: "/catalog",
				Page: <CatalogPage />,
				Icon: <CatalogIcon />,
				IconActive: <CatalogActiveIcon />,
			},
			{
				title: "Сторис",
				path: "/storis",
				Page: <StoriesPage />,
				Icon: <StoriesIcon />,
				IconActive: <StoriesActiveIcon />,
			},
		],
	},
	{
		title: "Специалисты",
		links: [
			{
				title: "Специалисты",
				path: "/specialists",
				Page: (
					<SpecialistProvider>
						<SpecialistsPage />
					</SpecialistProvider>
				),
				Icon: <SpecialistsIcon />,
				IconActive: <SpecialistsActiveIcon />,
			},
			{
				title: "Заявки",
				path: "/enrollments",
				Page: <EnrollmentsPage />,
				Icon: <ScheduleIcon />,
				IconActive: <ScheduleActiveIcon />,
				dropDownMenu: [
					{
						title: "Вопросы отмены",
						path: "/cancellation-issues",
						Page: <CancellationIssues />,
					},
				],
			},
			{
				title: "Рассылка уведомлений",
				path: "/notifications",
				Icon: <NotifyIcon />,
				Page: <NotifyPage />,
				IconActive: <NotifyActiveIcon />,
			},
		],
	},
]

export const RoutesArr = [
	{
		title: "Города",
		path: "/cities",
	},
	{
		title: "Специализации",
		path: "/specializations",
	},
	{
		title: "Добавить специализацию",
		path: "/specializations?create=true",
	},
]

export enum AllRoutes {
	Login = "/login",
	Admins = "/admins",
	Main = "/main",
	AdminsEdit = "/admins?isEdit=",
	AdminsCreate = "/admins?isCreate=true",
	Recovery = "/recovery",
	SetPassword = "/set-password",
	ConfirmPassword = "/auth/recovery-password-confirm/:token",
	Catalog = "/catalog",
	CatalogCity = "/catalog?cityId=",
	Cities = "/cities",
	CitiesEdit = "/cities?edit=true&id=",
	CitiesCreate = "/cities?create=true",
	ClinicEdit = "/cities/clinic?edit=true&id=:id",
	Specialization = "/specializations",
	SpecializationEdit = "/specializations?edit=true&id=",
	SpecializationCreate = "/specializations?create=true",
	Services = "/services",
	ServiceEdit = "/services?serviceEdit=true&serviceId=",
	ServiceCreate = "/services?serviceCreate=true&defaultParentId=",
	ServicesFieldCreate = "/services?fieldCreate=true",
	ServicesFieldEdit = "/services?fieldEdit=true&fieldId=",
	Complexes = "/complexes",
	ComplexesFieldCreate = "/complexes?fieldCreate=true",
	ComplexesFieldEdite = "/complexes?fieldEdit=true&fieldId=",
	ComplexesCreate = "/complexes?create=true",
	ComplexesEdit = "/complexes?edit=true&complexId=",
	Specialists = "/specialists",
	Enrollments = "/enrollments",
	EnrollmentsViewItem = "/enrollments?id=",
	Notifycations = "/notifications",
	NotifycationsCreate = "/notifications?action=create&cityId=",
	NotifycationEdit = "/notifications?action=edit",
	NotifycationsFieldCreate = "/notifications?fieldCreate=true",
	NotifycationFieldEdite = "/notifications?fieldEdit=true&fieldId=",
	Prepare = "/prepare",
	CancellationIssues = "/cancellation-issues",
	Discounts = "/discounts",
	DiscountsCreate = "/discounts?create=true",
	DiscountsEdit = "/discounts?edit=true&discountId=",
	DiscountsFieldCreate = "/discounts?fieldCreate=true",
	DiscountsFieldEdit = "/discounts?fieldEdit=true&fieldId=",
	Storis = "/storis",
	StorisCategories = "/storis?cityId=",
	StorisCategoriesCreate = "/storis?action=create&type=category&cityId=",
	StorisCategoriesEdit = "/storis?action=edit&type=category",
	StorisStoriesCreate = "/storis?action=create&type=story&cityId=",
	StorisStoriesEdit = "/storis?action=edit&type=story",
}
