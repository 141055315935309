import { createApi } from "@reduxjs/toolkit/query/react"
import { baseQuery } from "./baseQuery"
import { IPreparation } from "../models/Preparations"

const endpoind = "admin/preparations"

type RequestGetPreparation = {
	offset?: number
	limit?: number
	search?: string
}

type RequestCreatePreparation = {
	id?: number
	title: string
	note: string
	order?: number | string
	rules: { services: number[]; services_combos: number[] }[]
}

export const preparationsApi = createApi({
	reducerPath: "preparationsApi",
	baseQuery,
	tagTypes: ["getPreparation"],
	endpoints: build => ({
		getPreparaions: build.query<
			{ data: IPreparation[] },
			RequestGetPreparation
		>({
			query: params => ({
				url: endpoind,
				params,
			}),
			providesTags: ["getPreparation"],
		}),
		getPreparaionOfId: build.query<{ data: IPreparation }, { id: number }>({
			query: ({ id }) => ({
				url: endpoind + `/${id}`,
			}),
			providesTags: ["getPreparation"],
		}),
		createPreparaions: build.mutation<
			{ data: IPreparation },
			RequestCreatePreparation
		>({
			query: body => ({
				url: endpoind,
				body,
				method: "POST",
			}),
			invalidatesTags: () => ["getPreparation"],
		}),
		updatePreparaions: build.mutation<
			{ data: IPreparation },
			RequestCreatePreparation
		>({
			query: ({ id, ...body }) => ({
				url: endpoind + `/${id}`,
				body,
				method: "PUT",
			}),
			invalidatesTags: () => ["getPreparation"],
		}),
		deletePreparaions: build.mutation<string, { id: number }>({
			query: ({ id }) => ({
				url: endpoind + `/${id}`,
				method: "DELETE",
			}),
			invalidatesTags: () => ["getPreparation"],
		}),
	}),
})

export const {
	useCreatePreparaionsMutation,
	useDeletePreparaionsMutation,
	useGetPreparaionOfIdQuery,
	useGetPreparaionsQuery,
	useUpdatePreparaionsMutation,
} = preparationsApi
