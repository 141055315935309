import { useNavigate, useSearchParams } from "react-router-dom"
import { AllRoutes } from "../../constans/appRoutes"
import { ActionTypes } from "../MainEditPage/types"
import { useMemo } from "react"

export type ActionTypesStories = "story" | "category"

export const useStoriesPage = () => {
	const nav = useNavigate()
	const [searchParams] = useSearchParams()
	const params = Object.fromEntries(searchParams)

	const cityId = params?.cityId
	const type = params?.type as ActionTypesStories
	const action = params?.action as ActionTypes

	const isCreateCategory = useMemo(() => {
		return action === "create" && type === "category"
	}, [action, type])

	const isCreateStory = useMemo(() => {
		return action === "create" && type === "story"
	}, [action, type])

	const isEditCategory = useMemo(() => {
		return action === "edit" && type === "category"
	}, [action, type])

	const isEditStory = useMemo(() => {
		return action === "edit" && type === "story"
	}, [action, type])

	const showCreateBtns = useMemo(() => {
		return (
			!isCreateStory &&
			!isCreateCategory &&
			!!cityId &&
			!isEditCategory &&
			!isEditStory
		)
	}, [isCreateStory, cityId, isCreateCategory, isEditCategory, isEditStory])

	const title = useMemo(() => {
		if (
			cityId &&
			!isCreateCategory &&
			!isCreateStory &&
			!isEditCategory &&
			!isEditStory
		) {
			return "Категории"
		}

		if (isCreateCategory) {
			return "Создать категорию"
		}

		if (isEditCategory) {
			return "Редактирование категории"
		}

		if (isCreateStory) {
			return "Создать сторис"
		}

		if (isEditStory) {
			return "Редактирование стори"
		}

		return ""
	}, [cityId, isCreateCategory, isCreateStory, isEditCategory, isEditStory])

	const linksCities = useMemo(() => {
		return [{ path: AllRoutes.Storis, title: "Выбор города" }]
	}, [])

	const linksCategories = useMemo(() => {
		return [
			...linksCities,
			{
				path: AllRoutes.StorisCategories + cityId,
				title: "Категории",
			},
		]
	}, [linksCities, cityId])

	const prevLinks = useMemo(() => {
		if (
			cityId &&
			!isCreateCategory &&
			!isCreateStory &&
			!isEditCategory &&
			!isEditStory
		) {
			return linksCities
		}

		if (isCreateCategory || isCreateStory || isEditStory || isEditCategory) {
			return linksCategories
		}

		return []
	}, [
		cityId,
		isCreateCategory,
		linksCities,
		isCreateStory,
		linksCategories,
		isEditCategory,
		isEditStory,
	])

	const handleSelectCity = (id: number) => nav(AllRoutes.StorisCategories + id)

	const onCreateCategories = () => {
		nav(AllRoutes.StorisCategoriesCreate + cityId)
	}

	const onCreateStories = () => {
		nav(AllRoutes.StorisStoriesCreate + cityId)
	}

	return {
		cityId,
		prevLinks,
		title,
		isCreateCategory,
		showCreateBtns,
		isCreateStory,
		isEditCategory,
		isEditStory,
		handleSelectCity,
		onCreateCategories,
		onCreateStories,
	}
}
