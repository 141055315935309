import { useNavigate, useSearchParams } from "react-router-dom"
import { useCallback, useMemo, useState } from "react"
import { ReactComponent as DeleteIcon } from "../../../../constans/icons/delete.svg"
import { ReactComponent as EditIcon } from "../../../../constans/icons/redact.svg"
import { ReactComponent as ServiceIcon } from "../../../../constans/icons/serviceIcon.svg"
import { ReactComponent as GreyField } from "../../../../constans/icons/greyField.svg"
import { ReactComponent as GreenField } from "../../../../constans/icons/greenField.svg"
import styles from "./SpecialistsFields.module.scss"
import { AllRoutes } from "../../../../constans/appRoutes"
import type { DirectoryTreeProps } from "antd/es/tree"
import { useGetSpecialistFoldersQuery } from "../../../../services/specialist"
import { ISpecialist } from "../../../../models/Specialist"
import { prepareDataSpecialist, sortFieldsSpecialicts } from "../../helpers"

type Props = {
	specialists: ISpecialist[] | undefined
	setSpecialistsIds: (arr: number[]) => void
}

export const useSpecialistsFields = ({
	specialists,
	setSpecialistsIds,
}: Props) => {
	const navigate = useNavigate()
	const [searchParams] = useSearchParams()
	const params = Object.fromEntries(searchParams)

	const isFieldCreate = !!params?.fieldCreate
	const isFieldEdit = !!params?.fieldEdit
	const isEdit = !!params?.edit
	const isCreate = !!params?.create

	const isCreateFolder = !!params?.fieldCreate
	const isEditFolder = !!params?.fieldEdit

	const { data: specialistsFields, isLoading } = useGetSpecialistFoldersQuery()

	const [selectedFieldId, setSelectedFieldId] = useState(0)
	const [deleteFieldId, setDeleteFieldId] = useState<number>(0)
	const [del, setDel] = useState<number>(0)

	const addKey = (array: any, parentKey: any) => {
		const res = array.map((item: any, i: number) => {
			const key = `${parentKey}-${i}`
			const newItem = { ...item }
			if (item.children) {
				newItem.children = addKey(item.children, key)
			}
			if (!item.children) {
				newItem.children = []
			}
			newItem.key = key
			newItem.icon = key.length > 3 ? <GreyField /> : <GreenField />
			newItem.title = (
				<>
					{item.name}
					<div className={styles.tree__btns} style={{ flex: 1 }}>
						<button
							onClick={() =>
								navigate(
									AllRoutes.Specialists + `?fieldEdit=true&fieldId=` + item.id
								)
							}
							className={styles.tree__btn}
						>
							<EditIcon />
						</button>
						<button
							onClick={() => setDeleteFieldId(item.id)}
							className={styles.tree__btn}
						>
							<DeleteIcon />
						</button>
					</div>
				</>
			)
			newItem.type = "field"
			return newItem
		})

		return res
	}

	const goEdit = (id?: number) =>
		navigate(AllRoutes.Specialists + `?edit=true&id=${id}`)

	const findParentField = useCallback(
		(specialist: ISpecialist, dataArr: any[]) => {
			dataArr.forEach(item => {
				if (specialist.field_id === item.id) {
					item.children?.push({
						title: (
							<>
								<p className={styles.specialistId}>{specialist.id}</p>

								<p className={styles.userName}>
									{`${specialist?.user.surname} ${specialist?.user.name} ${
										specialist.user.patronymic || ""
									}`}
								</p>

								<p className={styles.specialistId}>
									{specialist.specialization.name}
								</p>

								<div className={styles.tree__btns} style={{ flex: 1 }}>
									<button
										onClick={() => goEdit(specialist.id)}
										className={styles.tree__btn}
									>
										<EditIcon />
									</button>
									<button
										onClick={() => setDel(specialist.id)}
										className={styles.tree__btn}
									>
										<DeleteIcon />
									</button>
								</div>
							</>
						),
						id: specialist.id,
						key: `${item.key}-${item.children.length}`,
						children: [],
						icon: <></>,
					})
				}
			})
		},
		[]
	)

	const dataForPrepare = useMemo(() => {
		if (specialistsFields?.data) {
			const sortedData = [...specialistsFields.data]

			const sortFieldsdata = [...sortFieldsSpecialicts(sortedData)]
			const prepareDataFields: any = [
				...prepareDataSpecialist(sortFieldsdata, addKey),
			]

			return prepareDataFields
		}
	}, [specialistsFields, specialists])

	const generateData = useCallback(
		(specialists: ISpecialist[]) => {
			const data: any = []

			dataForPrepare.forEach((item: any) => {
				data.push(item)
			})

			specialists.forEach(d => {
				findParentField(d, data)
			})

			const listIds = data
				.map((item: any) => {
					const childrenIds = item.children.map((it: any) => it.id)
					return childrenIds
				})
				.flat()

			setSpecialistsIds(listIds)

			return data
		},
		[dataForPrepare, findParentField]
	)

	const generatedData = useMemo(() => {
		if (specialistsFields?.data && specialists) {
			return generateData(specialists)
		}
		return []
	}, [specialistsFields?.data, specialists, generateData])

	const onSelect: DirectoryTreeProps<any>["onSelect"] = (keys, info) => {
		if (info.node.type === "field") {
			setSelectedFieldId(info.node.id)
		} else {
			setSelectedFieldId(0)
		}
	}

	return {
		isFieldCreate,
		isFieldEdit,
		deleteFieldId,
		del,
		isEdit,
		isCreate,
		generatedData,
		specialistsFields,
		setDeleteFieldId,
		setDel,
		onSelect,
		isLoading,
		isCreateFolder,
		isEditFolder,
	}
}
