import { useMemo } from "react"
import { useGetNotificationFoldersQuery } from "../../../services/mailing"
import { prepareData, sortFields } from "../helpers"

export const useNotifycationFolders = () => {
	const { data: notificationFolders } = useGetNotificationFoldersQuery()

	const dataForPrepare = useMemo(() => {
		if (notificationFolders) {
			const sortedData = [...notificationFolders.data]
			return prepareData(sortFields(sortedData as any))
		}
	}, [notificationFolders])

	const generatedFieldData = useMemo(() => {
		const data: any[] = []
		if (notificationFolders) {
			dataForPrepare.forEach((item: any) => {
				if (item) {
					data.push(item)
				}
			})
		}
		return data
	}, [notificationFolders, dataForPrepare])

	return {
		generatedFieldData,
	}
}
