import { useForm } from "react-hook-form"
import { useCallback, useEffect, useMemo, useState } from "react"
import { useNavigate, useSearchParams } from "react-router-dom"
import { AllRoutes } from "../../../../constans/appRoutes"
import { ActionTypes } from "../../../MainEditPage/types"
import {
	RequestCreateStory,
	StorySizeType,
	useCreateStoryMutation,
	useGetStoryByIdQuery,
	useGetStoryCategoriesQuery,
	useUpdateStoryMutation,
} from "../../../../services/stories"
import { message } from "antd"
import { DefaultOptionType } from "antd/es/select"
import { fileApi } from "../../../../services/file"

export const useStoriesStory = () => {
	const navigate = useNavigate()
	const [searchParams] = useSearchParams()
	const params = Object.fromEntries(searchParams)
	const {
		register,
		formState: { errors },
		watch,
		setValue,
	} = useForm({ mode: "onBlur" })

	const cityId = params?.cityId
	const action = params?.action as ActionTypes
	const storyId = params?.storyId

	const isCreate = useMemo(() => {
		return action === "create"
	}, [action])

	const [fileId_1, setFileId_1] = useState<number>(0)
	const [photoUrl_1, setPhotoUrl_1] = useState("")

	const [fileId_2, setFileId_2] = useState<number>(0)
	const [photoUrl_2, setPhotoUrl_2] = useState("")

	const [fileId_3, setFileId_3] = useState<number>(0)
	const [photoUrl_3, setPhotoUrl_3] = useState("")

	const [category, setCategory] = useState<number | undefined>()

	const name = watch("name")

	const disabled = useMemo(() => {
		return !name || !category || !fileId_1 || !fileId_2 || !fileId_3
	}, [name, category, fileId_1, fileId_2, fileId_3])

	const [sendFile] = fileApi.useSendFileMutation()
	const [createStory] = useCreateStoryMutation()
	const [updateStory] = useUpdateStoryMutation()
	const { data: categories, isLoading } = useGetStoryCategoriesQuery(
		cityId || "",
		{ skip: !cityId }
	)

	const { data: story } = useGetStoryByIdQuery(storyId, { skip: !storyId })

	const categoriesOptions: DefaultOptionType[] = useMemo(() => {
		return categories
			? categories?.map(it => {
					return {
						label: it.name,
						value: it.id,
					}
			  })
			: []
	}, [categories])

	const handleChangeIcon = async (file: File, type: StorySizeType) => {
		const formData = new FormData()

		formData.append("file", file)
		formData.append("is_public", "true")

		const response = await sendFile(formData)

		if (response && "data" in response) {
			const { id, url } = response.data.data

			switch (type) {
				case "small":
					setPhotoUrl_1(url)
					setFileId_1(id)
					break
				case "regular":
					setPhotoUrl_2(url)
					setFileId_2(id)
					break
				case "big":
					setPhotoUrl_3(url)
					setFileId_3(id)
					break
			}
		}
	}

	const hadnleDeleteIcon = (type: StorySizeType) => {
		switch (type) {
			case "small":
				setFileId_1(0)
				break
			case "regular":
				setFileId_2(0)
				break
			case "big":
				setFileId_3(0)
				break
		}
	}

	const handleCategoryChange = (value: number) => {
		setCategory(value)
	}

	const onCancel = () => {
		navigate(AllRoutes.StorisCategories + cityId)
	}

	const onSubmin = useCallback(() => {
		const sendData: RequestCreateStory = {
			name,
			city_id: cityId,
			active: 1,
			order: 1,
			category_id: category,
			image: null,
		}

		const image = {
			0: {
				id: fileId_1,
				type: "small",
			},
			1: {
				id: fileId_2,
				type: "regular",
			},
			2: {
				id: fileId_3,
				type: "big",
			},
		}

		sendData.image = JSON.stringify(image)

		if (isCreate) {
			createStory(sendData)
				.unwrap()
				.then(res => {
					if (res) {
						message.success("Сторис успешно создан")
						navigate(AllRoutes.StorisCategories + cityId)
					}
				})
				.catch(error => {
					console.log("createStory error", error)
					message.error("Не удалось создать сторис")
				})
		} else {
			sendData.id = storyId

			updateStory(sendData)
				.unwrap()
				.then(res => {
					if (res) {
						message.success("Сторис успешно изменён")
						navigate(AllRoutes.StorisCategories + cityId)
					}
				})
				.catch(error => {
					console.log("createStory error", error)
					message.error("Не удалось изменить сторис")
				})
		}
	}, [
		createStory,
		updateStory,
		cityId,
		category,
		fileId_1,
		fileId_2,
		fileId_3,
		name,
		navigate,
		isCreate,
		storyId,
	])

	useEffect(() => {
		if (story) {
			setValue("name", story.name)

			const isCategory = categories?.find(it => it.id === story.category_id)

			if (isCategory) setCategory(story.category_id)

			if (story.image?.length) {
				story.image?.forEach(it => {
					switch (it.type) {
						case "small":
							setPhotoUrl_1(it.url)
							setFileId_1(it.id)
							break
						case "regular":
							setPhotoUrl_2(it.url)
							setFileId_2(it.id)
							break
						case "big":
							setPhotoUrl_3(it.url)
							setFileId_3(it.id)
							break
					}
				})
			}
		}
	}, [story, setValue, categories])

	return {
		register,
		onSubmin,
		hadnleDeleteIcon,
		handleChangeIcon,
		handleCategoryChange,
		onCancel,
		errors,
		photoUrl_1,
		photoUrl_2,
		photoUrl_3,
		disabled,
		isCreate,
		category,
		categoriesOptions,
	}
}
