import React from "react"
import styles from "./CancelEnrollModal.module.scss"
import cn from "classnames"
import { Modal } from "../../../../common/Modal"
import { useDeleteEnrollmentMutation } from "../../../../services/enrollments"
import { AllRoutes } from "../../../../constans/appRoutes"
import { useNavigate, useSearchParams } from "react-router-dom"
import { message } from "antd"

type Props = {
	visible: boolean
	onClose: () => void
}

const CancelEnrollModal = ({ visible, onClose }: Props) => {
	const navigate = useNavigate()
	const [searchParams] = useSearchParams()
	const params = Object.fromEntries(searchParams)
	const currentId = params?.id
	const [deleteEnrollment] = useDeleteEnrollmentMutation()

	const handleDelete = () => {
		deleteEnrollment(currentId)
			.then(() => {
				navigate(AllRoutes.Enrollments)
				message.success("Запись успешно отменена")
			})
			.catch(() => {
				message.error("Не удалось отменить запись")
			})
	}

	return (
		<Modal visible={visible} onClose={onClose}>
			<div className={styles.modalWrapper}>
				<span className={styles.modalTitle}>Отмена записи</span>
				<p className={styles.modalDescription}>
					Вы уверены что хотите отменить запись?
				</p>
				<div className={styles.modalButtons}>
					<button
						onClick={onClose}
						className={cn(styles.modalBtn, styles.cancel)}
					>
						Не отменять запись
					</button>
					<button onClick={handleDelete} className={styles.modalBtn}>
						Отменить запись
					</button>
				</div>
			</div>
		</Modal>
	)
}

export default CancelEnrollModal
