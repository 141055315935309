import React, { useEffect } from "react"
import styles from "./NotificationFolder.module.scss"
import { useForm } from "react-hook-form"
import { TextField } from "../../../../common/Form/TextField"
import { Button } from "../../../../common/Button"
import { useNavigate, useSearchParams } from "react-router-dom"
import { AllRoutes } from "../../../../constans/appRoutes"
import {
	useCreateNotificationFolderMutation,
	useEditNotificationFolderMutation,
	useGetNotificationFolderByIdQuery,
} from "../../../../services/mailing"
import { message } from "antd"

type Props = {}

const NotificationFolder = ({}: Props) => {
	const navigate = useNavigate()
	const {
		register,
		formState: { errors },
		watch,
		setValue,
	} = useForm({ mode: "onBlur" })

	const [searchParams] = useSearchParams()
	const params = Object.fromEntries(searchParams)

	const fieldId = params?.fieldId

	const { data: currentField } = useGetNotificationFolderByIdQuery(fieldId, {
		skip: !fieldId,
	})

	const [createNotificationFolder] = useCreateNotificationFolderMutation()

	const [editNotificationFolder] = useEditNotificationFolderMutation()

	const name = watch("name")

	const handleCreateField = async () => {
		if (!fieldId) {
			await createNotificationFolder({ name })
				.unwrap()
				.then(result => {
					if (result.success) {
						message.success("Папка успешно создана")
						navigate(AllRoutes.Notifycations)
					}
				})
				.catch(err => {
					console.log(err)
					message.error("Не удалось создать папку")
				})
		} else {
			await editNotificationFolder({ name, id: fieldId })
				.unwrap()
				.then(result => {
					if (result.success) {
						message.success("Папка успешно изменена")
						navigate(AllRoutes.Notifycations)
					}
				})
				.catch(err => {
					console.log(err)
					message.error("Не удалось изменить папку")
				})
		}
	}

	useEffect(() => {
		if (currentField) {
			setValue("name", currentField.data.name)
		}
	}, [currentField, setValue])

	return (
		<div style={{ padding: "30px 0" }}>
			<div className={styles.halfContaienr}>
				<TextField
					fieldname='name'
					register={register}
					options={{ required: "" }}
					error={errors.name?.message as string}
					palcehoder='Название'
					isMarginBottom={false}
				/>
			</div>
			<div className={styles.createServiceBtnWrapper}>
				<Button
					title={fieldId ? "Сохранить" : "Создать"}
					// disabled={description.length === 0 || !name}
					disabled={!name}
					onClick={handleCreateField}
				/>
				<Button
					title='Отмена'
					disabled={false}
					onClick={() => navigate(AllRoutes.Notifycations)}
					isTransparent
				/>
			</div>
		</div>
	)
}

export default NotificationFolder
