import { FC } from "react"
import { TextField } from "../../../common/Form/TextField"
import { useNotifycationEditController } from "./NotifycationEdit.controller"
import { Select } from "../../../common/Select"
import {
	dateFormats,
	pushSelector,
	timeSettings as timeSettingsOptions,
} from "../../../constans/constans"
import { Checkbox, DatePicker, TimePicker } from "antd"
import styles from "./NotifycationEdit.module.scss"
import { Button } from "../../../common/Button"
import { ActionType } from "../../../models/Mailings"
import dayjs from "dayjs"
import { Entities, EntitiesOption } from "../../MainEditPage/types"
import { TreeSelect } from "../../../common/TreeSelect"

interface Props {
	action: ActionType
}

const NotifycationEdit: FC<Props> = ({ action }) => {
	const {
		isDate,
		errors,
		createLodaing,
		isTrigger,
		selectTime,
		triggerTime,
		selectedDay,
		selectedTime,
		timeSettings,
		triggerFormat,
		triggerOption,
		triggerOptions,
		currentMailing,
		entity,
		servicesId,
		complexId,
		specialistId,
		updateLodaing,
		specialistsOptions,
		servicesOptions,
		complexesOptions,
		promotionId,
		promotionOptions,
		gender,
		generatedFieldData,
		parentFieldValue,
		onChangeSelect,
		setFemaleHandler,
		setMaleHandler,
		disabled,
		register,
		onSubmit,
		handleSubmit,
		handleTimeChange,
		handleDateChange,
		handleChangeTrigger,
		handleChangeTriggerData,
		handleTimeSettigsChange,
		handleChangeTriggerFormat,
		handleEntiyChange,
		handleServChange,
		handleSpecChange,
		handleComplexChange,
		handlePromotionChange,
	} = useNotifycationEditController(action)

	return (
		<form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
			<div style={{ width: "40%" }}>
				<TextField
					defaultValue={currentMailing?.subject || ""}
					options={{ required: "Поле обязательно для заполнения" }}
					register={register}
					fieldname='name'
					error={errors.name?.message as string}
					palcehoder='Введите название уведомления'
					title='Название уведомления'
					hiddenRightIcons
				/>

				<div className={styles.foldersSelect}>
					<TreeSelect
						value={parentFieldValue}
						dropdownStyle={{ maxHeight: 400, overflow: "auto" }}
						placeholder='Папка'
						allowClear
						treeDefaultExpandAll
						onChange={onChangeSelect}
						treeData={generatedFieldData}
					/>
				</div>

				<TextField
					defaultValue={currentMailing?.subject || ""}
					options={{ required: "Поле обязательно для заполнения" }}
					register={register}
					fieldname='title'
					error={errors.name?.message as string}
					palcehoder='Введите заголовок уведомления'
					title='Заголовок уведомления'
					hiddenRightIcons
				/>

				<TextField
					defaultValue={currentMailing?.message || ""}
					options={{ required: "Поле обязательно для заполнения" }}
					register={register}
					fieldname='message'
					palcehoder='Введите текст сообщения'
					title='Сообщение для пациента'
					hiddenRightIcons
					error={errors.message?.message as string}
				/>

				<Select
					value={timeSettings}
					optionFilterProp='label'
					placeholder='Способ отправления'
					options={timeSettingsOptions}
					onChange={handleTimeSettigsChange}
				/>

				{timeSettings === pushSelector.given_time && (
					<>
						<div className={styles.selector}>
							<Select
								value={entity}
								onChange={handleEntiyChange}
								options={EntitiesOption}
								placeholder='Сущность'
							/>
						</div>
						{entity === Entities.Spec && (
							<div className={styles.selector}>
								<Select
									allowClear
									showSearch
									optionFilterProp='label'
									value={specialistId}
									onChange={handleSpecChange}
									options={specialistsOptions}
									placeholder='Специалист'
								/>
							</div>
						)}

						{entity === Entities.Serv && (
							<div className={styles.selector}>
								<Select
									allowClear
									showSearch
									optionFilterProp='label'
									value={servicesId}
									onChange={handleServChange}
									options={servicesOptions}
									placeholder='Услуга'
								/>
							</div>
						)}

						{entity === Entities.Complex && (
							<div className={styles.selector}>
								<Select
									allowClear
									showSearch
									optionFilterProp='label'
									value={complexId}
									onChange={handleComplexChange}
									options={complexesOptions}
									placeholder='Комлекс'
								/>
							</div>
						)}

						{entity === Entities.Promotion && (
							<div className={styles.selector}>
								<Select
									allowClear
									showSearch
									optionFilterProp='label'
									value={promotionId}
									onChange={handlePromotionChange}
									options={promotionOptions}
									placeholder='Акция'
								/>
							</div>
						)}
					</>
				)}

				{isDate && (
					<div className={styles.wrapp}>
						<div className={styles.flex}>
							<div style={{ width: 200, marginRight: 30 }}>
								<DatePicker
									style={{ width: "100%", marginBottom: 28 }}
									size='large'
									defaultValue={
										!!selectedDay?.length ? dayjs(selectedDay) : undefined
									}
									format={"YYYY-MM-DD"}
									showToday
									onChange={handleDateChange}
									placeholder='Не выбрана'
									allowClear
								/>
							</div>
							<div style={{ width: 120 }}>
								<TimePicker
									style={{ width: "100%", marginBottom: 28 }}
									size='large'
									defaultValue={
										!!selectedDay?.length
											? dayjs(selectedTime, "HH:mm")
											: undefined
									}
									format={"HH:mm"}
									onChange={handleTimeChange}
									placeholder='Не выбрана'
									allowClear
								/>
							</div>
						</div>
					</div>
				)}

				{isTrigger && (
					<>
						<div className={styles.wrapp}>
							<Select
								value={triggerOption}
								optionFilterProp='label'
								placeholder='Выбор события'
								options={triggerOptions}
								onChange={handleChangeTrigger}
							/>
						</div>
						{selectTime && (
							<div className={styles.wrapp}>
								<div className={styles.flex}>
									<div className={styles.inputWrap}>
										<span className={styles.inputPlaceholder}>Время</span>
										<input
											className={styles.input}
											onChange={handleChangeTriggerData}
											value={triggerTime > 0 ? triggerTime : ""}
											type='number'
											name='date'
										/>
									</div>
									<div style={{ width: 200 }}>
										<Select
											optionFilterProp='label'
											placeholder='Формат'
											options={dateFormats}
											value={triggerFormat}
											onChange={handleChangeTriggerFormat}
										/>
									</div>
								</div>
							</div>
						)}
					</>
				)}

				{!isTrigger && (
					<div>
						<h4 className={styles.sexTitle}>Выберите пол для рассылки</h4>

						<div className={styles.sexcheckboxes}>
							<Checkbox
								onChange={e => setMaleHandler(e.target.checked)}
								checked={gender === "male"}
							>
								Мужской
							</Checkbox>

							<Checkbox
								onChange={e => setFemaleHandler(e.target.checked)}
								checked={gender === "female"}
							>
								Женский
							</Checkbox>
						</div>
					</div>
				)}
			</div>

			<Button
				classes={{ btn: styles.btn }}
				title={action === "edit" ? "Редактировать" : "Создать"}
				onClick={handleSubmit(onSubmit)}
				disabled={disabled()}
				loading={updateLodaing || createLodaing}
			/>
		</form>
	)
}

export default NotifycationEdit
