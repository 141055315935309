import { ChangeEvent, useEffect, useMemo, useState } from "react"
import { useNavigate, useSearchParams } from "react-router-dom"
import {
	RequestEditServiceType,
	useEditServiceMutation,
	useGetServicePricesQuery,
	useGetServiceQuery,
	useGetServicesQuery,
} from "../../services/services"
import { useGetSpecializationsQuery } from "../../services/specialization"
import { fileApi } from "../../services/file"
import { useForm } from "react-hook-form"
import { findAdditionalServices } from "./helpers"
import { SelectProps, message } from "antd"
import { AllRoutes } from "../../constans/appRoutes"
import { ServicesFieldsType } from "../../models/CatalogItems"
import { prepareData, treeSelectorData } from "./ServicesPageEditField"
import { sortFields } from "./ServicesPage"
import { CheckboxChangeEvent } from "antd/es/checkbox"
import { ServicesSelected } from "../../common/Services/SelectedServicesFilds/useSelectedServicesFilds"
import { IServices } from "../../models/Specialist"
import { GendersType, MinAgeType } from "../../models/Services"

type Props = {
	fieldsData: ServicesFieldsType[]
}

export const useServicesPageEdit = ({ fieldsData }: Props) => {
	const navigate = useNavigate()
	const [searchParams] = useSearchParams()
	const params = Object.fromEntries(searchParams)
	const currentId = params?.serviceId
	const isShowPrices = params?.showPrices

	const { data: servicesData } = useGetServicesQuery("")
	const { data: currentServiceData } = useGetServiceQuery(
		{ id: +currentId },
		{ skip: !currentId }
	)

	const { data: specializationsData } = useGetSpecializationsQuery("")
	const [editService, { isLoading: loadingSave }] = useEditServiceMutation()
	const { data: servicePrices } = useGetServicePricesQuery({ id: +currentId })
	const [sendFile] = fileApi.useSendFileMutation()

	const {
		register,
		formState: { errors },
		watch,
		setValue,
	} = useForm({ mode: "onBlur" })

	const shortName = watch("shortname") ?? currentServiceData?.data.name
	const fullName = watch("fullname") ?? currentServiceData?.data.full_name
	const duration = watch("duration") ?? currentServiceData?.data.duration
	const order = watch("order") ?? currentServiceData?.data.order

	const [fileId, setFileId] = useState<number>(0)
	const [photoUrl, setPhotoUrl] = useState("")
	const [shortNameDefault, setShortNameDefault] = useState("")
	const [fullNameDefault, setFullNameDefault] = useState("")
	const [durationDefault, setDurationDefault] = useState<number>(0)
	const [parentFieldValue, setParentFieldValue] = useState<string>()
	const [parentFieldId, setParentFieldId] = useState<number>(0)
	const [description, setDescription] = useState<string>("")
	const [specializationId, setSpecializationId] = useState<string>("")
	const [isNotSchedule, setIsNotSchedule] = useState(false)
	const [selectedAdditionalServices, setSelectedAdditionalServices] = useState<
		string[]
	>([])
	const [isUseContrasting, setIsUseContrasting] = useState<boolean>(
		() => currentServiceData?.data?.with_contrast || false
	)
	const [searchDisabled, setSearchDisabled] = useState<boolean>(
		() => !!currentServiceData?.data?.search_disabled || false
	)

	// услуги с контрастированием
	const [selectedServicesId, setSelectedServicesId] = useState<number[]>([])

	const [selectedServices, setSelectedServices] = useState<ServicesSelected[]>(
		[]
	)

	const [requiredServices, setRequiredServices] = useState<boolean>(false)

	const [note, setNote] = useState<string>("")
	const [preparation, setPreparation] = useState<string>("")

	const [gender, setGender] = useState<GendersType>(null)
	const [maxAge, setMaxAge] = useState<MinAgeType>(null)

	const [descriptionAge, setDescriptionAge] = useState<string>("")
	const [descriptionGender, setDescriptionGender] = useState<string>("")
	const [descriptionGenderAndAge, setDescriptionGenderAndAge] =
		useState<string>("")

	const handleSave = () => {
		const required_for = requiredServices
			? selectedServices.map(it => it.service_id)
			: []

		const sendData: RequestEditServiceType = {
			id: +currentId,
			name: shortName !== "" ? shortName : shortNameDefault,
			description: description,
			full_name: fullName !== "" ? fullName : fullNameDefault,
			duration: duration !== "" ? duration : durationDefault,
			notAddedToSchedule: +isNotSchedule,
			specialization_id: +specializationId,
			service_field_id: parentFieldId,
			icon_id: fileId !== 0 && fileId ? fileId : null,
			external_id: fullName !== "" ? fullName : "12312",
			additional_services: selectedAdditionalServices
				.map(
					servname =>
						servicesData?.data.find(serv => serv.name === servname)?.id || 0
				)
				.flat(),
			order,
			with_contrast: isUseContrasting,
			note,
			training: preparation,
			search_disabled: searchDisabled,
			required_for,
			// contrasts: [],
		}

		if (maxAge === "от 18") {
			sendData.min_age = 18
			sendData.max_age = null
		} else {
			sendData.max_age = maxAge
			sendData.min_age = null
		}

		sendData.invalid_age_description = descriptionAge

		sendData.gender = gender
		sendData.invalid_gender_description = descriptionGender

		if (!!gender && !!maxAge) {
			sendData.invalid_both_description = descriptionGenderAndAge
		}

		if (isUseContrasting && !!selectedServicesId.length) {
			sendData.contrasts = selectedServicesId
		}
		// console.log("handleSave", sendData)

		editService(sendData)
			.unwrap()
			.then(result => {
				if (result.message === "Success") {
					message.success("Услуга успешно редактирована")
					navigate(AllRoutes.Services)
				}
			})
			.catch(err => {
				message.error("Не удалось редактировать услугу")
			})
	}

	const generatedServicesSelectorData = useMemo(() => {
		const selectorData: SelectProps["options"] = []

		if (servicesData?.data) {
			servicesData.data.forEach((service: IServices) => {
				const obj = {
					label: service.name,
					value: service.id,
				}
				selectorData.push(obj)
			})
		}
		return selectorData
	}, [servicesData?.data])

	const dataForPrepare = useMemo(() => {
		if (fieldsData) {
			const sortedData = [...fieldsData]
			return prepareData(sortFields(sortedData))
		}
	}, [fieldsData])

	const generatedFieldData = useMemo(() => {
		const data: any[] = []
		if (fieldsData) {
			dataForPrepare.forEach((item: any) => {
				if (item) {
					data.push(item)
				}
			})
		}
		return data
	}, [fieldsData, dataForPrepare])

	const handleChangeIcon = async (file: File) => {
		const formData = new FormData()

		formData.append("file", file)
		formData.append("is_public", "true")

		const response = await sendFile(formData)

		if (response && "data" in response) {
			const { id, url } = response.data.data
			setPhotoUrl(url)
			setFileId(id)
		}
	}

	const hadnleDeleteIcon = () => setFileId(0)

	const onChangeSelect = (newValue: string) => {
		setParentFieldValue(newValue)
	}

	const handleDescriptionOnChange = (
		event: ChangeEvent<HTMLTextAreaElement>
	) => {
		setDescription(event.target.value)
	}

	const handleDescriptionAgeOnChange = (
		event: ChangeEvent<HTMLTextAreaElement>
	) => {
		setDescriptionAge(event.target.value)
	}

	const handleDescriptionGenderOnChange = (
		event: ChangeEvent<HTMLTextAreaElement>
	) => {
		setDescriptionGender(event.target.value)
	}

	const handleDescriptionGenderAndAgeOnChange = (
		event: ChangeEvent<HTMLTextAreaElement>
	) => {
		setDescriptionGenderAndAge(event.target.value)
	}

	const handleSpecializationChange = (value: string) => {
		setSpecializationId(value)
	}

	const onChangeCheckbox = (e: CheckboxChangeEvent) => {
		setIsNotSchedule(e.target.checked)
	}

	const onChangeUseContrasting = (e: CheckboxChangeEvent) => {
		setIsUseContrasting(prev => !prev)
	}

	const onChangeRequiredServices = (e: CheckboxChangeEvent) => {
		setRequiredServices(prev => {
			// if (prev) {
			// 	setSelectedServices([])
			// }
			return !prev
		})
	}

	const onChangeSearchDisabled = (e: CheckboxChangeEvent) => {
		setSearchDisabled(prev => !prev)
	}

	const handlePreparationOnChange = (
		event: ChangeEvent<HTMLTextAreaElement>
	) => {
		setPreparation(event.target.value)
	}

	const handleNoteOnChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
		setNote(event.target.value)
	}

	const findFieldValue = (id: number, fieldData: treeSelectorData[]) => {
		fieldData.forEach(field => {
			if (field.id === id) {
				setParentFieldValue(field.value)
			}
			if (field.id !== id && field.children.length) {
				findFieldValue(id, field.children)
			}
		})
	}

	const findFieldValueResult = useMemo(() => {
		if (currentServiceData?.data && generatedFieldData.length) {
			findFieldValue(
				currentServiceData?.data?.service_field?.id,
				generatedFieldData
			)
		}
	}, [currentServiceData?.data, generatedFieldData])

	const findFieldIdByValue = (value: string, fieldData: treeSelectorData[]) => {
		fieldData.forEach(field => {
			if (field.value === value) {
				setParentFieldId(field.id)
			}
			if (field.value !== value && field.children.length) {
				findFieldIdByValue(value, field.children)
			}
		})
	}

	const findFieldIdResult = useMemo(() => {
		if (parentFieldValue && generatedFieldData.length) {
			findFieldIdByValue(parentFieldValue, generatedFieldData)
		}
	}, [parentFieldValue, generatedFieldData])

	const specializationSelectData = useMemo(() => {
		return specializationsData?.data.map(
			(spec: { id: number; name: string }) => ({
				value: spec.id,
				label: spec.name,
			})
		)
	}, [specializationsData?.data])

	useEffect(() => {
		if (
			currentServiceData?.data &&
			servicesData?.data &&
			specializationsData?.data
		) {
			setDescription(currentServiceData.data.description)

			setSpecializationId(
				specializationsData?.data?.find(
					(spec: { id: number; name: string }) =>
						currentServiceData?.data?.specialization &&
						spec?.id === currentServiceData?.data?.specialization?.id
				).id
			)
			setSelectedAdditionalServices(
				findAdditionalServices(
					servicesData.data,
					currentServiceData.data.additional_services.map(
						(serv: any) => serv.id
					)
				)
			)
			setShortNameDefault(currentServiceData.data.name)
			setFullNameDefault(currentServiceData.data.full_name)
			setDurationDefault(currentServiceData.data.duration)
			setIsNotSchedule(!!currentServiceData.data.notAddedToSchedule)

			setSelectedServicesId(
				currentServiceData.data.contrasts?.map(it => it.id) || []
			)

			setFileId(currentServiceData.data?.icon?.id ?? 0)
			setPhotoUrl(currentServiceData.data.icon?.url)

			setNote(currentServiceData.data.note || "")
			setPreparation(currentServiceData.data.training || "")

			setValue("shortname", currentServiceData?.data.name)
			setValue("fullname", currentServiceData?.data.full_name)
			setValue("duration", currentServiceData?.data.duration)
			setValue("order", currentServiceData?.data.order)
			setIsUseContrasting(currentServiceData?.data?.with_contrast)
			setSearchDisabled(!!currentServiceData?.data?.search_disabled)

			if (!!currentServiceData?.data?.max_age) {
				setMaxAge(currentServiceData?.data?.max_age as any)
				setDescriptionAge(
					currentServiceData?.data.invalid_age_description || ""
				)
			}

			if (!!currentServiceData?.data?.min_age) {
				setMaxAge("от 18")
				setDescriptionAge(
					currentServiceData?.data.invalid_age_description || ""
				)
			}
			if (!!currentServiceData?.data?.gender) {
				setGender(currentServiceData?.data?.gender as any)
				setDescriptionGender(
					currentServiceData?.data?.invalid_gender_description || ""
				)
			}

			if (
				!!currentServiceData?.data?.max_age &&
				!!currentServiceData?.data?.gender
			) {
				setDescriptionGenderAndAge(
					currentServiceData?.data?.invalid_both_description || ""
				)
			}
			if (currentServiceData.data?.requiredFor.length) {
				setSelectedServices(
					currentServiceData.data?.requiredFor?.map(s => {
						return {
							service_id: s.id,
							service_field: s.service_field.id,
							type: "primary",
						}
					})
				)
				setRequiredServices(true)
			}
		}
	}, [
		currentServiceData?.data,
		specializationsData?.data,
		servicesData?.data,
		setValue,
	])

	return {
		isShowPrices,
		photoUrl,
		parentFieldValue,
		generatedFieldData,
		errors,
		currentServiceData,
		description,
		specializationId,
		specializationSelectData,
		isNotSchedule,
		selectedAdditionalServices,
		servicesData,
		currentId,
		isUseContrasting,
		loadingSave,
		servicePrices,
		note,
		preparation,
		searchDisabled,
		selectedServices,
		requiredServices,
		selectedServicesId,
		generatedServicesSelectorData,
		setGender,
		setMaxAge,
		maxAge,
		gender,
		descriptionAge,
		setSelectedServicesId,
		onChangeRequiredServices,
		onChangeSearchDisabled,
		handleChangeIcon,
		hadnleDeleteIcon,
		onChangeSelect,
		register,
		handleSpecializationChange,
		handleDescriptionOnChange,
		onChangeCheckbox,
		setSelectedAdditionalServices,
		navigate,
		onChangeUseContrasting,
		handleSave,
		handlePreparationOnChange,
		handleNoteOnChange,
		setSelectedServices,
		handleDescriptionAgeOnChange,
		handleDescriptionGenderOnChange,
		handleDescriptionGenderAndAgeOnChange,
		descriptionGender,
		descriptionGenderAndAge,
	}
}
