import { FC } from "react"
import { Layout } from "../../common/Layout"
import { useStoriesPage } from "./StoriesPage.controller"
import { Cities } from "../../common/Cities"
import { useLocation } from "react-router-dom"
import StoriesCategories from "./parts/StoriesCategories/StoriesCategories.view"
import styles from "./StoriesPage.module.scss"

const StoriesPage: FC = () => {
	const {
		cityId,
		prevLinks,
		title,
		showCreateBtns,
		handleSelectCity,
		onCreateCategories,
		onCreateStories,
	} = useStoriesPage()

	return (
		<>
			<Layout
				createBtn={showCreateBtns}
				createBtnTitle='Создать категорию'
				onClickCreateBtn={onCreateCategories}
				secondCreateBtn={showCreateBtns} // показывать только в том случае если есть хотя бы одно категория
				secondCreateBtnTitle='Создать сторис'
				onClickSecondCreateBtn={onCreateStories}
				prevLinks={prevLinks}
				currentUrl={{
					path: `${useLocation().pathname}${useLocation().search}`,
					title,
				}}
				classes={{ content: styles.content }}
			>
				{!cityId && <Cities onSelect={handleSelectCity} />}

				{cityId && <StoriesCategories />}
			</Layout>
		</>
	)
}

export default StoriesPage
