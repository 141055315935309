import { DefaultOptionType } from "antd/es/select"

export type GendersType = null | "female" | "male"

export const optionsGenders: DefaultOptionType[] = [
	{
		label: "Для всех",
		value: null,
	},
	{
		label: "Для женщин",
		value: "female",
	},
	{
		label: "Для мужчин",
		value: "male",
	},
]

export type MinAgeType = null | 12 | 18 | "от 18"

export const optionsMinAges: DefaultOptionType[] = [
	{
		label: "Для всех",
		value: null,
	},
	{
		label: "до 12 лет",
		value: 12,
	},
	{
		label: "до 18 лет",
		value: 18,
	},
	{
		label: "после 18 лет",
		value: "от 18",
	},
]
