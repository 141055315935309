import React from "react"
import styles from "./DeleteModal.module.scss"
import cn from "classnames"
import { Modal } from "../Modal"

type Props = {
	visible: boolean
	onClose: () => void
	onDelete: () => void
	title: string
}

const DeleteModal = ({ visible, onClose, onDelete, title }: Props) => {
	const handleDeleteField = () => {
		onDelete()
	}

	return (
		<Modal visible={visible} onClose={onClose}>
			<div className={styles.modalWrapper}>
				<span className={styles.modalTitle}>Удалить</span>
				<p className={styles.modalDescription}>{title}</p>
				<div className={styles.modalButtons}>
					<button
						onClick={onClose}
						className={cn(styles.modalBtn, styles.cancel)}
					>
						Отмена
					</button>
					<button onClick={handleDeleteField} className={styles.modalBtn}>
						Удалить
					</button>
				</div>
			</div>
		</Modal>
	)
}

export default DeleteModal
