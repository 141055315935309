import { useNavigate, useSearchParams } from "react-router-dom"
import {
	RequestEditServiceType,
	useCreateServiceMutation,
	useGetServicesQuery,
} from "../../services/services"
import { useGetSpecializationsQuery } from "../../services/specialization"
import { fileApi } from "../../services/file"
import { useForm } from "react-hook-form"
import { ChangeEvent, useMemo, useState } from "react"
import { Checkbox, message } from "antd"
import { ServicesFieldsType } from "../../models/CatalogItems"
import { prepareData, treeSelectorData } from "./ServicesPageEditField"
import { sortFields } from "./ServicesPage"
import { CheckboxChangeEvent } from "antd/es/checkbox"
import { ServicesSelected } from "../../common/Services/SelectedServicesFilds/useSelectedServicesFilds"
import { GendersType, MinAgeType } from "../../models/Services"

type Props = {
	fieldsData: ServicesFieldsType[]
}

export const useServicesPageCreate = ({ fieldsData }: Props) => {
	const navigate = useNavigate()
	const [searchParams] = useSearchParams()
	const params = Object.fromEntries(searchParams)
	const defaultParentId = params?.defaultParentId
	const isShowPrices = params?.showPrices

	const { data: servicesData } = useGetServicesQuery("")
	const { data: specializationsData } = useGetSpecializationsQuery("")
	const [sendFile] = fileApi.useSendFileMutation()
	const [createService] = useCreateServiceMutation()

	const {
		register,
		formState: { errors },
		watch,
	} = useForm({ mode: "onBlur" })

	const shortName = watch("shortname")
	const fullName = watch("fullname")
	const duration = watch("duration")
	const order = watch("order")

	const [fileId, setFileId] = useState<number>(0)
	const [photoUrl, setPhotoUrl] = useState("")
	const [parentFieldValue, setParentFieldValue] = useState<string>("0-0")
	const [parentFieldId, setParentFieldId] = useState<number>(0)
	const [description, setDescription] = useState<string>("")

	const [note, setNote] = useState<string>("")
	const [preparation, setPreparation] = useState<string>("")

	const [specializationId, setSpecializationId] = useState<string>("")
	const [isNotSchedule, setIsNotSchedule] = useState(false)
	const [selectedAdditionalServices, setSelectedAdditionalServices] = useState<
		number[]
	>([])
	const [searchDisabled, setSearchDisabled] = useState<boolean>(false)

	const [selectedServices, setSelectedServices] = useState<ServicesSelected[]>(
		[]
	)

	const [requiredServices, setRequiredServices] = useState<boolean>(false)

	const [gender, setGender] = useState<GendersType>(null)
	const [maxAge, setMaxAge] = useState<MinAgeType>(null)

	const [descriptionAge, setDescriptionAge] = useState<string>("")
	const [descriptionGender, setDescriptionGender] = useState<string>("")
	const [descriptionGenderAndAge, setDescriptionGenderAndAge] =
		useState<string>("")

	const handleSave = () => {
		const required_for = requiredServices
			? selectedServices.map(it => it.service_id)
			: []

		const sendData: RequestEditServiceType = {
			name: shortName,
			description: description,
			full_name: fullName,
			duration: duration,
			notAddedToSchedule: +isNotSchedule,
			specialization_id: +specializationId,
			service_field_id: +parentFieldId,
			icon_id: fileId !== 0 && fileId ? fileId : null,
			external_id: fullName !== "" ? fullName : "",
			additional_services: selectedAdditionalServices,
			with_contrast: false,
			order,
			note,
			training: preparation,
			search_disabled: searchDisabled,
			required_for,
			contrasts: [],
			gender,
		}

		if (maxAge === "от 18") {
			sendData.min_age = 18
		} else {
			sendData.max_age = maxAge
		}

		sendData.invalid_age_description = descriptionAge

		sendData.gender = gender
		sendData.invalid_gender_description = descriptionGender

		if (!!gender && !!maxAge) {
			sendData.invalid_both_description = descriptionGenderAndAge
		}

		createService(sendData)
			.unwrap()
			.then(result => {
				if (result.message === "Success") {
					message.success("Услуга успешно создана")
					const id = result.data.id
					navigate(
						`/services?serviceEdit=true&serviceId=${id}&showPrices=true&fromCreate=true`
					)
				}
			})
			.catch(err => {
				message.error("Не удалось редактировать услугу")
			})
	}

	const dataForPrepare = useMemo(() => {
		if (fieldsData) {
			const sortedData = [...fieldsData]
			return prepareData(sortFields(sortedData))
		}
	}, [fieldsData])

	const generatedFieldData = useMemo(() => {
		const data: any[] = []
		if (fieldsData) {
			dataForPrepare.forEach((item: any) => {
				if (item) {
					data.push(item)
				}
			})
		}
		return data
	}, [fieldsData, dataForPrepare])

	const handleChangeIcon = async (file: File) => {
		const formData = new FormData()

		formData.append("file", file)
		formData.append("is_public", "true")

		const response = await sendFile(formData)

		if (response && "data" in response) {
			const { id, url } = response.data.data
			setPhotoUrl(url)
			setFileId(id)
		}
	}

	const hadnleDeleteIcon = () => setFileId(0)

	const onChangeSelect = (newValue: string) => {
		setParentFieldValue(newValue)
	}

	const handleDescriptionOnChange = (
		event: ChangeEvent<HTMLTextAreaElement>
	) => {
		setDescription(event.target.value)
	}

	const handleDescriptionGenderOnChange = (
		event: ChangeEvent<HTMLTextAreaElement>
	) => {
		setDescriptionGender(event.target.value)
	}

	const handleDescriptionGenderAndAgeOnChange = (
		event: ChangeEvent<HTMLTextAreaElement>
	) => {
		setDescriptionGenderAndAge(event.target.value)
	}

	const handleDescriptionAgeOnChange = (
		event: ChangeEvent<HTMLTextAreaElement>
	) => {
		setDescriptionAge(event.target.value)
	}

	const handlePreparationOnChange = (
		event: ChangeEvent<HTMLTextAreaElement>
	) => {
		setPreparation(event.target.value)
	}

	const handleNoteOnChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
		setNote(event.target.value)
	}

	const onChangeRequiredServices = (e: CheckboxChangeEvent) => {
		setRequiredServices(prev => !prev)
	}

	const onChangeSearchDisabled = (e: CheckboxChangeEvent) => {
		setSearchDisabled(prev => !prev)
	}

	const handleSpecializationChange = (value: string) => {
		setSpecializationId(value)
	}

	const onChangeCheckbox = (e: CheckboxChangeEvent) => {
		setIsNotSchedule(e.target.checked)
	}

	const findFieldValue = (id: number, fieldData: treeSelectorData[]) => {
		fieldData.forEach(field => {
			if (field.id === id) {
				setParentFieldValue(field.value)
			}
			if (field.id !== id && field.children.length) {
				findFieldValue(id, field.children)
			}
		})
	}

	const findFieldIdByValue = (value: string, fieldData: treeSelectorData[]) => {
		fieldData.forEach(field => {
			if (field.value === value) {
				setParentFieldId(field.id)
			}
			if (field.value !== value && field.children.length) {
				findFieldIdByValue(value, field.children)
			}
		})
	}

	const findFieldValueResult = useMemo(() => {
		if (defaultParentId && generatedFieldData.length) {
			findFieldValue(+defaultParentId, generatedFieldData)
		}
	}, [defaultParentId, generatedFieldData])

	const findFieldIdResult = useMemo(() => {
		if (parentFieldValue && generatedFieldData.length) {
			findFieldIdByValue(parentFieldValue, generatedFieldData)
		}
	}, [parentFieldValue, generatedFieldData])

	const specializationSelectData = useMemo(() => {
		return specializationsData?.data.map(
			(spec: { id: number; name: string }) => ({
				value: spec.id,
				label: spec.name,
			})
		)
	}, [specializationsData?.data])

	interface CustomOptionProps {
		children: React.ReactNode
		value: number
		selected: boolean
	}

	function CustomOption({ children, selected }: CustomOptionProps) {
		return <Checkbox checked={selected}>{children}</Checkbox>
	}

	return {
		CustomOption,
		handleChangeIcon,
		hadnleDeleteIcon,
		onChangeSelect,
		register,
		handleDescriptionOnChange,
		handleNoteOnChange,
		handlePreparationOnChange,
		handleSpecializationChange,
		onChangeCheckbox,
		setSelectedAdditionalServices,
		navigate,
		onChangeSearchDisabled,
		handleSave,
		onChangeRequiredServices,
		setGender,
		setMaxAge,
		handleDescriptionAgeOnChange,
		maxAge,
		gender,
		isShowPrices,
		photoUrl,
		parentFieldValue,
		generatedFieldData,
		errors,
		description,
		note,
		preparation,
		specializationId,
		specializationSelectData,
		isNotSchedule,
		selectedAdditionalServices,
		servicesData,
		searchDisabled,
		requiredServices,
		selectedServices,
		descriptionAge,
		descriptionGender,
		descriptionGenderAndAge,
		setSelectedServices,
		handleDescriptionGenderOnChange,
		handleDescriptionGenderAndAgeOnChange,
	}
}
